import React from "react";
import Table from "../../views/shared/Table";

function ModalDataTable({ tableColumns, tableData }) {
  return (
    <Table
      values={tableData}
      columns={tableColumns}
      tableClasses={{
        table:
          "table align-items-center table-sm table-flush border-none text-muted table-custom px-0 ",
        thead: "d-none",
        tbody: "border-none text-muted",
      }}
    />
  );
}

export default ModalDataTable;
