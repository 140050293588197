import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  // Nav,
  // NavItem,
  // Input,
  // Button,
} from "reactstrap";
import InProgressLines from "./InProgressLines";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import InProgressPpmGraph from "./InProgressPpmGraph";
// import OfficeTimePickers from "./OfficeTimePicker";

function getCurrentDateTime(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

function InProgressData({ clientId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [thresholdValues, setThresholdValues] = useState({
    // kpiThreshold: 100,
    offlineCheckThreshold: 30, //min
  });

  // const [timeRange, setTimeRange] = useState({
  //   start_time: "",
  //   end_time: "",
  // });

  // const [machineNotificationPreference, setMachineNotificationPreference] =
  //   useState("");

  // const [isUpdatingClientPreference, setIsUpdatingClientPreference] =
  //   useState(false);

  const timerRef = useRef(null);

  const today = new Date();
  const yesterday = new Date(today);
  // yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(yesterday.getHours() - 8);

  // const onChange = (e) => {
  //   setThresholdValues({
  //     ...thresholdValues,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const getData = (key) => {
    const todayGetData = new Date();
    //TODO: need to update below issue - provide todayGetData instead of today
    const yesterdayGetData = new Date(today);
    yesterdayGetData.setHours(yesterdayGetData.getHours() - 8);

    axios
      .get(`${baseUrl}/api/dashboard/inProgressData`, {
        // .get(`${baseUrl}/api/dashboard/getLineStatusData`, {
        params: {
          start_date: yesterdayGetData.toISOString(),
          // start_date: "2016-01-01T00:00:00Z",
          end_date: todayGetData.toISOString(),
          // end_date: "2022-08-04T23:59:59Z",
          clientId,
          initialReq: key,
          offlineCheckThreshold: thresholdValues.offlineCheckThreshold,
        },
        headers: authHeader(),
      })
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
        timerRef.current = setTimeout(() => {
          getData("no");
        }, 30000);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
        setIsLoading(false);
        timerRef.current = setTimeout(() => {
          getData("no");
        }, 30000);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getData("yes");
        // axios
    //   .get(`${baseUrl}/api/getClientPreferenceById/${clientId}`, {
    //     headers: authHeader(),
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     setTimeRange({
    //       start_time: res.data.client.office_hours_start,
    //       end_time: res.data.client.office_hours_end,
    //     });
    //     setMachineNotificationPreference(
    //       res.data.client.machine_notification_pref
    //     );
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [clientId]);

  // const handleUpdateClientPreference = () => {
  //   setIsUpdatingClientPreference(true);
  //   axios
  //     .put(
  //       `${baseUrl}/api/admin/client/update-preference/${clientId}`,
  //       {
  //         office_hours_start: timeRange.start_time,
  //         office_hours_end: timeRange.end_time,
  //         machine_notification_pref: machineNotificationPreference,
  //       },
  //       {
  //         headers: authHeader(),
  //       }
  //     )
  //     .then((res) => {
  //       setIsUpdatingClientPreference(false);
  //     })
  //     .catch((err) => {
  //       setIsUpdatingClientPreference(false);
  //     });
  // };

  return (
    <div className="header bg-transparent py-0 mt-4">
      <Container fluid>
        <div className="header-body">
          <Row className="mb-2">
            <Col xs="12" md="6" className="text-left mb-2">
              <span className="h1" style={{ color: "#a7ce39" }}>
                Current Status
              </span>
              <span className="h2 text-muted ml-5">
                {/* {yesterday.toDateString()} */}
                {/* {yesterday.toISOString().replace('T', ' ---- ')} */}
                {getCurrentDateTime(yesterday)}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {/* {today.toDateString()} */}
                {/* {today.toISOString().replace('T', ' ---- ')} */}
                {getCurrentDateTime(today)}
              </span>
            </Col>
            {/* <Col xs="12" md="6" className="text-right mb-2">
              <div className="col">
                <Nav className="justify-content-end" pills>
                  <NavItem className="mt-1 text-white">
                    Offline Threshold Minutes
                  </NavItem>
                  <NavItem>
                    <Input
                      placeholder="Offline Check Threshold"
                      name="offlineCheckThreshold"
                      value={thresholdValues.offlineCheckThreshold}
                      className="textBg"
                      bsSize="sm"
                      style={{ color: "#000000" }}
                      onChange={onChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setIsLoading(true);
                          getData("yes");
                          getPacksData("yes");
                          getAvgWeightData("yes");
                        }
                      }}
                    />
                  </NavItem>
                  <NavItem className="mt-1 text-white">KPI</NavItem>
                  <NavItem>
                    <Input
                      placeholder="KPI Threshold Value"
                      name="kpiThreshold"
                      value={thresholdValues.kpiThreshold}
                      className="textBg"
                      type="number"
                      bsSize="sm"
                      style={{ color: "#000000" }}
                      onChange={onChange}
                    />
                  </NavItem>
                </Nav>
              </div>
            </Col> */}
          </Row>
          {/* <Row className="mb-2">
            <Col xs="12" className="text-right mb-2">
              <div className="col">
                <Nav className="justify-content-center" pills>
                  <NavItem className="mt-1 text-white">Office Timings</NavItem>
                  <NavItem>
                    <OfficeTimePickers
                      timeRange={timeRange}
                      setTimeRange={setTimeRange}
                    />
                  </NavItem>
                  <NavItem className="mt-1 text-white">
                    Notification Preference
                  </NavItem>
                  <NavItem>
                    <Input
                      placeholder="Preference"
                      name="machineNotificationPreference"
                      type="select"
                      value={machineNotificationPreference}
                      className="textBg"
                      bsSize="sm"
                      style={{ color: "#000000" }}
                      onChange={(e) =>
                        setMachineNotificationPreference(e.target.value)
                      }
                    >
                      {["None", "Idle", "Offline", "Both"].map(
                        (option, idx) => {
                          return (
                            <option key={idx} value={option}>
                              {option}
                            </option>
                          );
                        }
                      )}
                    </Input>
                  </NavItem>
                  <NavItem className="text-white">
                    <Button
                      style={{
                        backgroundColor: "#a7ce39",
                        border: "none",
                        height: "33px",
                      }}
                      className="px-4 mt-0"
                      size="sm"
                      onClick={() => handleUpdateClientPreference()}
                    >
                      {isUpdatingClientPreference ? (
                        <>
                          <Spinner size="sm" className="" />
                        </>
                      ) : (
                        <>
                          <i className="fa fa-check fa-lg" />
                        </>
                      )}
                    </Button>
                  </NavItem>
                </Nav>
              </div>
            </Col>
          </Row> */}
          <Row>
            {isLoading ? (
              <Col xs="12" className="text-center mt-6">
                <Spinner />
              </Col>
            ) : data.dataArray && data.dataArray.length > 0 ? (
              <Col xs="12" className="mt-4">
                <InProgressPpmGraph data={data.dataArray} />
              </Col>
            ) : (
              <Col xs="12" className="text-center mt-6"></Col>
            )}
          </Row>
          <Row>
            {isLoading ? (
              <Col xs="12" className="text-center mt-6">
                <Spinner />
              </Col>
            ) : data.dataArray && data.dataArray.length > 0 ? (
              data.dataArray
                .sort((a, b) => a.line - b.line)
                .map((item) => {
                  return (
                    <Col xs="12" className="mt-4">
                      <InProgressLines
                        data={item}
                        clientId={clientId}
                        thresholdValues={thresholdValues}
                      />
                    </Col>
                  );
                })
            ) : (
              <Col xs="12" className="text-center mt-6">
                No Data Found
              </Col>
            )}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default InProgressData;
