import React, { useState, useEffect } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { FormGroup, InputGroup, InputGroupText, Input } from "reactstrap";

const DateFilter = ({
  handleDateRange,
  filtersData,
  handleApplyFromDateFilter,
}) => {
  const [state, setState] = useState({
    // start: moment().startOf('day').utcOffset(0, true),
    // start: moment.utc(filtersData.start_date).utcOffset(0, true),
    start: moment.utc(filtersData.start_date),
    // end: moment().endOf('day').utcOffset(0, true),
    // end: moment.utc(filtersData.end_date).utcOffset(0, true),
    end: moment.utc(filtersData.end_date),
  });
  const { start, end } = state;

  useEffect(() => {
    setState({
      // start: moment().startOf('day').utcOffset(0, true),
      // start: moment.utc(filtersData.start_date).utcOffset(0, true),
      start: moment.utc(filtersData.start_date),
      // end: moment().endOf('day').utcOffset(0, true),
      // end: moment.utc(filtersData.end_date).utcOffset(0, true),
      end: moment.utc(filtersData.end_date),
    });
  }, [filtersData]);

  const handleCallback = (start, end) => {
    setState({ start, end });
    handleDateRange(
      start.utcOffset(0, true).format(),
      end.utcOffset(0, true).format()
    );
  };
  const label =
    start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
  return (
    <DateRangePicker
      initialSettings={{
        startDate: start.toDate(),
        endDate: end.toDate(),
        showDropdowns: true,
        alwaysShowCalendars: true,
        ranges: {
          Today: [moment().toDate(), moment().toDate()],
          Yesterday: [
            moment().subtract(1, "days").toDate(),
            moment().subtract(1, "days").toDate(),
          ],
          "Last 7 Days": [
            moment().subtract(6, "days").toDate(),
            moment().toDate(),
          ],
          "Last 30 Days": [
            moment().subtract(29, "days").toDate(),
            moment().toDate(),
          ],
          "This Month": [
            moment().startOf("month").toDate(),
            moment().endOf("month").toDate(),
          ],
          "Last Month": [
            moment().subtract(1, "month").startOf("month").toDate(),
            moment().subtract(1, "month").endOf("month").toDate(),
          ],
          "This Year": [
            moment().startOf("year").toDate(),
            moment().endOf("year").toDate(),
          ],
          "Last Year": [
            moment().subtract(1, "year").startOf("year").toDate(),
            moment().subtract(1, "year").endOf("year").toDate(),
          ],
        },
      }}
      onCallback={handleCallback}
      onApply={handleApplyFromDateFilter}
    >
      <div
        id="reportrange"
        style={{
          cursor: "pointer",
        }}
      >
        <FormGroup className="mb-0">
          <label className="form-control-label textWhite d-block filter-label-font-size">
            Date Range
          </label>
          <InputGroup>
            <Input
              placeholder="Date Range"
              name="dateRange"
              type="text"
              className="textBg"
              disabled
              value={label}
              // onChange={onChange}
            />
            <InputGroupText className="textBg">
              <i className="fas fa-calendar" />
            </InputGroupText>
          </InputGroup>
        </FormGroup>
      </div>
    </DateRangePicker>
  );
};

export default DateFilter;
