import { useHistory } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  NavbarBrand,
  Nav,
  Container,
  Media,
  NavItem,
  Button,
} from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import logo from "../../assets/img/brand/MiWeigh.png";
import logoutGreen from "../../assets/img/brand/logoutGreen.png";
import logoutWhite from "../../assets/img/brand/logoutWhite.png";
import logoMW from "../../assets/img/brand/logoMW.png";
import logoMWa from "../../assets/img/brand/logoMWa.png";
import AuthService from "../../services/auth.service";
import { useState } from "react";
import { useAuth } from "contexts/AuthContext";

const AdminNavbar = ({ clientName, setClientId, setClientName }) => {
  const history = useHistory();
  const { user, logoutUser } = useAuth();

  const [logoutImage, setLogoutImage] = useState(logoutWhite);
  const [logoDropdown, setLogoDropdown] = useState(logoMW);
  const logOut = () => {
    logoutUser();
    setClientName("");
    setClientId("");
    history.push("/auth/login");
  };

  const handleClick = () => {
    history.push("/admin/index");
  };

  return (
    <>
      <Navbar>
        <Container fluid>
          <NavbarBrand onClick={handleClick} style={{
            cursor: "pointer",
          }}>
            <img
              alt="miwiegh-logo"
              className="navbar-brand-img"
              src={logo}
              width={250}
            />
          </NavbarBrand>
          <NavItem tag="div" className="h1 text-white">
            {clientName}
          </NavItem>
          <NavItem tag="div">
            {/* <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                <Media className="mr-3 d-none d-lg-block">
                    <span
                      className="mb-0 h1 font-weight-bold"
                      style={{ color: "#a7ce39", textTransform: "capitalize" }}
                    >
                      {user.firstName}
                    </span>
                  </Media>
                  <span className="ml-2"
                  onMouseEnter={() => setLogoDropdown(logoMWa)}
                  onMouseLeave={() => setLogoDropdown(logoMW)}>
                    <img
                      alt="..."
                      width={60}
                      // src={require("../../assets/img/brand/logoMW.png").default}
                      src={logoDropdown}
                    />
                  </span>
                  
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    setClientName("");
                    setClientId("");
                    history.push("/admin/index");
                  }}
                >
                  <i className="ni ni-settings" />
                  <span>Back to Main Page</span>
                </DropdownItem>
                <DropdownItem onClick={() => logOut()}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            <NavbarBrand tag="span">
              <Tooltip title="Back to Main Page" arrow>
                <Button className="m-0 p-0 bg-transparent border-0">
                  <Media className="mr-3 d-none d-lg-inline">
                    <span
                      className="mb-0 h1 font-weight-bold"
                      style={{ color: "#a7ce39", textTransform: "capitalize" }}
                    >
                      {user.firstName}
                    </span>
                  </Media>
                  <span
                    className="ml-2"
                    onMouseEnter={() => setLogoDropdown(logoMWa)}
                    onMouseLeave={() => setLogoDropdown(logoMW)}
                    onClick={() => {
                      setClientName("");
                      setClientId("");
                      history.push("/admin/index");
                    }}
                  >
                    <img
                      alt="..."
                      width={50}
                      // src={require("../../assets/img/brand/logoMW.png").default}
                      src={logoDropdown}
                    />
                  </span>
                </Button>
              </Tooltip>
            </NavbarBrand>
            <NavbarBrand>
              <Tooltip title="Logout" arrow>
                <Button
                  className="bg-transparent border-0 p-0 ml-4 pb-2"
                  onClick={() => logOut()}
                  onMouseEnter={() => setLogoutImage(logoutGreen)}
                  onMouseLeave={() => setLogoutImage(logoutWhite)}
                >
                  <img
                    alt="miwiegh-logo"
                    className="navbar-brand-img"
                    src={logoutImage}
                    width={50}
                  />
                </Button>
              </Tooltip>
            </NavbarBrand>
          </NavItem>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
