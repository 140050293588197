import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Nav,
  NavItem,
  Input,
} from "reactstrap";
import axios from "axios";
import Plot from "react-plotly.js";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
// import moment from "moment";
import { rgb } from "d3";
import deepEqual from "utils/deepequal";

function AvgWeightGraph({ title, filtersData, setFiltersData, clientId }) {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isWeightsLoading, setIsWeightsLoading] = useState(false);
  const [filter, setFilter] = useState({ ...filtersData });
  // const [duration, setDuration] = useState("");
  // const [labelFormat, setFormat] = useState("");
  const [weightList, setWeightList] = useState([]);
  const [weight, setWeight] = useState(null);

  useEffect(() => {
    setIsWeightsLoading(true);
    axios
      .get(`${baseUrl}/api/admin/getWeights`, {
        headers: authHeader(),
      })
      .then((res) => {
        setWeightList(res.data.weights);
        setWeight(parseInt(res.data.weights[0]["weight"]));
        setIsWeightsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsWeightsLoading(false);
      });
  }, []);

  // const onChange = (event) => {
  //   const value = event.target.value;
  //   setDuration(value);
  //   if (value === "") {
  //     setFilter({
  //       ...filtersData,
  //     });
  //   } else if (value === "Last Week") {
  //     const start = moment().subtract(1, "weeks").utcOffset(0, true).format();
  //     const end = moment().utcOffset(0, true).format();
  //     setFilter((prevState) => {
  //       return {
  //         ...prevState,
  //         start_date: start,
  //         end_date: end,
  //       };
  //     });
  //   } else if (value === "Last Month") {
  //     const start = moment().subtract(1, "months").utcOffset(0, true).format();
  //     const end = moment().utcOffset(0, true).format();
  //     setFilter((prevState) => {
  //       return {
  //         ...prevState,
  //         start_date: start,
  //         end_date: end,
  //       };
  //     });
  //   } else if (value === "Last Year") {
  //     const start = moment().subtract(1, "years").utcOffset(0, true).format();
  //     const end = moment().utcOffset(0, true).format();
  //     setFilter((prevState) => {
  //       return {
  //         ...prevState,
  //         start_date: start,
  //         end_date: end,
  //       };
  //     });
  //   }
  // };

  useEffect(() => {
    // setDuration("");
    if (deepEqual(filtersData, filter)) return;
    setFilter({ ...filtersData });
  }, [filtersData, clientId]);

  useEffect(() => {
    if (isWeightsLoading || !weight) return;
    setIsLoading(true);
    setChartData([]);
    setChartLabels([]);
    axios
      .get(`${baseUrl}/api/mining/avgWeightGraph`, {
        params: { ...filter, clientId, weight },
        headers: authHeader(),
      })
      .then((res) => {
        let labels = [];
        let data = {
          avgWeight: [],
          setPoint: [],
          recipeWeight: [],
        };
        res.data.response.data.forEach((item) => {
          labels.push(item.x);
          data.avgWeight.push(item.y);
        });
        res.data.response.setPoint.forEach((item) => {
          labels.push(item.x);
          data.setPoint.push(item.y);
        });
        res.data.response.recipeWeight.forEach((item) => {
          labels.push(item.x);
          data.recipeWeight.push(item.y);
        });
        setChartLabels(labels);
        // setFormat(res.data.days > 7 ? "%d %B \n %Y" : " %h:%M \n %d %B (%a)"); // setting the format for labels
        setChartData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setChartData([]);
        setChartLabels([]);
      });
  }, [filter, clientId, weight, isWeightsLoading]);

  const avgWeightGraph = {
    x: chartLabels,
    y: chartData && chartData.avgWeight,
    type: "line",
    // mode: "lines",
    name: "Average Weight",
    marker: { color: rgb(167, 0, 57) },
  };

  const setPointGraph = {
    x: chartLabels,
    y: chartData && chartData.setPoint,
    type: "line",
    // mode: "lines",
    name: "Set Point",
    marker: { color: "#5661c8" },
  };

  const recipeWeightGraph = {
    x: chartLabels,
    y: chartData && chartData.recipeWeight,
    type: "line",
    // mode: "lines",
    name: "Recipe Weight",
    marker: { color: "#fcba03" },
  };

  const formattedChartData = [avgWeightGraph, setPointGraph, recipeWeightGraph];

  const options2 = {
    //title: 'KPI',
    autosize: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: {
      title: "Average Weight (grams)",
      // titlefont: { color: "rgb(167, 206, 57)" },
      // tickfont: { color: "rgb(167, 206, 57)" },
    },
    // yaxis2: {
    //   title: "GA Cost",
    //   titlefont: { color: "rgb(167, 206, 57)" },
    //   tickfont: { color: "rgb(167, 206, 57)" },
    //   overlaying: "y",
    //   side: "right",
    // },
    xaxis: {
      // tickformat: labelFormat,
      // title: "Time",
      // titlefont: { color: "rgb(167, 206, 57)" },
      // tickfont: { color: "rgb(167, 206, 57)" },
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ? (
    <div className="m-5">Loading...</div>
  ) : (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">{title}</h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              {/* <NavItem className="text-white pt-1">Duration:</NavItem> */}
              {/* <NavItem>
                <Input
                  placeholder="Duration"
                  name="country_id"
                  type="select"
                  value={duration}
                  className="textBg"
                  bsSize="sm"
                  style={{ color: "#000000" }}
                  onChange={onChange}
                >
                  {["", "Last Week", "Last Month", "Last Year"].map(
                    (option, idx) => {
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    }
                  )}
                </Input>
              </NavItem> */}
              <NavItem className="text-white pt-1">Weights:</NavItem>
              <NavItem>
                <Input
                  placeholder="Duration"
                  name="country_id"
                  type="select"
                  value={weight}
                  className="textBg"
                  bsSize="sm"
                  style={{ color: "#000000" }}
                  onChange={(e) => setWeight(parseInt(e.target.value))}
                >
                  {weightList.map((option, idx) => {
                    return (
                      <option key={idx} value={option.weight}>
                        {option.weight}
                      </option>
                    );
                  })}
                </Input>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {chartLabels.length > 0 ? (
          <div className="chart" style={{ height: "auto" }}>
            <div class="chart-container-bar">
              {chartData && (
                <Plot
                  data={formattedChartData}
                  className="w-100 h-100"
                  useResizeHandler
                  layout={options2}
                  config={config}
                  onRelayout={(e) => {
                    if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
                      setFiltersData({
                        ...filtersData,
                        start_date: new Date(e["xaxis.range[0]"]).toISOString(),
                        end_date: new Date(e["xaxis.range[1]"]).toISOString(),
                      });
                    }
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="m-5 text-muted">No Data Found</div>
        )}
      </CardBody>
    </Card>
  );
}

export default AvgWeightGraph;
