import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import BarComponent from "./BarComponent";
import LineChartComponent from "./LineChartComponent";

function AnalyticsComponent({ title, barData, lineChartData }) {
  return (
    <Card className="shadow w-100 mb-4">
      <CardHeader className="bg-transparent">
        <h2 className="mb-0 text-color-mw">{title}</h2>
      </CardHeader>
      <CardBody className="p-0">
        <BarComponent barData={barData} title={title} />
        <LineChartComponent lineChartData={lineChartData} title={title} />
      </CardBody>
    </Card>
  );
}

export default AnalyticsComponent;
