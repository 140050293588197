import React, { useState, useEffect } from 'react';
import { Container, CardBody, Form, Card, Row, Col, Button } from 'reactstrap';
import DateFilter from 'views/examples/DateFilter';
import Select, { components } from 'react-select';

const customStyles = {
  input: base => ({
    ...base,
    color: 'white'
  }),
  singleValue: base => ({
    ...base,
    color: 'white'
  }),
  placeholder: base => ({
    ...base,
    color: 'white'
  }),
  control: (base, state) => ({
    ...base,
    // background: "#023950",
    // background: "#737977",
    background: '#19312e',
    // match with the menu
    borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
    // Overwrittes the different states of border
    // borderColor: state.isFocused ? "yellow" : "green",
    borderColor: state.isFocused ? '#a7ce39' : '#8898aa',
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? '#a7ce39' : '#a7ce39'
    }
  }),
  menu: base => ({
    ...base,
    zIndex: 2000,
    // background: "#737977",
    background: '#19312e',
    color: 'white',
    // override border radius to match the box
    border: '1px solid #8898aa',
    // border: '1px solid #a7ce39',
    borderRadius: 5,
    // kill the gap
    marginTop: 0
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0
    // "::-webkit-scrollbar": {
    //   display: "none",
    // },
    // overflow: 'hidden'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      // backgroundColor: isFocused ? "#2462ad" : null,
      backgroundColor: isFocused ? '#a7ce39' : null,
      color: isFocused ? 'black' : 'white'
      // fontWeight: 'bold'
      // color: "#333333",
    };
  }
};

const DropdownIndicator = (props, image) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={image} width={25} alt='' />
    </components.DropdownIndicator>
  );
};

function Filters({ handleApplyFilters, filtersData, clientId }) {
  const [filterState, setFilterState] = useState({
    ...filtersData
  });

  useEffect(() => {
    setFilterState({ ...filtersData });
  }, [filtersData]);

  const onChangeSelectValue = (value, name) => {
    console.log(name, value);
    setFilterState(prevState => {
      return {
        ...prevState,
        [name.name]: value.value
      };
    });
  };

  const handleDateRange = (start_date, end_date) => {
    setFilterState(prevState => {
      return {
        ...prevState,
        start_date,
        end_date
      };
    });
  };

  const handleApplyFromDateFilter = () => {
    handleApplyFilters(filterState);
  };

  return (
    <Card>
      <CardBody className='rounded px-5 py-4'>
        <Form>
          <div className=''>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
                xxl={2}
                className='mb-3'
              >
                <DateFilter
                  filtersData={filtersData}
                  handleDateRange={handleDateRange}
                  handleApplyFromDateFilter={handleApplyFromDateFilter}
                />
              </Col>

              <Col
                className='justify-content-end align-items-center d-flex pt-4 mb-3'
                xs={12}
                sm={12}
                md={6}
                lg={8} // 4
                xl={8} // 4
                xxl={10} // 2
              >
                <Button
                  style={{ backgroundColor: '#a7ce39', border: 'none' }}
                  onClick={() => handleApplyFilters(filterState)}
                >
                  Apply
                </Button>
                {/* <Tooltip title="Clear Filters" placement="top" arrow> */}
                <Button
                  className=''
                  color='secondary'
                  onClick={() => {
                    const today = new Date();
                    const oneMonthAgo = new Date();
                    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
                    oneMonthAgo.setDate(oneMonthAgo.getDate() - 1);

                    console.log('oneMonthAgo', oneMonthAgo);
                    const yesterday = new Date(today);

                    yesterday.setDate(yesterday.getDate() - 7);
                    setFilterState({
                      start_date: oneMonthAgo.toISOString(),
                      end_date: today.toISOString(),
                      recipe: 'All',
                      line: 'All',
                      kpiMin: 0,
                      kpiMax: 200
                      // gaMin: -9999,
                      // gaMax: 9999,
                    });
                    handleApplyFilters({
                      start_date: oneMonthAgo.toISOString(),
                      end_date: today.toISOString(),
                      recipe: 'All',
                      line: 'All',
                      kpiMin: 0,
                      kpiMax: 200
                      // gaMin: -9999,
                      // gaMax: 9999,
                    });
                  }}
                >
                  Clear
                  {/* <i className="fa fa-times" /> */}
                </Button>
                {/* </Tooltip> */}
              </Col>
            </Row>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}

export default Filters;
