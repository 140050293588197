import React from "react";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import { Chart } from "react-chartjs-2";
import Plot from "react-plotly.js";
import * as Chart1 from "chart.js";

function OpKpi({ chartData, chartLabels, title, isLoading }) {
  const options = {
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "#fff",
        },
      },
    },
    scales: {
      r: {
        grid: {
          color: "#89aa9f",
        },
        angleLines: {
          color: "#89aa9f",
        },
        pointLabels: {
          // https://www.chartjs.org/docs/latest/axes/radial/#point-labels
          color: "#fff",
          font: {
            size: 16,
          },
        },
        ticks: {
          // https://www.chartjs.org/docs/latest/axes/radial/#ticks
          color: "#fff",
          backdropColor: "transparent", // https://www.chartjs.org/docs/latest/axes/_common_ticks.html
        },
      },
    },
    responsive: true,
    // interaction: {
    //   mode: "index",
    //   intersect: false,
    // },
  };

  let colorArray =
    chartData &&
    chartData.data.map((item) => {
      if (item > 100) {
        return "#00FF00";
      } else {
        return "#EC1C24";
      }
    });

  const data = {
    labels: chartLabels && chartLabels,
    datasets: [
      {
        label: "KPI %",
        // backgroundColor: '#00FF00',
        data: chartData && chartData.data,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
      },
      // {
      //   label: "Packs Produced",
      //   // backgroundColor: '#EC1C24',
      //   data: chartData && chartData.packData,
      //   fill: true,
      //   backgroundColor: 'rgba(54, 162, 235, 0.2)',
      //   borderColor: 'rgb(54, 162, 235)',
      //   pointBackgroundColor: 'rgb(54, 162, 235)',
      //   pointBorderColor: '#fff',
      //   pointHoverBackgroundColor: '#fff',
      //   pointHoverBorderColor: 'rgb(54, 162, 235)'
      // },
    ],
  };

  const dataKpi = {
    x: chartLabels,
    y: chartData && chartData.data,
    type: "bar",
    // yaxis: "y2",
    // mode: "lines",
    name: "KPI %",
    // marker: { color: "#e7f551" },
    marker: { color: colorArray },
    // width: 0.3
    offsetgroup: "1",
  };

  // const data = {
  //   x: chartLabels,
  //   y: chartData && chartData.packData,
  //   type: "bar",
  //   yaxis: "y2",
  //   // mode: "lines",
  //   name: "Packs Produced",
  //   marker: { color: "#0000ff" },
  //   // marker: { color: colorArray },
  //   // width: 0.3
  //   offsetgroup: "2"
  // }

  const formattedChartData = [dataKpi, data];

  const options2 = {
    showlegend: true,
    autosize: true,
    //title: 'KPI',
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: { title: "KPI (%)" },
    yaxis2: {
      title: "Packs",
      titlefont: { color: "rgb(167, 206, 57)" },
      tickfont: { color: "rgb(167, 206, 57)" },
      overlaying: "y",
      side: "right",
    },
    xaxis: {
      tickformat: "%h:%M \n %d %B (%a)",
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  // for (let i=0; i< data.datasets[0].data.length; i++) {
  //   if (data.datasets[0].data[i] > 70) {
  //     data.datasets[0].backgroundColor[i] = '#00FF00'
  //   }
  // }

  return isLoading ? (
    <div className="m-5">Loading...</div>
  ) : (
    <Card className="shadow">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">{title}</h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart" style={{ height: "auto" }}>
          <div class="chart-container-bar">
            <Chart type="radar" data={data} options={options} />
            {/* <Plot
              data={formattedChartData}
              className="w-100 h-100"
              useResizeHandler
              layout={options2}
              config={config}
            /> */}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default OpKpi;
