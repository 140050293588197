import axios from "axios";
import authHeader from "./auth-header";
import { baseUrl } from "constants/url";
import { localStorage_token_key } from "constants/url";

axios.defaults.baseURL = baseUrl;
// axios.defaults.headers.common["x-access-token"] = localStorage.getItem(localStorage_token_key)
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(localStorage_token_key);
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    // const originalRequest = error.config;
    // if (error.response.status === 403 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   const access_token = await refreshAccessToken();
    //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    //   return axios(originalRequest);
    // }
    console.log(error.response);
    if (error.response && error.response.status === 401) {
      localStorage.removeItem(localStorage_token_key)
      // window.location.reload();
      // history.push("/auth/login");

    }
    return Promise.reject(error);
  }
);

const API_URL = `${baseUrl}/api/test/`;
const getPublicContent = () => {
  return axios.get(API_URL + "all");
};
const getUserBoard = () => {
  return axios.get(API_URL + "user");
};
const getModeratorBoard = () => {
  return axios.get(API_URL + "mod");
};
const getAdminBoard = () => {
  return axios.get(API_URL + "admin");
};
const ApiService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
};
export const api = axios;
export default ApiService;