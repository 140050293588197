import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/auth.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Sentry from "@sentry/react";
import { useAuth } from "contexts/AuthContext";

const Login = () => {
  const { signInUser } = useAuth();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showTwoFactorCode, setShowTwoFactorCode] = useState(false);
  const [showResendCodeButton, setShowResendCodeButton] = useState(false);
  const [code, setCode] = useState("");
  const [saveBrowser, setSaveBrowser] = useState(false);
  // const [userEmail, setUserEmail] = useState("");

  const history = useHistory();
  // const userExists = AuthService.getCurrentUser();
  // const verifyCodeScreen = AuthService.verifyCodeScreen();
  // console.log('Verifying Code Screen: ', verifyCodeScreen);
  // const browserSaved = AuthService.getSaveBrowser();
  const userBrowserToken = AuthService.getUserBrowserToken();
  // if (userExists && !verifyCodeScreen) {
  // if (userExists && !userExists.verifyCodeScreen) {
  //   history.push("/admin/index");
  // }

  const handleVerifyCode = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    Sentry.captureMessage(`User submitted code for two-factor login `, {
      level: "info", // or "success" based on your custom levels, if any
      user: {
        id: user, // ideally the unique identifier for the user
        code,
      },
    });
    AuthService.verifyTwoFactorCode(user, code, saveBrowser)
      .then((res) => {
        // localStorage.setItem("user", JSON.stringify({...userExists, accessToken: res.data.accessToken, mfaVerified:res.data.mfaVerified, verifyCodeScreen: false}));
        // localStorage.setItem(
        //   "user",
        //   JSON.stringify({
        //     ...userExists,
        //     ...res.data,
        //     verifyCodeScreen: false,
        //   })
        // );
        signInUser(res.data.accessToken, res.data.user);
        localStorage.setItem("userBrowserToken", res.data.userBrowserToken);
        Sentry.captureMessage(`User successfully Logged In with mfa code`, {
          level: "info", // or "success" based on your custom levels, if any
          user: {
            id: user, // ideally the unique identifier for the user
            code,
          },
        });
        // history.push("/admin/index");
        setIsLoading(false);
      })
      .catch((err) => {
        const errMessage =
          err.message === "Network Error"
            ? "Server error!"
            : err.response.data.message;
        setErrorMessage(errMessage);
        Sentry.captureMessage(`User verify mfa failed because :${errMessage}`, {
          level: "error", // or "success" based on your custom levels, if any
          user: {
            id: user, // ideally the unique identifier for the user
            code,
          },
        });
        setIsLoading(false);
      });
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (!password.trim()) {
      setErrorMessage("Password cannot be empty or contain only spaces.");
      return;
    } else if (password.length < 3) {
      setErrorMessage("Password must be at least 3 characters long.");
      return;
    }
    setIsLoading(true);
    Sentry.withScope(function (scope) {
      scope.setFingerprint(["login-attempt-failure"]);

      Sentry.captureMessage("User Trying to Login", {
        level: "info", // or "success" based on your custom levels, if any
        user: {
          id: user, // ideally the unique identifier for the user
          password,
        },
      });

      AuthService.login(user, password, userBrowserToken)
        .then((res) => {
          if (res.data.userMfaVerified) {
            signInUser(res.data.accessToken, res.data.user);
            // localStorage.setItem(
            //   "user",
            //   JSON.stringify({ ...res.data, verifyCodeScreen: false })
            // );

            // localStorage.setItem("userCodeVerified", true);
            // history.push("/admin/index");
            setIsLoading(false);
            Sentry.captureMessage("User SuccessFully Signed In", {
              level: "info", // or "success" based on your custom levels, if any
              user: {
                id: user, // ideally the unique identifier for the user
                password,
              },
            });
          } else {
            let message = "";
            let senturyMessage = "";
            if (userBrowserToken && res.data.userBrowserToken === null) {
              message =
                "Session expired for this browser. Please verify again. Verification code sent successfully to your email.";
              senturyMessage =
                "User Needs mfa to login because Session expired for this browser.";
            } else {
              senturyMessage =
                "User Needs mfa to login because User MFA not verified.";
              message = "Verification code sent successfully to your email.";
            }
            Sentry.captureMessage(senturyMessage, {
              level: "info", // or "success" based on your custom levels, if any
              user: {
                id: user, // ideally the unique identifier for the user
                password,
              },
            });
            AuthService.sendTwoFactorCode(user)
              .then((resData) => {
                setSuccessMessage(message);
                setTimeout(() => {
                  setSuccessMessage("");
                }, 5000);
                setTimeout(() => {
                  setShowResendCodeButton(true);
                }, 60000);
                // setUserEmail(res.data.email);
                setShowTwoFactorCode(true);
                // if (res.data.accessToken) {
                // localStorage.setItem(
                //   "user",
                //   JSON.stringify({ ...res.data, verifyCodeScreen: true })
                // );
                // }
                // sessionStorage.setItem("user", user);
                setIsLoading(false);
              })
              .catch((errData) => {
                setErrorMessage(
                  errData.message === "Network Error"
                    ? "Server error!"
                    : errData.response.data.message
                );
                setIsLoading(false);
              });
          }

          // if (browserSaved) {
          //   if (res.data.accessToken) {
          //     localStorage.setItem("user", JSON.stringify({...res.data, verifyCodeScreen: false}));
          //     localStorage.setItem("userCodeVerified", true);
          //   }
          //   sessionStorage.setItem("user", user);
          //   history.push("/admin/index");
          //   setIsLoading(false);
          // } else {
          //   localStorage.setItem("userCodeVerified", false);
          //   AuthService.sendTwoFactorCode(res.data.email)
          //     .then((resData) => {
          //       setSuccessMessage("Verification code sent successfully to your email.");
          //       setTimeout(() => {
          //         setSuccessMessage("");
          //       }, 5000);
          //       setTimeout(() => {
          //         setShowResendCodeButton(true);
          //       }, 60000);
          //       setUserEmail(res.data.email);
          //       setShowTwoFactorCode(true);
          //       if (res.data.accessToken) {
          //         localStorage.setItem("user", JSON.stringify({...res.data, verifyCodeScreen: true}));
          //       }
          //       sessionStorage.setItem("user", user);
          //       setIsLoading(false);
          //     })
          //     .catch((errData) => {
          //       setErrorMessage(
          //         errData.message === "Network Error"
          //           ? "Server error!"
          //           : errData.response.data.message
          //       );
          //       setIsLoading(false);
          //     });
          // }
          // if (res.role === "admin") {
          //   history.push("/admin/index");
          // } else {
          //   history.push("/admin/view_data");
          // }
        })
        .catch((err) => {
          const errMessage =
            err.message === "Network Error"
              ? "Server error!"
              : err.response.data.message;
          setErrorMessage(errMessage);

          Sentry.captureMessage(`User Login Failed :${errMessage}`, {
            level: "error", // or "success" based on your custom levels, if any
            user: {
              id: user, // ideally the unique identifier for the user
              password,
            },
          });
          setIsLoading(false);
        });
    });
  };

  const resendVerificationCode = () => {
    setShowResendCodeButton(false);
    setIsLoading(true);

    AuthService.sendTwoFactorCode(user)
      .then((resData) => {
        setSuccessMessage("Verification code sent successfully to your email.");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
        setTimeout(() => {
          setShowResendCodeButton(true);
        }, 60000);
        setIsLoading(false);
      })
      .catch((errData) => {
        setErrorMessage(
          errData.message === "Network Error"
            ? "Server error!"
            : errData.response.data.message
        );
        setIsLoading(false);
      });
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="h1 text-center mb-4" style={{ color: "#a7ce39" }}>
              <div>Login</div>
            </div>
            {(errorMessage || successMessage) && (
              <div
                className="h4 text-center mb-4"
                style={
                  errorMessage ? { color: "#ff0000" } : { color: "#9ACD32" }
                }
              >
                <div>{successMessage ? successMessage : errorMessage}</div>
              </div>
            )}

            {showTwoFactorCode ? (
              <>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="textBg">
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Enter verification code"
                        type="text"
                        className="textBg"
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      type="submit"
                      onClick={handleVerifyCode}
                      style={{ backgroundColor: "#a7ce39", border: "none" }}
                    >
                      {isLoading ? (
                        <>
                          Verify <CircularProgress className="ml-3" size={15} />
                        </>
                      ) : (
                        "Verify"
                      )}
                    </Button>
                  </div>
                </Form>
                <Row className="my-3">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                        checked={saveBrowser}
                        onChange={(e) => {
                          setSaveBrowser(e.target.checked);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">Save Browser</span>
                      </label>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Form role="form" onSubmit={handleSignIn}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="textBg">
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      placeholder="Username"
                      type="text"
                      autoComplete="new-email"
                      className="textBg"
                      onChange={(e) => setUser(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="textBg">
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      placeholder="Password"
                      type="password"
                      className="textBg"
                      autoComplete="new-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    type="submit"
                    style={{ backgroundColor: "#a7ce39", border: "none" }}
                  >
                    {isLoading ? (
                      <>
                        Sign In <CircularProgress className="ml-3" size={15} />
                      </>
                    ) : (
                      "Sign In"
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </CardBody>
        </Card>
        {!showTwoFactorCode && (
          <Row className="mt-3">
            <Col xs="6">
              <span
                className="h3"
                style={{ cursor: "pointer", color: "#a7ce39" }}
                onClick={(e) => history.push("/auth/forgot-password")}
              >
                <small>Forgot password?</small>
              </span>
            </Col>
            {/* <Col className="text-right" xs="6">
            <span
              className="h3"
              style={{ cursor: "pointer", color: "#a7ce39" }}
              onClick={(e) => history.push("/auth/register")}
            >
              <small>New User? Register</small>
            </span>
          </Col> */}
          </Row>
        )}
        {showResendCodeButton && (
          <Row className="mt-3">
            <Col xs="6">
              <span
                className="h3"
                style={{ cursor: "pointer", color: "#a7ce39" }}
                onClick={() => resendVerificationCode()}
              >
                <small>Resend Verification Code?</small>
              </span>
            </Col>
            {/* <Col className="text-right" xs="6">
            <span
              className="h3"
              style={{ cursor: "pointer", color: "#a7ce39" }}
              onClick={(e) => history.push("/auth/register")}
            >
              <small>New User? Register</small>
            </span>
          </Col> */}
          </Row>
        )}
      </Col>
    </>
  );
};

export default Login;
