import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import { Card, CardHeader, CardBody } from "reactstrap";

function TopValuesBar({ topValues, title, color, yaxisTitle }) {
  console.log("Top Values: ", topValues);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (topValues) {
      let x = [];
      let y = [];
      let hoverText = [];
      topValues.forEach((item) => {
        x.push(item.x);
        y.push(item.y);
        hoverText.push(item.x);
      });
      let barData = {
        x: x,
        y: y,
        type: "bar",
        yaxis: "y2",
        mode: "lines",
        text: hoverText,
        name: yaxisTitle,
        marker: { color: color },
        width: 0.3,
        hovertemplate: "<b>%{text}</b>: %{y}<extra></extra>", // Custom hover text template
      };

      setData([barData]);
    }
  }, [topValues, title]);

  const layout = {
    showlegend: true,
    autosize: true,
    //title: 'KPI',
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
    xaxis: {
      tickvals: data[0]?.x || [], // Ensure tickvals are set only when data is available
      ticktext: (data[0]?.x || []).map((label) =>
        label.length > 10 ? label.substring(0, 10) + "..." : label
      ),
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return (
    <Card className="shadow w-100 mb-4">
      <CardHeader className="bg-transparent">
        <h2 className="mb-0 text-color-mw">{title}</h2>
      </CardHeader>
      <CardBody className="p-0">
        <Plot
          data={data}
          className="w-100 h-100"
          useResizeHandler
          layout={layout}
          config={config}
          // onRelayout={(e) => {
          //   if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
          //     setFiltersData({
          //       ...filtersData,
          //       start_date: new Date(e["xaxis.range[0]"]).toISOString(),
          //       end_date: new Date(e["xaxis.range[1]"]).toISOString(),
          //     });
          //   }
          // }}
        />
      </CardBody>
    </Card>
  );
}

export default TopValuesBar;
