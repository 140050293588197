import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

import axios from "axios";
import OpPerformance from "views/charts/OpPerformance";
import AvgWeight from "views/charts/AvgWeight";
import { baseUrl } from "constants/url";
import AvgSpeedMin from "views/charts/AvgSpeedMin";
import KpiMin from "views/charts/KpiMin";
import Report from "./Report";
import authHeader from "services/auth-header";
import ModaloperatorTable from "views/TableContainer/ModaloperatorTable";
import OpKpi from "views/charts/OpKpi";
import ModalDataTable from "./ModalDataTable";
import OpPacks from "views/charts/OpPacks";

function InfoModal({ isOpen, toggle, modalTitle, modalData, clientId }) {
  const [setPoint, setSetPoint] = useState("Not Available");
  const [otherData, setOtherData] = useState({});
  const [opTableData, setOpTableData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [chartLabels, setChartLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSetPoint("Loading...");
    setIsLoading(true);
    setChartLabels([]);
    setChartData({});
    if (modalData) {
      axios
        .get(
          `${baseUrl}/api/modal/modal-card-data/data/${clientId}/${modalData.Batch_ID}`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          setSetPoint(res.data.count);
          setOtherData(res.data.data);
          setOpTableData(res.data.table_body);
          let labels = [];
          let data = [];
          res.data.window_scales_kpi.forEach((item) => {
            labels.push(item.x);
            data.push(item.y);
          });
          let packData = [];
          res.data.window_scales_packs.forEach((item) => {
            packData.push(item.y);
          });
          setChartLabels(labels);
          setChartData({ data, packData });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSetPoint("Not available");
          setChartLabels([]);
          setChartData({});
          setIsLoading(false);
        });
    }
  }, [modalData, clientId]);

  const tableColumns = [
    {
      id: "name",
      className: "text-left table-data-font",
    },
    {
      id: "value",
      className: "text-right table-data-font",
    },
  ];

  const tableData1 = [
    {
      name: "Report for Batch",
      // value: modalData && modalData.Batch_ID,
      value: otherData && otherData.batch_number,
    },
    {
      name: "Date & Time",
      value: modalData.Timestamp
        ? modalData.Timestamp
        : modalData.TIMESTAMP
        ? modalData.TIMESTAMP
        : "Not Available",
    },
    {
      name: "Recipe",
      value: modalData.Recipe,
    },
    {
      name: "Line",
      value: modalData.Line,
    },
    {
      name: "KPI",
      value: modalData ? modalData.KPI + "%" : "",
    },
    {
      name: "Packs Produced",
      value: modalData.Total_Packs ? modalData.Total_Packs : "Not Available",
    },
    {
      name: "Notes",
      value: modalData.Notes ? modalData.Notes : "Not Available",
    },
  ];

  const tableData2 = [
    {
      name: "Start Line Leader",
      value: otherData && otherData.start_line_leader,
    },
    {
      name: "End Line Leader",
      value: modalData && modalData.Line_Leader,
    },
    {
      name: "Run Time",
      value: modalData.Run_Time + " (Minutes)",
    },
    {
      name: "Target Speed",
      value: otherData && otherData.target_speed,
    },
    {
      name: "No of Operators",
      value: otherData && otherData.operators,
    },
    {
      name: "No of Scales",
      value: otherData && otherData.scales,
    },
  ];

  const tableData3 = [
    {
      name: "Cost Per Pack",
      value: modalData && "£" + modalData.Pack_Cost,
    },
    {
      name: "Give Away Cost",
      value: modalData && "£" + modalData.Ga_Cost,
    },
    {
      name: "Product Cost",
      value: modalData && "£" + modalData.Prd_Cost,
    },
    {
      name: "Labour Cost",
      value: modalData && "£" + modalData.Lbr_Cost,
    },
    {
      name: "Extra Cost",
      value: modalData && "£" + modalData.Extr_Cost,
    },
    {
      name: "Total Cost",
      value: modalData && "£" + modalData.Total_Cost,
    },
  ];

  const tableData4 = [
    {
      name: "Tolerance",
      value: otherData && otherData.tolerance,
    },
    {
      name: "Average Speed",
      value: modalData && modalData.Avg_Speed,
    },
    {
      name: "- T1 Final",
      value: modalData && modalData.AvT1,
    },
    {
      name: "Average Weight",
      value: modalData && modalData.Avg_Wght + "g",
    },
    {
      name: "Set Point",
      value: setPoint,
    },
    {
      name: otherData && otherData.checkWeigherData && "Standard Deviation",
      value:
        otherData &&
        otherData.checkWeigherData &&
        otherData.checkWeigherData.data.Standard_Deviation,
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdropClassName="modal-backdrop-opacity"
      contentClassName="modal-bg px-4"
      className="modal-width"
    >
      <ModalHeader
        toggle={toggle}
        className="p-3 pt-5"
        close={
          <Report
            recipe={modalData.Recipe}
            id={modalData.Batch_ID}
            clientId={clientId}
            toggle={toggle}
          />
        }
      >
        <h2 className="" style={{ color: "#a7ce39" }}>
          {modalTitle}
        </h2>
      </ModalHeader>
      <ModalBody className="p-3">
        <div className="header-body">
          <Row>
            <Col className="mb-4">
              <Card className="card-stats h-100">
                <CardBody className="">
                  <Row>
                    <ModalDataTable
                      tableColumns={tableColumns}
                      tableData={tableData1}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col className="mb-4">
              <Card className="card-stats h-100">
                <CardBody className="">
                  <Row>
                    <ModalDataTable
                      tableColumns={tableColumns}
                      tableData={tableData2}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col className="mb-4">
              <Card className="card-stats h-100">
                <CardBody className="">
                  <Row>
                    <ModalDataTable
                      tableColumns={tableColumns}
                      tableData={tableData3}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col className="mb-4">
              <Card className="card-stats h-100">
                <CardBody className="">
                  <Row>
                    <ModalDataTable
                      tableColumns={tableColumns}
                      tableData={tableData4}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6" xl="6">
              <Card className="card-stats mb-4">
                <CardBody className="p-0">
                  <AvgSpeedMin
                    title="Avg Speed per Minute"
                    batch_id={modalData.Batch_ID}
                    clientId={clientId}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="6">
              <Card className="card-stats mb-4">
                <CardBody className="p-0">
                  <KpiMin
                    title="Give Away"
                    batch_id={modalData.Batch_ID}
                    clientId={clientId}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="6">
              <Card className="card-stats mb-4">
                <CardBody className="p-0">
                  <OpPerformance
                    title="Operator Performance"
                    batch_id={modalData.Batch_ID}
                    clientId={clientId}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="6">
              <Card className="card-stats mb-4">
                <CardBody className="p-0">
                  <AvgWeight
                    title="Avg Weight per Minute"
                    batch_id={modalData.Batch_ID}
                    clientId={clientId}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6" xl="6">
              <Card className="card-stats" style={{ height: "100%" }}>
                <CardBody className="p-0">
                  {chartData.data && (
                    <OpKpi
                      title="Operator KPI"
                      chartData={chartData}
                      chartLabels={chartLabels}
                      isLoading={isLoading}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="6">
              <Card className="card-stats" style={{ height: "100%" }}>
                <CardBody className="p-0">
                  {chartData.data && (
                    <OpPacks
                      title="Operator Packs"
                      chartData={chartData}
                      chartLabels={chartLabels}
                      isLoading={isLoading}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col lg="12" xl="12">
              <Card className="card-stats" style={{ height: "100%" }}>
                <h2 className="px-4 pt-4 text-color-mw">Operators Summary</h2>
                <CardBody className="p-0">
                  <ModaloperatorTable
                    tableData={opTableData.filter(
                      (el) => +el.ppm !== 0 && !isNaN(el.ppm)
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default InfoModal;
