import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Progress,
  Spinner,
  CardBody,
  Button,
} from "reactstrap";
import Table from "views/shared/Table";
import { TablePagination } from "@material-ui/core";
import InfoModal from "views/shared/InfoModal";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import Recipes from "../charts/Recipes";
import deepEqual from "utils/deepequal";

function DataTable({ filtersData, setFiltersData, clientId }) {
  const [filter, setFilter] = useState({
    filtersData: { ...filtersData },
    page: 0,
    rowsPerPage: 10,
    clientId,
  });
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [count, setCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState({
    key: null,
    title: null,
    modalData: null,
    modalButtonText: null,
  });

  const [isGeneratingReport1, setIsGeneratingReport1] = useState(false)
  const [isGeneratingReport2, setIsGeneratingReport2] = useState(false)

  useEffect(() => {
    if (
      deepEqual(filtersData, filter.filtersData) &&
      filter.clientId === clientId &&
      filter.page === page &&
      filter.rowsPerPage === rowsPerPage
    )
      return;
    setTableData("Loading...");
    setFilter({ filtersData: { ...filtersData }, clientId, page, rowsPerPage });
    axios
      .get(`${baseUrl}/api/dashboard/production_data/${page}/${rowsPerPage}`, {
        params: { ...filtersData, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        console.log("Actual Data from dB: ", res.data.formattedEndData);
        setTableData(res.data.formattedEndData);
        setCount(res.data.count);
      })
      .catch((err) => {
        console.log(err);
        setTableData([]);
      });
  }, [page, rowsPerPage, clientId, filtersData]);

  const tableColumns = [
    {
      id:
        filtersData.end_date.includes("2020") ||
          filtersData.end_date.includes("2021")
          ? "TIMESTAMP"
          : "Timestamp",
      title: "End Time",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Line",
      title: "Line",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Recipe",
      title: "Recipe",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },

    {
      id: "Run_Time",
      title: "Runtime (Mins)",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Total_Packs",
      title: "Packs Produced",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Avg_Speed",
      title: "Avg Speed",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },

    {
      id: "KPI",
      title: "KPI",
      className: "sort table-data-font",
      onClick: null,
      render: (record) => renderHtml("kpi", record),
    },
    {
      id: "Total_Wght",
      title: "Weight",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Operator_Speed",
      title: "Operator Speed",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Pack_Cost",
      title: "Cost per pack",
      className: "sort text-right table-data-font",
      onClick: null,
      render: (record) => renderHtml("Pack_Cost", record),
    },
    // {
    //   id: "action",
    //   title: "ACTION",
    //   className: "sort text-right",
    //   onClick: null,
    //   render: (record) => renderHtml("action", record),
    // },
  ];

  const renderHtml = (key, record) => {
    switch (key) {
      case "action":
        return (
          <>
            <i
              className="text-primary btn fa fa-edit px-1 py-0 m-0"
              onClick={() => {
                setOpenModal(true);
                setInfoModalData({
                  key: "subscriber",
                  title: "Edit Subscriber",
                  modalData: record,
                  modalButtonText: "Save",
                });
              }}
            />
          </>
        );

      case "status":
        return <>{record.status ? "Active" : "Inactive"}</>;

      case "Pack_Cost":
        return <>{record.Pack_Cost} £</>;

      case "kpi":
        return (
          <>
            <div className="d-flex align-items-center">
              <div>
                <Progress
                  className="textBg"
                  max="100"
                  value={record.KPI.toString()}
                  barClassName="bg-info"
                  style={{ height: "20px" }}
                >
                  <span className="progress-bg font-weight-bold">
                    {record.KPI}%
                  </span>
                </Progress>
              </div>
            </div>
          </>
        );

      default:
        break;
    }
  };

  const handleReport = async () => {
    try {
      setIsGeneratingReport1(true)
      const res = await axios.get(
        `${baseUrl}/api/dashboard/production_data_report`,
        {
          params: { ...filtersData, clientId },
          headers: authHeader(),
        }
      );
      window.open(res.data.url, "_blank");
    } catch (err) {

    } finally {
      setIsGeneratingReport1(false)
    }
  };

  const handleOperatorsReport = async () => {
    try {
      setIsGeneratingReport2(true)
      const res = await axios.get(
        `${baseUrl}/api/dashboard/operators-data-report`,
        {
          params: { ...filtersData, clientId },
          headers: authHeader(),
        }
      );
      window.open(res.data.url, "_blank");
    } catch (err) {

    } finally {
      setIsGeneratingReport2(false)
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {/* <Container fluid> */}
      <Row className="">
        {/* <Col lg="12" xl="12"> */}
        <Col
          xs="12"
          // xs={
          //   (filtersData.recipe && filtersData.recipe !== "All") ||
          //   (filtersData.line && filtersData.line !== "All")
          //     ? "12"
          //     : "12"
          // }
          // md={
          //   (filtersData.recipe && filtersData.recipe !== "All") ||
          //   (filtersData.line && filtersData.line !== "All")
          //     ? "12"
          //     : "8"
          // }
          // xl={
          //   (filtersData.recipe && filtersData.recipe !== "All") ||
          //   (filtersData.line && filtersData.line !== "All")
          //     ? "12"
          //     : "9"
          // }
          className="mb-4"
        >
          <Card className="shadow h-100">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: "2rem",
              }}
            >
              <h2 className="px-4 pt-4 text-color-mw">Production Data</h2>
              <Button
                style={{
                  backgroundColor: "#a7ce39",
                  border: "none",
                  height: "33px",
                  width: "fit-content",
                  marginLeft: "auto",
                }}
                className="px-4"
                size="sm"
                onClick={handleOperatorsReport}
                disabled={isGeneratingReport2}
              >
                {isGeneratingReport2 ? <Spinner size="sm" /> :
                  <>             Get Operators Report
                    <i className="fa fa-plus ml-2" />
                  </>}
              </Button>
              <Button
                style={{
                  backgroundColor: "#a7ce39",
                  border: "none",
                  height: "33px",
                  width: "fit-content",
                  marginLeft: "10px",
                }}
                className="px-4"
                size="sm"
                onClick={handleReport}
                disabled={isGeneratingReport1}
              >
                {isGeneratingReport1 ? <Spinner size="sm" /> :
                  <>
                    Get Production Data Report
                    <i className="fa fa-plus ml-2" />
                  </>}
              </Button>
            </div>
            {tableData === "Loading..." ? (
              <div className="row justify-content-center my-5">
                <Spinner>Loading...</Spinner>
              </div>
            ) : tableData.length > 0 ? (
              <>
                <Table
                  values={tableData}
                  columns={tableColumns}
                  onRowClick={(record) => {
                    setOpenModal(true);
                    setInfoModalData({
                      key: "production",
                      title: "Detailed Production Data",
                      modalData: record,
                      modalButtonText: "Save",
                    });
                  }}
                  tableClasses={{
                    table:
                      "table align-items-center table-flush table-sm border-bottom text-white table-custom",
                    thead: "",
                    tbody: "list table-td-color",
                  }}
                />
                <TablePagination
                  component="div"
                  className="text-muted"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />{" "}
              </>
            ) : (
              <span className="m-4">No Data Found</span>
            )}
          </Card>
        </Col>
        {/* {(filtersData.recipe && filtersData.recipe !== "All") ||
          (filtersData.line && filtersData.line !== "All") ? null : (
            <Col xs={12} md={4} xl="3" className="mb-4">
              <Card className="card-stats recipe-height-custom">
                <CardBody className="p-0">
                  <Recipes
                    filtersData={filtersData}
                    setFiltersData={setFiltersData}
                    clientId={clientId}
                  />
                </CardBody>
              </Card>
            </Col>
          )} */}
      </Row>
      {/* </Container> */}
      {openModal && (
        <InfoModal
          isOpen={openModal}
          toggle={() => setOpenModal((prevState) => !prevState)}
          modalKey={infoModalData.key}
          modalTitle={infoModalData.title}
          modalData={infoModalData.modalData}
          modalButtonText={infoModalData.modalButtonText}
          clientId={clientId}
        />
      )}
    </>
  );
}

export default DataTable;
