import Index from "views/Index.js";
import viewData from "views/ViewData/viewData";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import adminPanel from "views/managementPanel/index";
import Tutorials from "views/Tutorials/index.js";
import ForgotPassword from "views/examples/ForgotPassword";
import ResetPassword from "views/examples/ResetPassword";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    group: "All",
  },
  {
    path: "/panel",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: adminPanel,
    layout: "/admin",
    group: "All",
  },
  {
    path: "/view_data",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: viewData,
    layout: "/admin",
    group: "All",
  },
  {
    path: "/analytics",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: viewData,
    layout: "/admin",
    group: "All",
  },
  {
    path: "/inProgress",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: viewData,
    layout: "/admin",
    group: "All",
  },
  {
    path: "/operatorPerformance",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: viewData,
    layout: "/admin",
    group: "All",
  },
  {
    path: "/tutorials",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Tutorials,
    layout: "/admin",
    group: "All",
  },
  {
    path: "/mining",
    name: "Mining",
    icon: "ni ni-tv-2 text-primary",
    component: viewData,
    layout: "/admin",
    group: "All",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    icon: "ni ni-circle-08 text-pink",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/reset-password/:newToken/:userStatus?",
    name: "ResetPassword",
    icon: "ni ni-circle-08 text-pink",
    component: ResetPassword,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
];
export default routes;
