import axios from "axios";
import { baseUrl } from "../constants/url";
import authHeader from "services/auth-header";

const distributorList = () => {
  return axios.get(`${baseUrl}/api/admin/distributor`, {
    headers: authHeader(),
  });
};

const subscriptionList = () => {
  return axios.get(`${baseUrl}/api/admin/subscriptions`, {
    headers: authHeader(),
  });
};

const clientList = () => {
  return axios.get(`${baseUrl}/api/admin/client`, {
    headers: authHeader(),
  });
};

const versionList = () => {
  return axios.get(`${baseUrl}/api/admin/version`, {
    headers: authHeader(),
  });
};

const machineTypes = () => {
  return axios.get(`${baseUrl}/api/admin/machineTypes`, {
    headers: authHeader(),
  });
};

const rolesList = () => {
  return axios.get(`${baseUrl}/api/admin/userGroups`, {
    headers: authHeader(),
  });
};

export const modalConfiguration = {
  distributor: [
    {
      label: "Name",
      type: "text",
      name: "name",
      placeholder: "Name",
      required: true,
    },
    {
      label: "Location",
      type: "text",
      name: "location",
      placeholder: "Location",
    },
    {
      label: "Contact Name",
      type: "text",
      name: "contactName",
      placeholder: "Contact Name",
      required: true,
    },
    {
      label: "Contact Email",
      type: "text",
      name: "contactEmail",
      placeholder: "Contact Email",
      required: true,
    },
    {
      label: "Contact Number",
      type: "text",
      name: "contactNumber",
      placeholder: "Contact Number",
      required: true,
    },
  ],
  client: [
    {
      label: "Client Id",
      type: "text",
      name: "clientId",
      placeholder: "Client Id",
      required: true,
    },
    {
      label: "Name",
      type: "text",
      name: "name",
      placeholder: "Name",
      required: true,
    },
    {
      label: "Location",
      type: "text",
      name: "location",
      placeholder: "Location",
    },
    {
      label: "Contact Name",
      type: "text",
      name: "contactName",
      placeholder: "Contact Name",
      required: true,
    },
    {
      label: "Contact Email",
      type: "text",
      name: "contactEmail",
      placeholder: "Contact Email",
      required: true,
    },
    {
      label: "Contact Number",
      type: "text",
      name: "contactNumber",
      placeholder: "Contact Number",
      required: true,
    },
    {
      label: "Distributor",
      type: "select",
      name: "distributorId",
      options: distributorList,
      placeholder: "Distributor",
      required: true,
    },
    {
      label: "Subscription",
      type: "select",
      name: "subscriptionId",
      options: subscriptionList,
      placeholder: "Subscription",
      required: true,
    },
    {
      label: "Enabled",
      type: "select",
      name: "is_active",
      required: true,
      options: () => {
        return Promise.resolve({
          data: {
            values: [
              { id: true, name: "Yes" },
              { id: false, name: "No" },
            ],
          },
        });
      },
      placeholder: "Enabled",
      dontUseEmptyOption: false,
    },
  ],
  machine: [
    {
      label: "Client",
      type: "select",
      name: "clientId",
      options: clientList,
      placeholder: "Client",
      required: true,
    },
    {
      label: "Machine Type",
      type: "select",
      name: "machineTypeId",
      options: machineTypes,
      placeholder: "Machine Type",
      required: true,
    },
    {
      label: "Serial No",
      type: "text",
      name: "machineId",
      placeholder: "Serial No",
      required: true,
    },
    {
      label: "Line",
      type: "text",
      name: "lineName",
      placeholder: "Line",
    },
  ],
  machineType: [
    {
      label: "Label",
      type: "text",
      name: "label",
      placeholder: "Label",
      required: true
    },
    {
      label: "Machine Type Image",
      type: "file",
      name: "file",
      placeholder: "Machine Type Image",
      required: true,
    },
  ],
  line: [
    {
      label: "Line Name",
      type: "text",
      name: "lineName",
      placeholder: "Line Name",
      required: true,
    },
    {
      label: "Client",
      type: "select",
      name: "clientId",
      options: clientList,
      placeholder: "Client",
      required: true,
    },
    {
      label: "Recipe Version",
      type: "select",
      name: "versionNo",
      options: versionList,
      placeholder: "Version",
      required: true,
    },
    // {
    //   label: "Machine",
    //   type: "text",
    //   name: "machineName",
    //   placeholder: "Machine",
    // },
  ],
  device: [
    {
      label: "Serial No",
      type: "text",
      name: "serialNo",
      placeholder: "Serial No",
      required: true,
    },
    {
      label: "Client",
      type: "select",
      name: "clientId",
      options: clientList,
      placeholder: "Client",
      required: true,
    }
  ],
  user: [
    {
      label: "Username",
      type: "text",
      name: "username",
      placeholder: "Username",
      required: true
    },
    {
      label: "First Name",
      type: "text",
      name: "firstName",
      placeholder: "First Name",
      required: true
    },
    {
      label: "Last Name",
      type: "text",
      name: "lastName",
      placeholder: "Last Name",
      required: true
    },
    {
      label: "Email",
      type: "text",
      name: "email",
      placeholder: "Email",
      required: true
    },
    {
      label: "Password",
      type: "text",
      name: "password",
      placeholder: "Password",
      required: true
    },
    {
      label: "Client",
      type: "select",
      name: "clientId",
      options: clientList,
      placeholder: "Client",
      required: true
    },
    {
      label: "Role",
      type: "select",
      name: "role",
      options: rolesList,
      placeholder: "Role",
      required: true
    },
    {
      label: "Contact Number",
      type: "text",
      name: "contactNumber",
      placeholder: "Contact Number",
      required: true
    },
  ],
  // recipe: [
  //   {
  //     label: "Field Name",
  //     type: "text",
  //     name: "FieldName",
  //     placeholder: "Field Name",
  //   },
  //   {
  //     label: "Product E",
  //     type: "text",
  //     name: "Product_e",
  //     placeholder: "Product E",
  //   },
  //   {
  //     label: "Product Cost/KG",
  //     type: "text",
  //     name: "ProductCostPerKg",
  //     placeholder: "Product Cost/KG",
  //   },
  //   {
  //     label: "Product Extra Cost",
  //     type: "text",
  //     name: "ProductExtraCost",
  //     placeholder: "Product Extra Cost",
  //   },
  //   {
  //     label: "Product T1SP",
  //     type: "text",
  //     name: "ProductT1SP",
  //     placeholder: "Product T1SP",
  //   },
  //   {
  //     label: "Product Target Speed",
  //     type: "text",
  //     name: "ProductTargetSpeed",
  //     placeholder: "Product Target Speed",
  //   },
  //   {
  //     label: "Product Tolerance",
  //     type: "text",
  //     name: "ProductTolerance",
  //     placeholder: "Product Tolerance",
  //   },
  //   {
  //     label: "Product Weight",
  //     type: "text",
  //     name: "ProductWeight",
  //     placeholder: "Product Weight",
  //   },
  //   {
  //     label: "Count Threshold",
  //     type: "text",
  //     name: "CountThreshold",
  //     placeholder: "Count Threshold",
  //   },
  //   {
  //     label: "Adj Max",
  //     type: "text",
  //     name: "AdjMax",
  //     placeholder: "Adj Max",
  //   },
  //   {
  //     label: "Adj Min",
  //     type: "text",
  //     name: "AdjMin",
  //     placeholder: "Adj Min",
  //   },
  //   {
  //     label: "AdjQtPercStart",
  //     type: "text",
  //     name: "AdjQtPercStart",
  //     placeholder: "AdjQtPercStart",
  //   },
  //   {
  //     label: "AutoAdjustON",
  //     type: "text",
  //     name: "AutoAdjustON",
  //     placeholder: "AutoAdjustON",
  //   },
  //   {
  //     label: "AdjStartWeigh",
  //     type: "text",
  //     name: "AdjStartWeigh",
  //     placeholder: "AdjStartWeigh",
  //   },
  //   {
  //     label: "CwProgramNumber",
  //     type: "text",
  //     name: "CwProgramNumber",
  //     placeholder: "CwProgramNumber",
  //   },
  //   {
  //     label: "CwBypass",
  //     type: "text",
  //     name: "CwBypass",
  //     placeholder: "CwBypass",
  //   },
  //   {
  //     label: "Tare",
  //     type: "text",
  //     name: "Tare",
  //     placeholder: "Tare",
  //   },
  // ],
  version: [
    {
      label: "Version No",
      type: "text",
      name: "versionNo",
      placeholder: "Version No",
      required: true,
    },
    {
      label: "Upload CSV",
      type: "file",
      name: "fileCsv",
      placeholder: "Upload CSV",
      required: true,
    },
  ],
  tutorial: [
    {
      label: "Title",
      type: "text",
      name: "title",
      placeholder: "Title",
      required: true,
    },
    {
      label: "Description",
      type: "text",
      name: "description",
      placeholder: "Description",
      required: true,
    },
    {
      label: "Access",
      type: "select",
      name: "is_restricted",
      placeholder: "Access",
      required: true,
      options: () => {
        return Promise.resolve({
          data: {
            values: [
              { id: true, name: "Open" },
              { id: false, name: "Restricted" },
            ],
          },
        });
      },
    },

    {
      label: "Select Tutorial Video",
      type: "file",
      name: "file",
      required: true,
      placeholder: "Select Tutorial Video",
    },
  ],
};
