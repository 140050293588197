import axios from "axios";
import { baseUrl } from "constants/url";
const API_URL = `${baseUrl}/api/auth/`;
const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};
const login = (username, password, userBrowserToken) => {
  return axios.post(API_URL + "signin", {
    username,
    password,
    userBrowserToken,
  });
  // .then((response) => {
  //   if (response.data.accessToken) {
  //     localStorage.setItem("user", JSON.stringify(response.data));
  //   }
  //   return response.data;
  // });
};
const logout = () => {
  localStorage.removeItem("user");
};
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
// const getSaveBrowser = () => {
//   return JSON.parse(localStorage.getItem("userSaveBrowser"));
// };
const getUserBrowserToken = () => {
  return localStorage.getItem("userBrowserToken");
};
// const getUserCodeVerified = () => {
//   return JSON.parse(localStorage.getItem("userCodeVerified"));
// };
// const verifyCodeScreen = () => {
//   return JSON.parse(localStorage.getItem("verifyCodeScreen"));
// };
const forgotPassword = (email) => {
  return axios.post(API_URL + "forgotPassword", {
    email,
  });
};
const resetPassword = (resetToken, newPassword) => {
  return axios.post(API_URL + "resetPassword", {
    newPassword,
    resetToken,
  });
};
const sendTwoFactorCode = (username) => {
  return axios.post(API_URL + "sendTwoFactorCode", {
    username,
  });
};
const verifyTwoFactorCode = (username, code, saveBrowser) => {
  return axios.post(API_URL + "verifyTwoFactorCode", {
    username,
    code,
    saveBrowser,
  });
};
const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  // getSaveBrowser,
  forgotPassword,
  resetPassword,
  sendTwoFactorCode,
  verifyTwoFactorCode,
  getUserBrowserToken,
  // getUserCodeVerified
  // verifyCodeScreen
};
export default AuthService;
