import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";

function ConfirmationModal({ isOpen, toggle, title, body, confirmText, handleDelete, isDeleting }) {
  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-bg"
      backdropClassName="modal-backdrop-opacity"
    >
      <ModalHeader toggle={toggle} close={<button className="close" onClick={toggle}>
        <div className="text-white rounded-circle">
          <i className="fas fa-times" />
        </div>
      </button>}>
        <h2 style={{color: '#a7ce39'}}>{title}</h2>
      </ModalHeader>
      <ModalBody className="text-muted">{body}</ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
        <Button color="danger" onClick={handleDelete}>
          {isDeleting ? <>Deleting...<Spinner className="ml-2" size='sm' /></> : confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmationModal;
