import { localStorage_browser_token_key } from "constants/url";
import { localStorage_token_key } from "constants/url";
import { useContext, useEffect } from "react";
import { api } from "services/api.service";

const { createContext, useState } = require("react");

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authenticating, setAuthenticating] = useState(true)


  useEffect(() => {
    verifyToken();
  }, []);

  const verifyToken = async () => {
    try {
      const { data } = await api.get('/api/auth/verify');
      setUser(data.user);
      setIsLoggedIn(true);
    } catch (err) {
      localStorage.removeItem(localStorage_token_key);
      localStorage.removeItem(localStorage_browser_token_key)
      setIsLoggedIn(false);
    }
    finally {
      setAuthenticating(false);
    }
  };

  const signInUser = (tk, us) => {
    window.localStorage.setItem(localStorage_token_key, tk);
    setUser(us);
    setIsLoggedIn(true);
  };

  const logoutUser = () => {
    setIsLoggedIn(false);
    setUser(null);
    localStorage.removeItem(localStorage_token_key);
  };



  return (
    <AuthContext.Provider
      displayName='Auth Context'
      value={{
        logoutUser,
        user,
        setUser,
        signInUser,
        isLoggedIn,
        authenticating
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);