import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Progress,
  Spinner,
  Button,
} from "reactstrap";
import Table from "views/shared/Table";
import { TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import deepEqual from "utils/deepequal";

function OperatorsData({ filtersData, clientId, timeRange }) {
  const [filter, setFilter] = useState({
    filtersData: {
      start_date: filtersData.start_date,
      end_date: filtersData.end_date,
      line: filtersData.line,
      page: 0,
      rowsPerPage: 10,
    },
    clientId,
  });
  const [tableData, setTableData] = useState([]);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [totalPacks, setTotalPacks] = useState(null);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      deepEqual(
        {
          start_date: filtersData.start_date,
          end_date: filtersData.end_date,
          line: filtersData.line,
        },
        {
          start_date: filter.filtersData.start_date,
          end_date: filter.filtersData.end_date,
          line: filter.filtersData.line,
        }
      ) &&
      filter.clientId === clientId
    )
      return;
    setTableData("Loading...");
    setFilter({
      filtersData: {
        start_date: filtersData.start_date,
        end_date: filtersData.end_date,
        line: filtersData.line,
        page: 0,
        rowsPerPage: 10,
      },
      clientId,
    });
  }, [clientId, filtersData]);

  const handleReport = () => {
    axios
      .get(`${baseUrl}/api/dashboard/operators_data_report`, {
        params: { ...filter.filtersData, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        window.open(res.data.url, "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setTableData("Loading...");
    axios
      .get(
        `${baseUrl}/api/dashboard/operators_data/${filter.filtersData.page}/${filter.filtersData.rowsPerPage}`,
        {
          params: { ...filter.filtersData, clientId },
          headers: authHeader(),
        }
      )
      .then((res) => {
        setTableData(res.data.operators_data);
        setCount(res.data.pagination.totalRecords);
        setTotalPacks(res.data.totalPacks);
      })
      .catch((err) => {
        console.log(err);
        setTotalPacks(0);
        setTableData([]);
      });
  }, [
    filter,
    filter.filtersData.page,
    filter.filtersData.rowsPerPage,
    clientId,
  ]);

  const tableColumns = [
    {
      id: "operator",
      title: "Operator",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "line",
      title: "Line",
      className: "sort table-data-font",
      onClick: null,
      render: (value) => (
        <>
          {value.line === "Total" ? (
            <span className="text-muted">
              <b>{value.line}</b>
            </span>
          ) : (
            value.line
          )}
        </>
      ),
    },
    {
      id: "packs",
      title: "Packs Produced",
      className: "sort table-data-font",
      onClick: null,
      render: (value) => (
        <>
          {value.line === "Total" ? (
            <span className="text-muted">
              <b>{value.packs}</b>
            </span>
          ) : (
            value.packs
          )}
        </>
      ),
    },
  ];

  function convertTo12HourFormat(time) {
    let [hour, minute] = time.split(":").map(Number);
    let period = "AM";

    if (hour >= 12) {
      period = "PM";
      if (hour > 12) {
        hour -= 12;
      }
    } else if (hour === 0) {
      hour = 12;
    }

    // Ensure hour and minute are always two digits
    hour = String(hour).padStart(2, "0");
    minute = String(minute).padStart(2, "0");

    return `${hour}:${minute} ${period}`;
  }

  const handleChangePage = (event, newPage) => {
    setFilter((st) => ({
      ...st,
      filtersData: {
        ...st.filtersData,
        page: newPage,
      },
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage();
    // setPage(0);
    setFilter((st) => ({
      ...st,
      filtersData: {
        ...st.filtersData,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      },
    }));
  };

  return (
    <>
      <Container fluid>
        <Row className="">
          {/* <Col lg="12" xl="12"> */}
          <Col xs={12} className="mb-4">
            <Card className="shadow h-100">
              <h2
                className="px-4 pt-4 text-color-mw"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Operators Punnets Data&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  style={{
                    backgroundColor: "#a7ce39",
                    border: "none",
                    height: "33px",
                    width: "fit-content",
                  }}
                  className="px-4"
                  size="sm"
                  onClick={handleReport}
                >
                  Get Excel Report
                  <i className="fa fa-plus ml-2" />
                </Button>
              </h2>
              {tableData !== "Loading..." && (
                <h4 className="px-4" style={{ color: "#fff" }}>
                  Time:{" "}
                  {new Date(filter.filtersData.start_date).toLocaleDateString()}{" "}
                  {convertTo12HourFormat(timeRange.start_time)} -{" "}
                  {new Date(filter.filtersData.end_date).toLocaleDateString()}{" "}
                  {convertTo12HourFormat(timeRange.end_time)}
                  &nbsp;&nbsp;&nbsp;&nbsp;Total Packs:{" "}
                  {totalPacks === null ? 0 : totalPacks.toLocaleString()}
                </h4>
              )}

              {tableData === "Loading..." ? (
                <div className="row justify-content-center my-5">
                  <Spinner>Loading...</Spinner>
                </div>
              ) : tableData.length > 0 ? (
                <>
                  <Table
                    values={[...tableData]}
                    columns={tableColumns}
                    tableClasses={{
                      table:
                        "table align-items-center table-flush table-sm border-bottom text-white table-custom",
                      thead: "",
                      tbody: "list table-td-color",
                    }}
                  />
                  <TablePagination
                    component="div"
                    className="text-muted"
                    count={count}
                    page={filter.filtersData.page}
                    onPageChange={handleChangePage}
                    rowsPerPage={filter.filtersData.rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />{" "}
                </>
              ) : (
                <span className="m-4">No Data Found</span>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OperatorsData;
