export const calculateRecipeValues = (values, isPremium = false) => {
  let SystemType = values.SystemType;
  let Product = values.Product;
  let ProductWeight = Number(values.ProductWeight);
  let Tare = Number(values.Tare);
  let Moisture = Number(values.Moisture);
  let Brand = values.Brand;
  let Tier = values.Tier;

  console.log('SystemType', SystemType);
  console.log('ProductWeight', ProductWeight);
  console.log('Tare', Tare);
  console.log('Moisture', Moisture);
  console.log('Product', Product);
  console.log('Brand', Brand);
  console.log('Tier', Tier);
  console.log('ProductWeight', ProductWeight);
  console.log('isPremium', isPremium);

  let recipe = {};
  recipe.ProductWeight = ProductWeight;
  recipe.Tare = Tare;
  recipe.Moisture = Moisture;

  let SPf = ProductWeight * (Moisture / 100 + 1);

  if (SystemType === "Average Weight") {
    recipe.Product_e = 1;
    let SP = Math.round(SPf);
    recipe.WeightSP = SP;

    let T1 = 0;
    if (ProductWeight <= 50) {
      T1 = SPf - SPf * 0.09;
    } else if (ProductWeight <= 100) {
      T1 = SPf - 4.5;
    } else if (ProductWeight <= 200) {
      T1 = SPf - SPf * 0.045;
    } else if (ProductWeight <= 300) {
      T1 = SPf - 9;
    } else if (ProductWeight <= 500) {
      T1 = SPf - SPf * 0.03;
    } else if (ProductWeight <= 1000) {
      T1 = SPf - 15;
    } else if (ProductWeight <= 10000) {
      T1 = SPf - SPf * 0.015;
    }

    T1 = Math.round(T1);
    recipe.ProductT1SP = T1;

    let ProductTolerance = 0;
    if (ProductWeight <= 400) {
      ProductTolerance = SPf - (T1 + 1.3);
      recipe.CountThreshold = Math.round(ProductWeight / 2);
      recipe.AdjStartWeigh = 1;
      recipe.AdjMax = 10;
      recipe.AdjMin = -2;
    } else {
      ProductTolerance = SPf - (T1 + 2);
      recipe.CountThreshold = Math.round(ProductWeight / 3);
      recipe.AdjStartWeigh = 2;
      recipe.AdjMax = 15;
      recipe.AdjMin = -4;
    }
    recipe.ProductTolerance = Math.round(ProductTolerance);
    if (isPremium)  //BRAND_PRODUCT_WEIGHT_TIER_SYS-TYPE
      recipe.FieldName = Brand + " " + Product + " " + ProductWeight.toString() + "g " + Tier + " Avg";
    else
      recipe.FieldName = ProductWeight.toString() + "g " + Product + " Avg";
    recipe.AutoAdjustON = true;
    // recipe.AdjQtPercStart = 10;
    recipe.CwBypass = 0;
  } else {
    recipe.Product_e = 0;
    let ProductTolerance = Number(values.ProductTolerance);
    recipe.ProductTolerance = ProductTolerance;

    if (isPremium)  //BRAND_PRODUCT_WEIGHT_TIER_SYS-TYPE
      recipe.FieldName = Brand + " " + Product + " " + ProductWeight.toString() + "g " + Tier + " Min";
    else
      recipe.FieldName = ProductWeight.toString() + "g " + Product + " Min";
    recipe.AutoAdjustON = false;
    recipe.ProductT1SP = SPf;
    SPf = SPf + ProductTolerance;
    let SP = Math.round(SPf);
    recipe.WeightSP = SP;
    recipe.ProductTolerance = ProductTolerance;
    if (ProductWeight <= 400) {
      recipe.CountThreshold = Math.round(ProductWeight / 2);
      recipe.AdjStartWeigh = 1;
      recipe.AdjMax = 10;
      recipe.AdjMin = -2;
    } else {
      recipe.CountThreshold = Math.round(ProductWeight / 3);
      recipe.AdjStartWeigh = 2;
      recipe.AdjMax = 15;
      recipe.AdjMin = -4;
    }
  }
  recipe.ProductTargetSpeed = 10;
  recipe.CwBypass = 0;
  if (recipe.CwProgramNumber < 1) recipe.CwProgramNumber = 1;
  recipe.ProductCostPerKg = 5;
  recipe.ProductExtraCost = 0.1;
  recipe.AdjQtPercStart = 10;
  recipe.ProductTolPlus = recipe.ProductTolerance;
  recipe.ProductAllowOver = 0;

  return recipe
};
