import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  Spinner,
  Label,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import { calculateRecipeValues } from "utils/calculateRecipeValues";
import authHeader from "../../services/auth-header";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";
// import CustomSelectComponent from "components/CustomSelect/CustomSelect";

function AddNewPremiumRecipeModal({
  isOpen,
  toggle,
  modalKey,
  modalTitle,
  modalData,
  line,
  modalButtonText,
  fetchData,
  clientId,
  // products,
  // brands,
  // tiers,
  // weights,
}) {
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [weights, setWeights] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/admin/getProducts`, {
        headers: authHeader(),
      })
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${baseUrl}/api/admin/getBrands`, {
        headers: authHeader(),
      })
      .then((res) => {
        setBrands(res.data.brands);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${baseUrl}/api/admin/getTiers`, {
        headers: authHeader(),
      })
      .then((res) => {
        setTiers(res.data.tiers);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${baseUrl}/api/admin/getWeights`, {
        headers: authHeader(),
      })
      .then((res) => {
        setWeights(res.data.weights);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!modalData) return;
    let tmp = products.find(
      (product) => product.id == modalData.Product
    )?.product;
    if (!tmp) return;
    setRecipeData((st) => ({ ...st, Product: tmp }));
  }, [modalData, products]);

  useEffect(() => {
    if (!modalData) return;
    let tmp = brands.find((brand) => brand.id == modalData.Brand)?.brand;
    if (!tmp) return;
    setRecipeData((st) => ({ ...st, Brand: tmp }));
  }, [modalData, brands]);

  useEffect(() => {
    if (!modalData) return;
    let tmp = tiers.find((tier) => tier.id == modalData.Tier)?.tier;
    if (!tmp) return;
    setRecipeData((st) => ({ ...st, Tier: tmp }));
  }, [modalData, tiers]);

  useEffect(() => {
    if (!modalData) return;
    let tmp = weights.find((weight) => weight.id == modalData.Weight)?.weight;
    if (!tmp) return;
    setRecipeData((st) => ({ ...st, ProductWeight: tmp, Weight: tmp.id }));
  }, [modalData, weights]);

  const [recipeData, setRecipeData] = useState(
    modalData
      ? {
          ...modalData,
          Product: "",
          Brand: "",
          Tier: "",
          ProductWeight: "",
        }
      : {
          Product: products?.product || "",
          Tier: tiers?.tier || "",
          Brand: brands?.brand || "",
          ProductWeight: weights?.weight || "",
          Line: line,
          SystemType: "Average Weight",
          ProductWeight: "",
          Tare: "",
          Moisture: "",
          FieldName: "",
          Product_e: "",
          WeightSP: "",
          ProductTolerance: "",
          ProductT1SP: "",
          CountThreshold: "",
          ProductTargetSpeed: "",
          AutoAdjustON: "",
          AdjQtPercStart: "",
          AdjStartWeigh: "",
          AdjMax: "",
          AdjMin: "",
          // index: "",
          CwBypass: 0,
          CwProgramNumber: "1",
          ProductCostPerKg: "",
          ProductExtraCost: "",
          ProductAllowOver: 0,
          ProductTolPlus: "",
        }
  );
  const [weightError, setWeightError] = useState(false);
  const [tareError, setTareError] = useState(false);
  const [moistError, setMoistError] = useState(false);
  const [tolError, setTolError] = useState(false);
  const [countThresholdError, setCountThresholdError] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [generalError, setGeneralError] = useState(false);

  const onChange = (event) => {
    const integerValidationArray = [
      "ProductTargetSpeed",
      "ProductTolerance",
      "WeightSP",
      "CountThreshold",
      "AdjMax",
      "AdjMin",
      "AdjQtPercStart",
      "AdjStartWeigh",
      "CwProgramNumber",
      "Tare",
      "ProductWeight",
      "ProductTolPlus",
    ];
    const name = event.target.name;

    const value =
      name === "ProductAllowOver"
        ? event.target.checked
          ? 1
          : 0
        : event.target.value;

    if (integerValidationArray.includes(name)) {
      // const isValidInteger = /^\d*$/.test(value);
      const isValidInteger = /^-?\d*$/.test(value);
      if (!isValidInteger) {
        setGeneralError(true);
        return;
      }
    }
    setRecipeData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setGeneralError(false);
    if (name === "ProductWeight") {
      if (value && (value < 11 || value > 3999)) {
        setWeightError(true);
      } else {
        setWeightError(false);
      }
    }
    if (name === "Tare") {
      if (value && (value < 0 || value > 1999)) {
        setTareError(true);
      } else {
        setTareError(false);
      }
    }
    if (name === "Moisture") {
      if (value && (value < 0 || value > 19)) {
        setMoistError(true);
      } else {
        setMoistError(false);
      }
    }
    if (name === "ProductTolerance") {
      if (
        value &&
        recipeData.SystemType === "Minimum Weight" &&
        (value < 3 ||
          value > 999 ||
          parseInt(value) > parseInt(recipeData.ProductWeight) ||
          !recipeData.ProductWeight)
      ) {
        setTolError(true);
      } else {
        setTolError(false);
      }
    }
    if (
      name === "CountThreshold" &&
      recipeData.WeightSP &&
      recipeData.ProductTolPlus
    ) {
      if (
        value &&
        value >
          parseInt(recipeData.WeightSP) - parseInt(recipeData.ProductTolPlus)
      ) {
        setCountThresholdError(true);
      } else {
        setCountThresholdError(false);
      }
    }
    if (name === "SystemType" && value === "Minimum Weight") {
      setRecipeData({
        Product: "",
        Tier: "",
        Brand: "",
        Line: line,
        SystemType: value,
        ProductWeight: "",
        Tare: "",
        Moisture: "",
        FieldName: "",
        Product_e: "",
        WeightSP: "",
        ProductTolerance: "",
        ProductT1SP: "",
        CountThreshold: "",
        ProductTargetSpeed: "",
        AutoAdjustON: "OFF",
        AdjQtPercStart: "",
        AdjStartWeigh: "",
        AdjMax: "",
        AdjMin: "",
        // index: "",
        CwBypass: 0,
        CwProgramNumber: "1",
        ProductCostPerKg: "",
        ProductExtraCost: "",
        ProductAllowOver: 0,
        ProductTolPlus: "",
      });
    } else if (name === "SystemType" && value === "Average Weight") {
      setRecipeData({
        Product: "",
        Tier: "",
        Brand: "",
        Line: line,
        SystemType: value,
        ProductWeight: "",
        Tare: "",
        Moisture: "",
        FieldName: "",
        Product_e: "",
        WeightSP: "",
        ProductTolerance: "",
        ProductT1SP: "",
        CountThreshold: "",
        ProductTargetSpeed: "",
        AutoAdjustON: "ON",
        AdjQtPercStart: "",
        AdjStartWeigh: "",
        AdjMax: "",
        AdjMin: "",
        // index: "",
        CwBypass: 0,
        CwProgramNumber: "1",
        ProductCostPerKg: "",
        ProductExtraCost: "",
        ProductAllowOver: 0,
        ProductTolPlus: "",
      });
    }
  };

  const onClickCalculate = (e) => {
    console.log("Calculate: form");
    e.preventDefault();
    if (
      recipeData.ProductWeight &&
      recipeData.Tare.toString() &&
      recipeData.Moisture.toString() &&
      recipeData.Product &&
      recipeData.Brand &&
      recipeData.Tier &&
      !weightError &&
      !tareError &&
      !moistError &&
      !countThresholdError
    ) {
      let calculatedRecipeData;
      if (recipeData.SystemType === "Average Weight") {
        calculatedRecipeData = calculateRecipeValues(
          {
            Brand: recipeData.Brand,
            Tier: recipeData.Tier,
            SystemType: recipeData.SystemType,
            ProductWeight: recipeData.ProductWeight,
            Tare: recipeData.Tare,
            Moisture: recipeData.Moisture,
            Product: recipeData.Product,
          },
          true
        );
      } else if (recipeData.SystemType === "Minimum Weight") {
        if (recipeData.ProductTolerance && !tolError) {
          calculatedRecipeData = calculateRecipeValues(
            {
              Brand: recipeData.Brand,
              Tier: recipeData.Tier,
              SystemType: recipeData.SystemType,
              ProductWeight: recipeData.ProductWeight,
              Tare: recipeData.Tare,
              Moisture: recipeData.Moisture,
              ProductTolerance: recipeData.ProductTolerance,
              Product: recipeData.Product,
            },
            true
          );
        } else {
          alert("Please check tolerance value!");
        }
      }
      setRecipeData((prevState) => ({
        ...prevState,
        ...calculatedRecipeData,
      }));
    } else {
      alert("Please check values again!");
    }
  };

  const closeModal = () => {
    toggle();
  };

  const addNewRecipe = (e) => {
    e.preventDefault();
    console.log("Add new: ");
    console.log(recipeData);
    const body = { ...recipeData };
    body.Product = products.find(
      (product) => product.product == recipeData.Product
    )?.id;
    body.Brand = brands.find((brand) => brand.brand == recipeData.Brand)?.id;
    body.Tier = tiers.find((tier) => tier.tier == recipeData.Tier)?.id;

    body.Weight = weights.find(
      (weight) => weight.weight == recipeData.ProductWeight
    )?.id;
    axios
      .post(`${baseUrl}/api/admin/recipe/${clientId}`, body, {
        headers: authHeader(),
      })
      .then((res) => {
        fetchData();
        setIsAdding(false);
        closeModal();
        toast.success("Recipe added successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message || err.message || 'Something went wrong');
        setIsAdding(false);
      });
  };

  const updateRecipe = (e) => {
    e.preventDefault();
    console.log("Update new: ");
    console.log(recipeData);
    const body = { ...recipeData };
    body.Product = products.find(
      (product) => product.product == recipeData.Product
    )?.id;
    body.Brand = brands.find((brand) => brand.brand == recipeData.Brand)?.id;
    body.Tier = tiers.find((tier) => tier.tier == recipeData.Tier)?.id;
    console.log("recipeData.ProductWeight", recipeData.ProductWeight);
    body.Weight = weights.find(
      (weight) => weight.weight == recipeData.ProductWeight
    )?.id;
    axios
      .put(`${baseUrl}/api/admin/recipe/update/${clientId}`, body, {
        headers: authHeader(),
      })
      .then((res) => {
        fetchData();
        setIsAdding(false);
        closeModal();
        toast.success("Recipe updated successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message || err.message || 'Something went wrong');
        setIsAdding(false);
      });
  };

  return (
    <Modal
      centered
      scrollable
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-bg"
      backdropClassName="modal-backdrop-opacity"
    >
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <div className="text-white rounded-circle">
              <i className="fas fa-times" />
            </div>
          </button>
        }
      >
        <h2 style={{ color: "#a7ce39" }}>{modalTitle}</h2>
      </ModalHeader>
      <ModalBody>
        <Form id="calculate-form" onSubmit={onClickCalculate}></Form>

        <Form
          id="addNewRecipeForm"
          onSubmit={
            modalButtonText.includes("Update") ? updateRecipe : addNewRecipe
          }
        >
          <div className="pl-lg-4">
            <Row className="justify-content-center align-item-center mx-auto mb-3 text-danger font-weight-bolder">
              {generalError
                ? "Decimal values not allowed for this field. Please provide values in integer."
                : ""}
            </Row>
            <Row>
              <Col xs={12} lg={3}>
                <FormGroup className="mb-3 w-75">
                  <Tooltip
                    title="What type of product you are packing i.e. strawberries, blueberries"
                    arrow
                  >
                    <label className="form-control-label textWhite d-block">
                      Product
                    </label>
                  </Tooltip>
                  <Input
                    form="calculate-form"
                    placeholder="Product"
                    name="Product"
                    type="select"
                    //size="sm"
                    className="textBg"
                    value={recipeData.Product}
                    onChange={onChange}
                    required
                  >
                    <option key={13123} value={""}>
                      {""}
                    </option>
                    {products.map((option, idx) => {
                      return (
                        <option key={idx} value={option.product}>
                          {option.product}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Are you packing to minimum or average weight">
                    <label className="form-control-label textWhite d-block">
                      System Type
                    </label>
                  </Tooltip>
                  <Input
                    form="calculate-form"
                    placeholder="System Type"
                    name="SystemType"
                    type="select"
                    //size="sm"
                    className="textBg"
                    value={recipeData.SystemType}
                    onChange={onChange}
                    required
                  >
                    {["Average Weight", "Minimum Weight"].map((option, idx) => {
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Brand" arrow>
                    <label className="form-control-label textWhite d-block">
                      Brand
                    </label>
                  </Tooltip>
                  <Input
                    form="calculate-form"
                    placeholder="Brand"
                    name="Brand"
                    type="select"
                    //size="sm"
                    className="textBg"
                    value={recipeData.Brand}
                    onChange={onChange}
                    required
                  >
                    <option key={13123} value={""}>
                      {""}
                    </option>
                    {brands.map((option, idx) => {
                      return (
                        <option key={idx} value={option.brand}>
                          {option.brand}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Tier" arrow>
                    <label className="form-control-label textWhite d-block">
                      Tier
                    </label>
                  </Tooltip>
                  <Input
                    form="calculate-form"
                    placeholder="Tier"
                    name="Tier"
                    type="select"
                    //size="sm"
                    className="textBg"
                    value={recipeData.Tier}
                    onChange={onChange}
                    required
                  >
                    <option key={13123} value={""}>
                      {""}
                    </option>
                    {tiers.map((option, idx) => {
                      return (
                        <option key={idx} value={option.tier}>
                          {option.tier}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Indicated weight on the packaging" arrow>
                    <label className="form-control-label textWhite d-block">
                      Weight
                    </label>
                  </Tooltip>
                  <Input
                    form="calculate-form"
                    placeholder="Weight (g)"
                    name="ProductWeight"
                    type="select"
                    //size="sm"
                    className="textBg"
                    value={recipeData.ProductWeight}
                    onChange={onChange}
                    required
                    invalid={weightError}
                  >
                    <option key={13123} value={""}>
                      {""}
                    </option>
                    {weights.map((option, idx) => {
                      return (
                        <option key={idx} value={option.weight}>
                          {option.weight}
                        </option>
                      );
                    })}
                  </Input>
                  <FormFeedback>
                    Weight should be greater than 10 and less than 4000
                  </FormFeedback>
                </FormGroup>
                {/* <FormGroup className="mb-3 w-75">
                  <Tooltip title="Indicated weight on the packaging">
                    <label className="form-control-label textWhite d-block">
                      Weight (g)
                    </label>
                  </Tooltip>
                  <Input
                    form="calculate-form"
                    name="ProductWeight"
                    placeholder="Weight (g)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.ProductWeight}
                    onChange={onChange}
                    required
                    invalid={weightError}
                  />
                  <FormFeedback>
                    Weight should be greater than 10 and less than 4000
                  </FormFeedback>
                </FormGroup> */}
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Add a tare for packaging. note only use this when not taring the packaging manually on the scales">
                    <label className="form-control-label textWhite d-block">
                      Tare (g)
                    </label>
                  </Tooltip>
                  <Input
                    form="calculate-form"
                    name="Tare"
                    placeholder="Tare (g)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.Tare}
                    onChange={onChange}
                    required
                    invalid={tareError}
                  />
                  <FormFeedback>
                    Tare should be greater than or equal to 0 and less than 2000
                  </FormFeedback>
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Stipulated moisture loss that must be added when creating the setpoint for the scales">
                    <label className="form-control-label textWhite d-block">
                      Moisture (%)
                    </label>
                  </Tooltip>
                  <Input
                    form="calculate-form"
                    name="Moisture"
                    placeholder="Moisture (%)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.Moisture}
                    onChange={onChange}
                    required
                    invalid={moistError}
                  />
                  <FormFeedback>
                    Moist should be greater than or equal to 0 and less than or
                    equal to 20
                  </FormFeedback>
                </FormGroup>
                {/* </Col>
                <Col
                  xs={12}
                  lg={2}
                  className="justify-content-end align-items-center d-flex pt-4"
                  // className="pl-4 pt-3"
                > */}
                <Button
                  className="w-75"
                  style={{
                    backgroundColor: "#a7ce39",
                    border: "none",
                    marginTop: "30px",
                  }}
                  type="submit"
                  form="calculate-form"
                >
                  Calculate
                </Button>
              </Col>
              <Col xs={12} lg={3}>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="This will auto generate but then can be manually edited this is used as the recipe name and appears on the miweigh hmi and the reports">
                    <label className="form-control-label textWhite d-block">
                      Name
                    </label>
                  </Tooltip>
                  <Input
                    placeholder="Name"
                    name="FieldName"
                    type="text"
                    className="textBg"
                    //size="sm"
                    value={recipeData.FieldName}
                    onChange={onChange}
                    required
                  />
                </FormGroup>

                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Scale set point this is auto calculated but can be manually overridden">
                    <label className="form-control-label textWhite d-block">
                      SP (g)
                    </label>
                  </Tooltip>
                  <Input
                    name="WeightSP"
                    placeholder="SP (g)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.WeightSP}
                    onChange={onChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Positive tolerance this is auto calculated but can be manually overridden">
                    <label className="form-control-label textWhite d-block">
                      Tol. + (g){" "}
                    </label>
                  </Tooltip>
                  <Input
                    name="ProductTolPlus"
                    placeholder="Tol. + (g)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.ProductTolPlus}
                    onChange={onChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Negative tolerance this is auto calculated but can be manually overridden">
                    <label className="form-control-label textWhite d-block">
                      Tol. - (g){" "}
                      {recipeData.SystemType === "Minimum Weight" ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </Tooltip>
                  <Input
                    name="ProductTolerance"
                    placeholder="Tol. - (g)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.ProductTolerance}
                    onChange={onChange}
                    required
                    invalid={tolError}
                  />
                  <FormFeedback>
                    Tolerance should be greater than 2 and less than 1000. Also
                    it should be less than Weight.
                  </FormFeedback>
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="T1 value based on stipulation from weights and measures legislation. this is auto calculated but can be manually overridden">
                    <label className="form-control-label textWhite d-block">
                      -T1 (g)
                    </label>
                  </Tooltip>
                  <Input
                    name="ProductT1SP"
                    placeholder="-T1 (g)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.ProductT1SP}
                    onChange={onChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Used for the operator kpi. example 10 would mean 10 packs a minute per scale. 8 scale system would give target of 80ppm through the line">
                    <label className="form-control-label textWhite d-block">
                      Target Speed (p/scale)
                    </label>
                  </Tooltip>
                  <Input
                    name="ProductTargetSpeed"
                    placeholder="Target Speed (p/scale)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.ProductTargetSpeed}
                    onChange={onChange}
                    required
                  />
                </FormGroup>
              </Col>
              {/* </Row>
              <Row> */}
              <Col xs={12} lg={3}>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="If on the algorithm will work by adjusting the scales automatically to maintain the average weight through the bactch whilst ensuring the average weight does not fall below the set point">
                    <label className="form-control-label textWhite d-block">
                      Auto Adjust
                    </label>
                  </Tooltip>
                  <Input
                    placeholder="Auto Adjust"
                    name="AutoAdjustON"
                    type="select"
                    //size="sm"
                    className="textBg"
                    value={recipeData.AutoAdjustON}
                    onChange={onChange}
                    required
                  >
                    {["ON", "OFF"].map((option, idx) => {
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="This is how long at the beginning of the bactch do you want to run the adj. start value">
                    <label className="form-control-label textWhite d-block">
                      Adj. Start (%)
                    </label>
                  </Tooltip>
                  <Input
                    name="AdjQtPercStart"
                    placeholder="Adj. Start (%)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.AdjQtPercStart}
                    onChange={onChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="This is the weight you can set to ensure the beginning of the run will be above the set point, to prevent the initial packs being rejected by the checkweigher for being below the average weight">
                    <label className="form-control-label textWhite d-block">
                      Adj. Start (g)
                    </label>
                  </Tooltip>
                  <Input
                    name="AdjStartWeigh"
                    placeholder="Adj. Start (g)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.AdjStartWeigh}
                    onChange={onChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="This is the setting to set the maximum the system will automatically adjust the scale to above the set point to maintain the average weight">
                    <label className="form-control-label textWhite d-block">
                      Adj. Max (g)
                    </label>
                  </Tooltip>
                  <Input
                    name="AdjMax"
                    placeholder="Adj. Max (g)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.AdjMax}
                    onChange={onChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="This is the setting to set the minimum the system will automatically adjust the scale to below the set point to maintain the average weight">
                    <label className="form-control-label textWhite d-block">
                      Adj. Min (g)
                    </label>
                  </Tooltip>
                  <Input
                    name="AdjMin"
                    placeholder="Adj. Min (g)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.AdjMin}
                    onChange={onChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="If you want the system to allow overweights example prepunneted jobs">
                    <label className="form-control-label textWhite d-block">
                      Product Allow Over
                    </label>
                  </Tooltip>
                  <Input
                    name="ProductAllowOver"
                    placeholder="Product Allow Over"
                    type="checkbox"
                    className="textBg ml-3"
                    style={{ width: "20px", height: "20px" }}
                    //size="sm"
                    value={recipeData.ProductAllowOver == 1 ? true : false}
                    defaultChecked={
                      recipeData.ProductAllowOver == 1 ? true : false
                    }
                    onChange={onChange}
                    // required
                  />
                </FormGroup>
              </Col>
              <Col xs={12} lg={3}>
                {/* <FormGroup className="mb-3 w-75">
                    <label className="form-control-label textWhite d-block">
                      Index
                    </label>
                    <Input
                      name="index"
                      placeholder="Index"
                      type="number"
                      className="textBg"
                      //size="sm"
                      value={recipeData.index}
                      onChange={onChange}
                      required
                    />
                  </FormGroup> */}
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="The value at which the scale will count the pack when removing. the high this value the faster the scale can operate but it can lessen the accuracy">
                    <label className="form-control-label textWhite d-block">
                      Count Threshold (g)
                    </label>
                  </Tooltip>
                  <Input
                    placeholder="Count Threshold (g)"
                    name="CountThreshold"
                    type="number"
                    ////size="sm"
                    className="textBg"
                    value={recipeData.CountThreshold}
                    onChange={onChange}
                    required
                    invalid={countThresholdError}
                  />
                  <FormFeedback>
                    Value can not be higher than SP - Tol+.
                  </FormFeedback>
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Ignores checkweigher communication errors if checkweigher not present">
                    <label className="form-control-label textWhite d-block">
                      Checkweigher Bypass
                    </label>
                  </Tooltip>
                  <Input
                    placeholder="Checkweigher Bypass"
                    name="CwBypass"
                    type="select"
                    //size="sm"
                    className="textBg"
                    value={recipeData.CwBypass}
                    onChange={onChange}
                    required
                  >
                    {[
                      { label: "ON", value: 1 },
                      { label: "OFF", value: 0 },
                    ].map((option, idx) => {
                      return (
                        <option key={idx} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="The program number in the yamato. this is used to auto set the program number in the checkweigher">
                    <label className="form-control-label textWhite d-block">
                      Checkweigher Program
                    </label>
                  </Tooltip>
                  <Input
                    name="CwProgramNumber"
                    placeholder="Checkweigher Program"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.CwProgramNumber}
                    onChange={onChange}
                    required
                    min={1}
                  />
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Price per kilo of the product being packed this is used for the pack costings on the report">
                    <label className="form-control-label textWhite d-block">
                      Product Cost (£/kg)
                    </label>
                  </Tooltip>
                  <Input
                    name="ProductCostPerKg"
                    placeholder="Product Cost (£/kg)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.ProductCostPerKg}
                    onChange={onChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-3 w-75">
                  <Tooltip title="Price of any packaging or materials you want to include in the pack costings">
                    <label className="form-control-label textWhite d-block">
                      Extras Cost (£/pack)
                    </label>
                  </Tooltip>
                  <Input
                    name="ProductExtraCost"
                    placeholder="Extras Cost (£/pack)"
                    type="number"
                    className="textBg"
                    //size="sm"
                    value={recipeData.ProductExtraCost}
                    onChange={onChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          style={{ backgroundColor: "#a7ce39", border: "none" }}
          // onClick={
          //   modalButtonText.includes("Update") ? updateRecipe : addNewRecipe
          // }
          type="submit"
          form="addNewRecipeForm"
        >
          {isAdding ? (
            <>
              Loading...
              <Spinner className="ml-2" size="sm" />
            </>
          ) : (
            modalButtonText
          )}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default AddNewPremiumRecipeModal;
