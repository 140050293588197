import React, { useState, useEffect } from 'react';
import { CardBody, Form, Card, Row, Col, Button } from 'reactstrap';
import DateFilter from 'views/examples/DateFilter';


function Filters({ handleApplyFilters, filtersData }) {
  const [filterState, setFilterState] = useState({
    ...filtersData
  });

  useEffect(() => {
    setFilterState({ ...filtersData });
  }, [filtersData]);

  const handleDateRange = (start_date, end_date) => {
    setFilterState(prevState => {
      return {
        ...prevState,
        start_date,
        end_date
      };
    });
  };

  const handleApplyFromDateFilter = () => {
    handleApplyFilters(filterState);
  };

  return (
    <Card>
      <CardBody className='rounded px-5 py-4'>
        <Form>
          <div className=''>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
                xxl={2}
                className='mb-3'
              >
                <DateFilter
                  filtersData={filtersData}
                  handleDateRange={handleDateRange}
                  handleApplyFromDateFilter={handleApplyFromDateFilter}
                />
              </Col>

              <Col
                className='justify-content-end align-items-center d-flex pt-4 mb-3'
                xs={12}
                sm={12}
                md={6}
                lg={6} // 4
                xl={8} // 4
                xxl={10} // 2
              >
                <Button
                  style={{ backgroundColor: '#a7ce39', border: 'none' }}
                  onClick={() => handleApplyFilters(filterState)}
                >
                  Apply
                </Button>
                <Button
                  className=''
                  color='secondary'
                  onClick={() => {
                    const today = new Date();
                    const oneMonthAgo = new Date();
                    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
                    oneMonthAgo.setDate(oneMonthAgo.getDate() - 1);

                    const yesterday = new Date(today);

                    yesterday.setDate(yesterday.getDate() - 7);
                    setFilterState({
                      start_date: oneMonthAgo.toISOString(),
                      end_date: today.toISOString()
                    });
                    handleApplyFilters({
                      start_date: oneMonthAgo.toISOString(),
                      end_date: today.toISOString()
                    });
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}

export default Filters;
