import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Button,
  Input,
  // Label,
  // Nav,
  // NavItem,
  FormGroup,
  Tooltip,
} from "reactstrap";
import Table from "views/shared/Table";
import { Grid, TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import authHeader from "../../services/auth-header";
// import AddNewModal from "views/shared/AddNewModal";
import ConfirmationModal from "views/shared/ConfirmationModal";
import AuthService from "../../services/auth.service";
import AddNewRecipeModal from "views/shared/AddNewRecipeModal";
import { toast } from "react-toastify";
import AddNewPremiumItemsModal from "views/shared/AddNewPremiumItemsModal";
import AddNewPremiumRecipeModal from "views/shared/AddNewPremiumRecipeModal";
import { DataContext } from "layouts/Admin";
import { useAuth } from "contexts/AuthContext";

function RecipesTable({ filtersData, clientId, clientSubscription }) {
  const { user } = useAuth();

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [premiumCount, setTableCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false);
  const [isOpenItemsModal, setIsOpenItems] = useState(false);
  const [infoModalData, setInfoModalData] = useState({
    key: null,
    title: null,
    modalData: null,
    modalButtonText: null,
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationModalData, setConfirmationModalData] = useState({
    title: "",
    body: "",
    confirmText: "",
  });
  const [deleteUserData, setDeleteUserData] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectRecipes, setSelectRecipes] = useState([]);
  const [isExportingRecipes, setIsExportingRecipes] = useState(false);
  const [recipeExportLine, setRecipeExportLine] = useState("");
  // const [lineData, setLineData] = useState("Loading...");
  const { lineData } = useContext(DataContext);

  const [premiumData, setPremiumData] = useState("Loading...");
  const [tableColumns, setTableColumns] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  const baseColumns = [
    {
      id: "select",
      title: (
        <FormGroup className="ml-3 mb-4" check>
          <Input
            type="checkbox"
            checked={selectRecipes === "all" ? true : false}
            onChange={(e) => handleSelectRecipe(e.target.checked, "all")}
            style={{
              transform: "scale(1.5)",
            }}
          />
          {/* <Label className="ml-1" check>
            All
          </Label> */}
        </FormGroup>
      ),
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: (record) => renderHtml("select", record),
      // onHeadingClick: () => console.log(""),
    },
    {
      id: "action",
      title: "ACTION",
      className: "sort table-data-font",
      onClick: null,
      render: (record) => renderHtml("action", record),
    },
    {
      id: "FieldName",
      title: "Recipe Name",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    // {
    //   id: "Line",
    //   title: "Line",
    //   className: "sort custom-padding table-data-font",
    //   onClick: null,
    //   render: null,
    // },
  ];
  const remainingColumns = [
    {
      id: "SystemType",
      title: "System Type",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "ProductWeight",
      title: "Weight",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Moisture",
      title: "Moisture",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "WeightSP",
      title: "Weight SP",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
  ];
  const premiumColumns = [
    {
      id: "Rcp_Product",
      title: "Product",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Rcp_Brand",
      title: "Brand",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Rcp_Tier",
      title: "Tier",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
  ];

  const showMoreColumns = [
    {
      id: "Product_e",
      title: "Product e",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "ProductCostPerKg",
      title: "Product Cost/KG",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "ProductExtraCost",
      title: "Product Extra Cost",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "ProductT1SP",
      title: "Product T1SP",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "ProductTargetSpeed",
      title: "Product Target Speed",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "ProductTolerance",
      title: "Product Tolerance",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "CountThreshold",
      title: "Count Threshold",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "AdjMax",
      title: "Adj Max",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "AdjMin",
      title: "Adj Min",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "AdjQtPercStart",
      title: "Adj QtPerc Start",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "AutoAdjustON",
      title: "Auto Adjust ON",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "AdjStartWeigh",
      title: "Adj Start Weigh",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "CwProgramNumber",
      title: "Cw Program No",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "CwBypass",
      title: "Cw Bypass",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Tare",
      title: "Tare",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "ProductTolPlus",
      title: "Product Tol Plus",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "ProductAllowOver",
      title: "Product Allow Over",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
  ];

  useEffect(() => {
    if (showMore) {
      if (clientSubscription === "premium") {
        setTableColumns([
          ...baseColumns,
          ...premiumColumns,
          ...remainingColumns,
          ...showMoreColumns,
        ]);
      } else
        setTableColumns([
          ...baseColumns,
          ...remainingColumns,
          ...showMoreColumns,
        ]);
    } else {
      if (clientSubscription === "premium") {
        setTableColumns([
          ...baseColumns,
          ...premiumColumns,
          ...remainingColumns,
        ]);
      } else setTableColumns([...baseColumns, ...remainingColumns]);
    }
  }, [clientSubscription, selectRecipes, showMore]);

  useEffect(() => {
    if (typeof lineData === "string") return;
    if (lineData.length > 0) setRecipeExportLine(lineData[0]);
  }, [lineData]);

  // useEffect(() => {
  //   axios
  //     .get(`${baseUrl}/api/get_lookup_data/line/${clientId}`, {
  //       headers: authHeader(),
  //     })
  //     .then((res) => {
  //       setLineData(res.data.data);
  //       setRecipeExportLine(res.data.data[0])
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [clientId]);

  useEffect(() => {
    setTableData("Loading...");
    fetchData();
    // }, [filtersData, page, rowsPerPage]);
  }, [filtersData]);

  const fetchData = () => {
    axios
      .get(`${baseUrl}/api/admin/recipe/${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        setTableData(res.data.recipes);
        setCount(res.data.recipes.length);
      })
      .catch((err) => {
        console.log(err);
        setTableData([]);
      });
  };

  const fetchPremiumData = (url, bodyKey) => {
    axios
      .get(`${url}`, {
        headers: authHeader(),
      })
      .then((res) => {
        setPremiumData(res.data[bodyKey]);
        setTableCount(res.data[bodyKey].length);
      })
      .catch((err) => {
        console.log(err);
        setPremiumData([]);
      });
  };

  const onChange = (event) => {
    console.log(event.target.value);
    setRecipeExportLine(event.target.value);
  };

  const handleSelectRecipe = (check, recipe) => {
    console.log("Check: ", check);
    console.log("Recipe: ", recipe);
    if (check) {
      if (recipe === "all") {
        setSelectRecipes("all");
        return;
      }
      const recipeExist =
        selectRecipes.length > 0 &&
        selectRecipes.find((r) => r.id === recipe.id);
      if (recipeExist) {
        return;
      } else {
        setSelectRecipes((prevList) => {
          return [...prevList, recipe];
        });
      }
    } else {
      if (recipe === "all") {
        setSelectRecipes([]);
        return;
      } else if (recipe !== "all" && selectRecipes === "all") {
        setSelectRecipes([]);
        return;
      }
      const recipeExist =
        selectRecipes.length > 0 &&
        selectRecipes.find((r) => r.id === recipe.id);
      if (!recipeExist) {
        return;
      } else {
        let list = [...selectRecipes];
        const idx = list.findIndex((i) => i.id === recipe.id);
        list.splice(idx, 1);
        console.log(list);
        setSelectRecipes(list);
      }
    }
  };

  const renderHtml = (key, record) => {
    switch (key) {
      case "action":
        return (
          <>
            <i
              className="text-danger btn fa fa-trash px-1 py-0 m-0"
              onClick={() => {
                setConfirmationModalData({
                  title: `Delete recipe ${record.FieldName}`,
                  body: "Are you sure you want to delete this recipe?",
                  confirmText: "Confirm",
                });
                setDeleteUserData(record);
                setOpenConfirmationModal(true);
              }}
            />
            <i
              className="text-info btn fa fa-edit px-1 py-0 m-0"
              onClick={() => {
                // if (user.subscriptionLabel === 'premium')
                if (clientSubscription === "premium")
                  setIsPremiumModalOpen(true);
                else setOpenModal(true);
                setInfoModalData({
                  key: "recipe",
                  title: "Update Recipe",
                  modalData: record,
                  modalButtonText: "Update",
                });
              }}
            />
          </>
        );

      case "select":
        return (
          <>
            <FormGroup className="mb-4 ml-3" check>
              <Input
                type="checkbox"
                checked={
                  selectRecipes === "all"
                    ? true
                    : selectRecipes.findIndex((r) => r.id === record.id) === -1
                    ? false
                    : true
                }
                onChange={(e) => handleSelectRecipe(e.target.checked, record)}
                style={{
                  transform: "scale(1.5)",
                }}
              />
            </FormGroup>
          </>
        );

      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = () => {
    setIsDeleting(true);
    axios
      .delete(`${baseUrl}/api/admin/recipe/${deleteUserData.id}`, {
        headers: authHeader(),
      })
      .then(() => {
        const selectedRecipesArray = [...selectRecipes];

        const recipeExist =
          selectedRecipesArray.length > 0 &&
          selectedRecipesArray.findIndex((r) => r.id === deleteUserData.id);
        if (recipeExist > -1) {
          selectedRecipesArray.splice(recipeExist, 1);
          setSelectRecipes(selectedRecipesArray);
        }
        fetchData();
        setIsDeleting(false);
        toast.success("Recipe Deleted!");
        setOpenConfirmationModal((prevState) => !prevState);
      })
      .catch((err) => {
        console.log(err);
        setIsDeleting(false);
      });
  };

  const handleUpload = () => {
    if (!recipeExportLine) {
      alert("Please select line from the dropdown!");
      return;
    }
    if (selectRecipes.length > 0) {
      setIsExportingRecipes(true);
      axios
        .post(
          `${baseUrl}/api/admin/uploadRecipes/${clientId}`,
          { recipesList: selectRecipes, recipeExportLine },
          {
            headers: authHeader(),
          }
        )
        .then(() => {
          setIsExportingRecipes(false);
          // alert("Data Exported!");
          toast.success("Data Exported!");
        })
        .catch((err) => {
          setIsExportingRecipes(false);
          toast.error(`Error: ${err.response.data.message}`);
        });
    } else {
      alert("Please select recipes first!");
    }
  };

  const handleBulkDelete = () => {
    const recipeIds =
      selectRecipes === "all"
        ? tableData.map((item) => item.id)
        : selectRecipes.map((item) => item.id);

    axios
      .post(
        `${baseUrl}/api/admin/recipe/bulk-delete`,
        { recipeIds: recipeIds },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        fetchData();
        setSelectRecipes([]);
        toast.success("Recipe Deleted!");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Container fluid>
        <Row className="mt-4">
          <Col>
            <Card className="shadow">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <h2 className="px-4 pt-4 text-color-mw">
                    Recipes Management
                  </h2>
                </Grid>
                <Grid item>
                  {/* {user.role !== "user" && ( */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="mr-4 pt-3"
                    spacing="2"
                  >
                    <Grid item>
                      {lineData === "Loading..." ||
                      lineData === "No Data Found" ? (
                        lineData
                      ) : (
                        <Input
                          placeholder="Line"
                          name="recipeExportLine"
                          type="select"
                          value={recipeExportLine}
                          className="textBg"
                          bsSize="sm"
                          style={{ color: "#000000" }}
                          onChange={onChange}
                        >
                          {/* <option value={""}>Select Line</option> */}
                          {lineData
                            // .slice(
                            //   page * rowsPerPage,
                            //   page * rowsPerPage + rowsPerPage
                            // )
                            .map((option, idx) => {
                              // {["Line 1"].map((option, idx) => {
                              return (
                                <option key={idx} value={option}>
                                  {option}
                                </option>
                              );
                            })}
                        </Input>
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        style={{
                          backgroundColor: "#a7ce39",
                          border: "none",
                          height: "33px",
                        }}
                        className="px-4"
                        size="sm"
                        onClick={() => handleUpload()}
                      >
                        {isExportingRecipes ? (
                          <>
                            Exporting...
                            <Spinner size="sm" className="ml-2" />
                          </>
                        ) : (
                          <>
                            Export
                            <i className="fa fa-upload ml-2" />
                          </>
                        )}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        style={{
                          backgroundColor: "#a7ce39",
                          border: "none",
                          height: "33px",
                        }}
                        className="px-4"
                        size="sm"
                        onClick={() => {
                          // if (user.subscriptionLabel === 'premium')
                          if (clientSubscription === "premium")
                            setIsPremiumModalOpen(true);
                          else setOpenModal(true);

                          setInfoModalData({
                            key: "recipe",
                            title: "Add Recipe",
                            modalData: null,
                            modalButtonText: "Add",
                          });
                        }}
                      >
                        Add Recipe
                        <i className="fa fa-plus ml-2" />
                      </Button>
                    </Grid>
                    {
                      // TODO - Check if user is admin then allow to add products, brands, tiers, weights
                      // user.subscriptionLabel === 'premium' && (
                      clientSubscription === "premium" && (
                        <>
                          <Grid item>
                            <Button
                              style={{
                                // backgroundColor: "#a7ce39",
                                // backgroundColor: "#5e71e4",
                                border: "none",
                                height: "33px",
                              }}
                              className="px-4"
                              size="sm"
                              onClick={() => {
                                setIsOpenItems(true);
                                setInfoModalData({
                                  key: "product",
                                  title: "Add Products",
                                  modalData: null,
                                  modalButtonText: "Add",
                                });
                                fetchPremiumData(
                                  `${baseUrl}/api/admin/getProducts`,
                                  "products"
                                );
                              }}
                            >
                              Products
                              {/* <i className="fa fa-plus ml-2" /> */}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              style={{
                                // backgroundColor: "#a7ce39",
                                // backgroundColor: "#5e71e4",
                                border: "none",
                                height: "33px",
                              }}
                              className="px-4"
                              size="sm"
                              onClick={() => {
                                setIsOpenItems(true);
                                setInfoModalData({
                                  key: "brand",
                                  title: "Add Brands",
                                  modalData: null,
                                  modalButtonText: "Add",
                                });
                                fetchPremiumData(
                                  `${baseUrl}/api/admin/getBrands`,
                                  "brands"
                                );
                              }}
                            >
                              Brands
                              {/* <i className="fa fa-plus ml-2" /> */}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              style={{
                                // backgroundColor: "#a7ce39",
                                // backgroundColor: "#5e71e4",
                                border: "none",
                                height: "33px",
                              }}
                              className="px-4"
                              size="sm"
                              onClick={() => {
                                setIsOpenItems(true);
                                setInfoModalData({
                                  key: "tier",
                                  title: "Add Tiers",
                                  modalData: null,
                                  modalButtonText: "Add",
                                });
                                fetchPremiumData(
                                  `${baseUrl}/api/admin/getTiers`,
                                  "tiers"
                                );
                              }}
                            >
                              Tier
                              {/* <i className="fa fa-plus ml-2" /> */}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              style={{
                                // backgroundColor: "#a7ce39",
                                // backgroundColor: "#5e71e4",
                                border: "none",
                                height: "33px",
                              }}
                              className="px-4"
                              size="sm"
                              onClick={() => {
                                setIsOpenItems(true);
                                setInfoModalData({
                                  key: "weight",
                                  title: "Add Weights",
                                  modalData: null,
                                  modalButtonText: "Add",
                                });
                                fetchPremiumData(
                                  `${baseUrl}/api/admin/getWeights`,
                                  "weights"
                                );
                              }}
                            >
                              Weight
                              {/* <i className="fa fa-plus ml-2" /> */}
                            </Button>
                          </Grid>
                        </>
                      )
                    }
                    {selectRecipes.length > 0 && (
                      <Grid item>
                        <Button
                          style={{
                            // backgroundColor: "#a7ce39",
                            border: "none",
                            height: "33px",
                          }}
                          // className="px-4"
                          size="sm"
                          onClick={handleBulkDelete}
                        >
                          <i className="fa fa-trash" />
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        style={{
                          // backgroundColor: "#a7ce39",
                          border: "none",
                          height: "33px",
                        }}
                        // className="px-4"
                        size="sm"
                        onClick={() => {
                          setShowMore((ps) => !ps);
                        }}
                      >
                        <i id="show-hide" className="fa fa-table" />
                        <Tooltip
                          placement={"top"}
                          isOpen={tooltip}
                          target={"show-hide"}
                          toggle={() => {
                            setTooltip((st) => !st);
                          }}
                        >
                          Show More/Hide Data
                        </Tooltip>
                      </Button>
                    </Grid>
                    {/* )} */}
                  </Grid>
                </Grid>
              </Grid>
              {tableData === "Loading..." ? (
                <div className="row justify-content-center my-5">
                  <Spinner>Loading...</Spinner>
                </div>
              ) : tableData ? (
                <>
                  <Table
                    values={tableData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )}
                    columns={tableColumns}
                    tableClasses={{
                      table:
                        "table align-items-center table-flush table-sm border-bottom text-white table-custom",
                      thead: "",
                      tbody: "list table-td-color",
                    }}
                  />
                  <TablePagination
                    component="div"
                    className="text-muted"
                    count={count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />{" "}
                </>
              ) : (
                "Not Available"
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      {openModal && (
        <AddNewRecipeModal
          isOpen={openModal}
          toggle={() => setOpenModal((prevState) => !prevState)}
          modalKey={infoModalData.key}
          modalTitle={infoModalData.title}
          modalData={infoModalData.modalData}
          modalButtonText={infoModalData.modalButtonText}
          fetchData={fetchData}
          clientId={clientId}
          line={recipeExportLine}
        />
      )}
      {isPremiumModalOpen && (
        <AddNewPremiumRecipeModal
          isOpen={isPremiumModalOpen}
          toggle={() => setIsPremiumModalOpen((prevState) => !prevState)}
          modalKey={infoModalData.key}
          modalTitle={infoModalData.title}
          modalData={infoModalData.modalData}
          modalButtonText={infoModalData.modalButtonText}
          fetchData={fetchData}
          clientId={clientId}
          line={recipeExportLine}
        />
      )}
      {isOpenItemsModal && (
        <AddNewPremiumItemsModal
          user={user}
          isOpen={isOpenItemsModal}
          toggle={() => setIsOpenItems((prevState) => !prevState)}
          modalTitle={infoModalData.title}
          modalData={infoModalData.modalData}
          modalKey={infoModalData.key}
          modalButtonText={infoModalData.modalButtonText}
          clientId={clientId}
          premiumData={premiumData}
          setTableData={setPremiumData}
          premiumCount={premiumCount}
          setTableCount={setTableCount}
          fetchPremiumData={fetchPremiumData}
        />
      )}
      <ConfirmationModal
        isOpen={openConfirmationModal}
        toggle={() => setOpenConfirmationModal((prevState) => !prevState)}
        title={confirmationModalData.title}
        body={confirmationModalData.body}
        confirmText={confirmationModalData.confirmText}
        modalData={deleteUserData}
        handleDelete={handleDelete}
        isDeleting={isDeleting}
        fetchPremiumData={fetchPremiumData}
      />
    </>
  );
}

export default RecipesTable;
