import React, { useState, useEffect } from "react";
import { Col, FormGroup, Input } from "reactstrap";

function CustomSelectComponent({ item, idx, value, onChange, disabled, dontUseEmptyOption = false, required = false, isAdminRoleDisabled, isDeviceRoleDisabled }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    item
      .options()
      .then((res) => {
        setData(res.data[Object.keys(res.data)[0]]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [item]);

  return (
    <Col lg="3" key={idx}>
      <FormGroup className="mb-2">
        <label className="form-control-label textWhite d-block">
          {item.label} {Boolean(required) && <span className="required-label">*</span>}
        </label>
        <Input
          placeholder={item.placeholder}
          name={item.name}
          disabled={disabled}
          type="select"
          value={value}
          className={disabled ? 'textBg-disabled' : "textBg"}
          onChange={onChange}
          required={required}
        >
          {!dontUseEmptyOption && <option />}
          {data &&
            data.map((option, idx) => {
              return (
                <option key={idx} value={option.id} disabled={
                  (isAdminRoleDisabled && (option.id === 1) && (option.label === 'admin')) ||
                  (isDeviceRoleDisabled && (option.id === 5) && (option.label === 'device'))
                }>
                  {option.name || option.label || option.versionNo}
                </option>
              );
            })}
        </Input>
      </FormGroup>
    </Col>
  );
}

export default CustomSelectComponent;
