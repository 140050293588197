import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import axios from "axios";
import Plot from "react-plotly.js";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import { rgb } from "d3";
import deepEqual from "utils/deepequal";

function DailyAverageGiveAway({ filtersData, setFiltersData, clientId }) {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({ ...filtersData });

  useEffect(() => {
    // setDuration("");
    if (deepEqual(filtersData, filter)) return;
    setFilter({ ...filtersData });
  }, [filtersData, clientId]);

  useEffect(() => {
    setIsLoading(true);
    setChartData([]);
    setChartLabels([]);
    axios
      .get(`${baseUrl}/api/mining/avg-GA-data`, {
        params: { ...filter, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        let labels = [];
        let data = {
          dailyAvgGA: [],
          // overallAvgGA: [],
          percentageGA: [],
        };
        console.log(res.data);
        res.data.response.forEach((item) => {
          labels.push(item.x);
          data.dailyAvgGA.push(item.averageGA);
          // data.overallAvgGA.push(res.data.averageGA.toFixed());
          // data.percentageGA.push(res.data.percentageGA);
        });
        res.data.percentageGAData.forEach((item) => {
          data.percentageGA.push(item.percentageGA);
        });
        setChartLabels(labels);
        // setFormat(res.data.days > 7 ? "%d %B \n %Y" : " %h:%M \n %d %B (%a)"); // setting the format for labels
        setChartData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setChartData([]);
        setChartLabels([]);
      });
  }, [filter, clientId]);

  const dailyAvgGaGraph = {
    x: chartLabels,
    y: chartData && chartData.dailyAvgGA,
    type: "line",
    // mode: "lines",
    name: "Total GA/Day",
    marker: { color: rgb(167, 0, 57) },
  };

  // const overallAvgGA = {
  //   x: chartLabels,
  //   y: chartData && chartData.overallAvgGA,
  //   type: "line",
  //   // mode: "lines",
  //   name: "Overall Average GA",
  //   marker: { color: "#5661c8" },
  // };

  const percentageGa = {
    x: chartLabels,
    y: chartData && chartData.percentageGA,
    type: "line",
    yaxis: "y2",
    // mode: "lines",
    name: "Percentage GA",
    marker: { color: "#fcba03" },
  };

  const formattedChartData = [
    dailyAvgGaGraph,
    // overallAvgGA,
    percentageGa,
  ];

  const options2 = {
    //title: 'KPI',
    autosize: true,
    showlegend: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: {
      title: "Daily Total GA (in KG)",
      // titlefont: { color: "rgb(167, 206, 57)" },
      // tickfont: { color: "rgb(167, 206, 57)" },
    },
    yaxis2: {
      title: "Percentage GA",
      titlefont: { color: "rgb(167, 206, 57)" },
      tickfont: { color: "rgb(167, 206, 57)" },
      overlaying: "y",
      side: "right",
    },
    xaxis: {
      // tickformat: labelFormat,
      // title: "Time",
      // titlefont: { color: "rgb(167, 206, 57)" },
      // tickfont: { color: "rgb(167, 206, 57)" },
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ? (
    <div className="m-5">Loading...</div>
  ) : (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">Give Away Analysis</h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {chartLabels.length > 0 ? (
          <div className="chart" style={{ height: "auto" }}>
            <div class="chart-container-bar">
              {chartData && (
                <Plot
                  data={formattedChartData}
                  className="w-100 h-100"
                  useResizeHandler
                  layout={options2}
                  config={config}
                  onRelayout={(e) => {
                    if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
                      setFiltersData({
                        ...filtersData,
                        start_date: new Date(e["xaxis.range[0]"]).toISOString(),
                        end_date: new Date(e["xaxis.range[1]"]).toISOString(),
                      });
                    }
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="m-5">No Data Found</div>
        )}
      </CardBody>
    </Card>
  );
}

export default DailyAverageGiveAway;
