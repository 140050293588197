import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import TutorialsTable from "./tutorialsTable";
// import AuthService from "../../services/auth.service";
import { baseUrl } from "constants/url";
import axios from "axios";
import authHeader from "services/auth-header";

function Index() {
  //   const { user } = useAuth();

  const [tutorialsList, setTutorialsList] = useState("Loading...");

  const fetchData = () => {
    setTutorialsList("Loading...");
    axios
      .get(`${baseUrl}/api/admin/tutorials`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data.tutorials.length);
        setTutorialsList(res.data.tutorials);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData()
  }, [])



  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      className="px-5"
    >
      <Grid item className="my-2" xs={12}>
        <TutorialsTable
          data={tutorialsList}
          fetchData={fetchData}
        />
      </Grid>
    </Grid>
  );
}

export default Index;
