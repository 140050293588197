import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
  CardImg,
  CardSubtitle,
  Tooltip
} from 'reactstrap';
import axios from 'axios';
import { baseUrl } from 'constants/url';
import { Grid } from '@material-ui/core';
import authHeader from '../../services/auth-header';
import Prize from '../../assets/img/icons/prize.png';
import AvatarImage from '../../assets/img/theme/team-4-800x800.jpg';

const TopOperators = ({ clientId, timePeriod }) => {
  const [topOperators, setTopOperators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/api/operatorPerformance/topOperators/${clientId}`, {
        params: { ...timePeriod },
        headers: authHeader()
      })
      .then(res => {
        console.log(res);
        setTopOperators(res.data.dataArray);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });
  }, [clientId, timePeriod]);

  return (
    <Grid
      container
      direction='column'
      justifyContent='space-between'
      alignItems='center'
      style={{ minHeight: '100%' }}
      spacing={1}
    >
      {isLoading ? (
        <div className='row justify-content-center my-5'>
          <Spinner />
        </div>
      ) : topOperators.length > 0 ? (
        topOperators.map(item => {
          return (
            <Grid
              item
              xs={12}
              className='w-100'
              style={{ backgroundColor: '#19312e' }}
            >
              <Card className='card-stats top-op-bg'>
                <CardBody className='bg-transparent'>
                  <Row
                    style={{
                      alignItems: 'center'
                    }}
                  >
                    <Col xs='3'>
                      <CardImg
                        // top
                        src={AvatarImage}
                        // width={10}
                        alt='User image'
                        className='rounded-circle'
                      />
                    </Col>
                    <Col xs='5'>
                      <CardBody
                        className='bg-transparent'
                        style={{
                          paddingInline: 0
                        }}
                      >
                        <CardTitle tag='h2' className='text-light'>
                          {item.opName}
                        </CardTitle>
                        <CardSubtitle
                          tag='h3'
                          className='py-2'
                          style={{ color: '#a7cd39' }}
                        >
                          Total Packs: <strong>{item.totalPacks}</strong>
                        </CardSubtitle>
                        <CardSubtitle
                          tag='h3'
                          className='py-2'
                          style={{ color: '#a7cd39' }}
                        >
                          Average Speed:{' '}
                          <strong>
                            {(item.totalPacks / item.runTime).toFixed(2)}
                          </strong>
                        </CardSubtitle>
                      </CardBody>
                    </Col>
                    <Col xs='4'>
                      <CardBody className='d-flex flex-column align-items-center justify-content-center h-100 bg-transparent'>
                        <Row className='align-items-center justify-content-center'>
                          <Col xs='auto'>
                            <div
                              className='d-flex align-items-center
                            justify-content-center
                            flex-wrap
                            '
                            >
                              <div
                                className='rounded-circle d-flex align-items-center justify-content-center mr-2'
                                style={{ width: '40px', height: '40px' }}
                              >
                                <img src={Prize} alt='' width='40' />
                              </div>
                              <div
                                className='text-white ml-3'
                                style={{ fontSize: '20px' }}
                              >
                                {item.efficiency.toFixed(2) + '%'}
                              </div>
                            </div>
                          </Col>
                          <Col xs='12' className='mt-2'>
                            <CardTitle
                              tag='h2'
                              className='text-white text-center mb-0'
                              style={{
                                fontSize: '16px'
                              }}
                            >
                              Efficiency Score
                            </CardTitle>
                            
                          </Col>
                        </Row>
                      </CardBody>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Grid>
          );
        })
      ) : (
        <span className='m-4'>No Data Found</span>
      )}
    </Grid>
  );
};

export default TopOperators;
