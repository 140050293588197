// import React, { useEffect, useState } from "react";
// import Plot from "react-plotly.js";

// function BarComponent({ barData, title }) {
//   console.log('BarData: ', barData);
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     if (barData.current.x) {
//       let currentBarValue = {
//         x: barData.current.x,
//         y: barData.current.y,
//         width: [1],
//         name: "Current",
//         orientation: "h",
//         type: "bar",
//         marker: {
//           color: "rgba(55,128,191,0.6)",
//         },
//       };

//       let previousBarValue = {
//         x: barData.previous.x,
//         y: barData.previous.y,
//         width: [1],
//         name: "Previous",
//         orientation: "h",
//         type: "bar",
//         marker: {
//           color: "rgba(255,153,51,0.6)",
//         },
//       };

//       setData([currentBarValue, previousBarValue]);
//     }
//   }, [barData]);

//   const layout = {
//     showlegend: true,
//     height: 250,
//     // responsive: true,
//     autosize: true,
//     barmode: "overlay",
//     // legend: {
//     //   orientation: "h",
//     //   yanchor: "bottom",
//     //   y: 1.02,
//     //   xanchor: "right",
//     //   x: 1,
//     // },
//     yaxis: { title },
//     // xaxis: {
//     //   tickformat: labelFormat,
//     // },
//     paper_bgcolor: "rgb(36,41,40)",
//     plot_bgcolor: "rgb(36,41,40)",
//     font: {
//       family: "Open Sans, sans-serif",
//       size: 13,
//       color: "white",
//     },
//   };

//   const config = {
//     displayModeBar: false, // this is the line that hides the bar.
//     // responsive: true
//   };

//   return (
//     <Plot
//       data={data}
//       className="w-100"
//       useResizeHandler
//       layout={layout}
//       config={config}
//       // onRelayout={(e) => {
//       //   if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
//       //     setFiltersData({
//       //       ...filtersData,
//       //       start_date: new Date(e["xaxis.range[0]"]).toISOString(),
//       //       end_date: new Date(e["xaxis.range[1]"]).toISOString(),
//       //     });
//       //   }
//       // }}
//     />
//   );
// }

// export default BarComponent;

import React from "react";
import { Progress, Row, Col } from "reactstrap";

function BarComponent({ barData ,title}) {
  // console.log("BarData: ", barData);
  // console.log("title: ", title);
  const style = {
    height: "30px",
  };
  if(!barData.current || !barData.previous) return null

  const max =
    barData.current.toFixed(2) >= barData.previous.toFixed()
      ? barData.current.toFixed(2) * 1.5
      : barData.previous.toFixed(2) * 1.5;

  // let current = barData.current > barData.previous ? barData.current - barData.previous : barData.current
  // let previous = barData.previous > barData.current ? barData.previous - barData.current : barData.current

  return (
    <Row className="mx-2">
      <Col xs="12" lg="3">
        <span style={{ color: "#a7ce39", fontWeight: 'bolder' }}>Current</span>
      </Col>
      <Col xs="12" lg="9">
        <Progress
          value={barData.current.toFixed(2)}
          className="current-bar"
          max={max}
          style={style}
        >
          <span className="text-dark font-weight-bold">
            {barData.current.toFixed(2)}
          </span>
        </Progress>
      </Col>

      <Col xs="12" lg="3">
        <span style={{ color: "#0c8ea5", fontWeight: 'bolder' }}>Previous</span>
      </Col>
      <Col xs="12" lg="9">
        <Progress
          value={barData.previous.toFixed(2)}
          className="previous-bar"
          color="info"
          max={max}
          style={style}
        >
          <span className="text-dark font-weight-bold">
            {barData.previous.toFixed(2)}
          </span>
        </Progress>
      </Col>
    </Row>
    // <Row className="mx-2">
    //   <Col xs="12">
    //     <Progress multi>
    //       <Progress
    //         bar
    //         color="success"
    //         value={barData.current.toFixed(2)}
    //         style={style}
    //       >
    //         Current: {barData.current.toFixed(2)}
    //       </Progress>
    //       <Progress
    //         bar
    //         color="info"
    //         value={barData.previous.toFixed(2)}
    //         style={style}
    //       >
    //         Previous: {barData.previous.toFixed(2)}
    //       </Progress>
    //     </Progress>
    //   </Col>
    // </Row>
  );
}

export default BarComponent;
