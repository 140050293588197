import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  Spinner,
} from "reactstrap";
import { modalConfiguration } from "../../variables/modalConfiguration";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import authHeader from "../../services/auth-header";
import CustomSelectComponent from "components/CustomSelect/CustomSelect";
import { toast } from "react-toastify";

function AddNewModal({
  isOpen,
  toggle,
  modalKey,
  modalTitle,
  modalData,
  modalButtonText,
  fetchData,
  user,
}) {
  const [modalList, setModalList] = useState(null);
  const [modalState, setModalState] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  const modalListInitState = (modalListArray) => {
    let tempState = {};
    modalListArray &&
      modalListArray.forEach((item) => {
        if (typeof item.name === "string") {
          tempState[item.name] = "";
        } else {
          item.name.forEach((key) => {
            tempState[key] = "";
          });
        }
      });
    return tempState;
  };

  useEffect(() => {
    if (modalKey) {
      setModalList(modalConfiguration[modalKey]);
      if (modalData) {
        setModalState({ ...modalData });
      } else {
        let tempState = modalListInitState(modalConfiguration[modalKey]);
        setModalState(tempState);
      }
    }
  }, [modalKey, modalData]);

  const onChange = (event) => {
    if (event.target.type === "file") {
      setModalState((prevState) => {
        return {
          ...prevState,
          [event.target.name]: event.target.files[0],
        };
      });
    } else {
      setModalState((prevState) => {
        return {
          ...prevState,
          [event.target.name]: event.target.value,
        };
      });
    }
  };

  const closeModal = () => {
    setModalState(modalListInitState(modalConfiguration[modalKey]));
    toggle();
  };

  const addNew = () => {
    setIsAdding(true);
    console.log(modalTitle);
    if (modalTitle === "Add New Tutorial") {
      console.log('State: ', modalState);
      let formData = new FormData();
      formData.append("title", modalState["title"]);
      formData.append("description", modalState["description"]);
      formData.append("is_restricted", modalState["is_restricted"]);
      formData.append("video_url", modalState["file"]);

      axios
        .post(`${baseUrl}/api/admin/tutorials`, formData)
        .then((res) => {
          fetchData();
          setIsAdding(false);
          closeModal();
          toast.success("Tutorial added successfully");
        })
        .catch((err) => {
          console.log(err);
          setIsAdding(false);
          const errMessage = err.response ? err.response.data.message : "Error adding tutorial";
          toast.error(errMessage);
        });
    } else if (modalTitle === "Update Tutorial") {
      let formData = new FormData();
      formData.append("title", modalState["title"]);
      formData.append("description", modalState["description"]);
      formData.append("is_restricted", modalState["is_restricted"]);
      formData.append("video_url", modalState["file"]);
      axios
        .put(`${baseUrl}/api/admin/tutorials/${modalData.id}`, formData)
        .then((res) => {
          fetchData();
          setIsAdding(false);
          closeModal();
          toast.success("Tutorial updated successfully");
        })
        .catch((err) => {
          console.log(err);
          setIsAdding(false);
          const errMessage = err.response ? err.response.data.message : "Error adding tutorial";
          toast.error(errMessage);
        });
    }
  };

  return (
    <Modal
      centered
      scrollable
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-bg"
      backdropClassName="modal-backdrop-opacity"
    >
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <div className="text-white rounded-circle">
              <i className="fas fa-times" />
            </div>
          </button>
        }
      >
        <h2 style={{ color: "#a7ce39" }}>{modalTitle}</h2>
      </ModalHeader>
      <ModalBody>
        <Form>
          <div className="pl-lg-4">
            <Row>
              {modalList &&
                modalList.map((item, idx) => {
                  if (item.type === "text") {
                    return (
                      <Col lg="3" key={idx}>
                        <FormGroup className="mb-2">
                          <label className="form-control-label textWhite d-block">
                            {item.label}
                          </label>
                          <Input
                            placeholder={item.placeholder}
                            name={item.name}
                            type={
                              item.label === "Password" ? "password" : "text"
                            }
                            className="textBg"
                            disabled={
                              (modalTitle === "Update User" &&
                                item.label === "Username") ||
                              (modalTitle === "Update Client" &&
                                ["clientId", "name"].includes(item.name))
                            }
                            value={modalState[item.name]}
                            onChange={onChange}
                          />
                        </FormGroup>
                      </Col>
                    );
                  } else if (item.type === "select") {
                    console.log(item);
                    return (
                      <CustomSelectComponent
                        item={item}
                        idx={idx}
                        dontUseEmptyOption={item.dontUseEmptyOption}
                        disabled={
                          (modalTitle === "Update User" &&
                            (item.label === "Client" ||
                              item.label === "Role")) ||
                          (user?.role === "clientAdmin" &&
                            (item.label === "Client" || item.label === "Role"))
                          // ||
                          // (modalTitle === "Update Client" &&
                          //   ["distributorId"].includes(item.name))
                        }
                        // value={modalState[item.name]}
                        value={
                          user?.role === "clientAdmin" &&
                            item.label === "Client"
                            ? user?.clientId
                            : user?.role === "clientAdmin" &&
                              item.label === "Role"
                              ? 2
                              : modalState[item.name]
                        }
                        onChange={onChange}
                      />
                    );
                  } else if (item.type === "select_local") {
                    return (
                      <Col lg="3" key={idx}>
                        <FormGroup className="mb-2">
                          <label className="form-control-label textWhite d-block">
                            {item.label}
                          </label>
                          <Input
                            placeholder={item.placeholder}
                            name={item.name}
                            type="select"
                            disabled={
                              modalTitle === "Update User" &&
                              (item.label === "Client" || item.label === "Role")
                            }
                            className="textBg"
                            value={modalState[item.name]}
                            onChange={onChange}
                          >
                            {item.options.map((option, idx) => {
                              return (
                                <option key={idx} value={option}>
                                  {option}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    );
                  } else if (item.type === "date") {
                    return (
                      <Col lg="3" key={idx}>
                        <FormGroup className="mb-2">
                          <label className="form-control-label mb-0 d-block">
                            {item.label}
                          </label>
                          <Input
                            name={item.name}
                            placeholder={item.placeholder}
                            type="date"
                            value={modalState[item.name]}
                            onChange={onChange}
                          />
                        </FormGroup>
                      </Col>
                    );
                  } else if (item.type === "number") {
                    return (
                      <Col lg="3" key={idx}>
                        <FormGroup className="mb-2">
                          <label className="form-control-label mb-0 d-block">
                            {item.label}
                          </label>
                          <Input
                            name={item.name}
                            placeholder={item.placeholder}
                            type="number"
                            value={modalState[item.name]}
                            onChange={onChange}
                          />
                        </FormGroup>
                      </Col>
                    );
                  } else if (item.type === "file" && item.name === "fileCsv") {
                    return (
                      <Col lg="3" key={idx}>
                        <FormGroup className="mb-2">
                          <label className="form-control-label mb-2 d-block text-muted">
                            {item.label}
                          </label>
                          <label
                            style={{
                              display: "block",
                              width: "100%",
                              height: "calc(1.5em + 1.25rem + 2px)",
                              padding: "0.625rem 0.75rem",
                              fontSize: "0.875rem",
                              fontWeight: 400,
                              lineHeight: 1.5,
                              // backgroundColor: "#19312e !important",
                              color: "white !important",
                              backgroundClip: "padding-box",
                              border: "1px solid #cad1d7",
                              borderRadius: "0.375rem",
                              boxShadow: "none",
                              // wordWrap: 'break-word',
                              overflow: "hidden",
                              transition:
                                "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                            }}
                            className="btn btn-primary"
                            htmlFor="fileCsv"
                          >
                            {/* {item.label} */}
                            {modalState[item.name].row1
                              ? "File attached"
                              : "Upload"}
                          </label>
                          <Input
                            name={item.name}
                            placeholder={item.placeholder}
                            type="file"
                            id="fileCsv"
                            accept=".csv"
                            onChange={onChange}
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            className="d-none"
                          />
                        </FormGroup>
                      </Col>
                    );
                  } else if (item.type === "file" && item.name === "file") {
                    return (
                      <Col lg="3" key={idx}>
                        <FormGroup className="mb-2">
                          <label className="form-control-label mb-2 d-block text-muted">
                            {item.label}
                          </label>
                          <label
                            style={{
                              display: "block",
                              width: "100%",
                              height: "calc(1.5em + 1.25rem + 2px)",
                              padding: "0.625rem 0.75rem",
                              fontSize: "0.875rem",
                              fontWeight: 400,
                              lineHeight: 1.5,
                              // backgroundColor: "#19312e !important",
                              color: "white !important",
                              backgroundClip: "padding-box",
                              border: "1px solid #cad1d7",
                              borderRadius: "0.375rem",
                              boxShadow: "none",
                              // wordWrap: 'break-word',
                              overflow: "hidden",
                              transition:
                                "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                            }}
                            className="btn btn-primary"
                            htmlFor="file"
                          >
                            {/* {item.label} */}
                            {modalState[item.name] ? "File attached" : "Upload"}
                          </label>
                          <Input
                            name={item.name}
                            placeholder={item.placeholder}
                            type="file"
                            id="file"
                            accept="*"
                            onChange={onChange}
                            onClick={(e) => {
                              console.log(e);
                              e.target.value = null;
                            }}
                            className="d-none"
                          />
                        </FormGroup>
                      </Col>
                    );
                  } else return null;
                })}
            </Row>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          style={{ backgroundColor: "#a7ce39", border: "none" }}
          onClick={addNew}
        >
          {isAdding ? (
            <>
              Adding...
              <Spinner className="ml-2" size="sm" />
            </>
          ) : (
            modalButtonText
          )}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default AddNewModal;
