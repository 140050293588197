import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";
import DistributorsTable from "./distributorsTable";
import ClientsTable from "./clientsTable";
import MachinesTable from "./machinesTable";
import MachineTypesTable from "./machineTypesTable";
import UsersTable from "./usersTable";
import AuthService from "./../../services/auth.service";
import { useHistory } from "react-router-dom";
import LinesTable from "./LinesTable";
import VersionTable from "./versionTable";
import { baseUrl } from "constants/url";
import axios from "axios";
import authHeader from "services/auth-header";
import DevicesTable from "./devicesTable";
import { useAuth } from "contexts/AuthContext";

function Index() {
  const { user } = useAuth();

  const history = useHistory();

  const [usersData, setUsersData] = useState([]);
  const [usersCount, setUsersCount] = useState(0);

  const fetchUsersData = useCallback(() => {
    axios
      .get(`${baseUrl}/api/admin/user`, {
        headers: authHeader(),
      })
      .then((res) => {
        setUsersData(res.data.users);
        setUsersCount(res.data.users.length);
      })
      .catch((err) => {
        console.log(err);
        setUsersCount([]);
      });
  }, []);

  const [clientsData, setClientsData] = useState([]);
  const [clientsCount, setClientsCount] = useState(0);

  const fetchClientsData = useCallback(() => {
    axios
      .get(`${baseUrl}/api/admin/client`, {
        headers: authHeader(),
      })
      .then((res) => {
        setClientsData(res.data.clients);
        setClientsCount(res.data.clients.length);
      })
      .catch((err) => {
        console.log(err);
        setClientsData([]);
      });
  }, []);

  const [machinesData, setMachinesData] = useState([]);
  const [machinesCount, setMachinesCount] = useState(0);

  const fetchMachinesData = useCallback(() => {
    axios
      .get(`${baseUrl}/api/admin/machine`, {
        headers: authHeader(),
      })
      .then((res) => {
        setMachinesData(res.data.machines);
        setMachinesCount(res.data.machines.length);
      })
      .catch((err) => {
        console.log(err);
        setMachinesData([]);
      });
  }, []);

  const [linesData, setLinesData] = useState([]);
  const [linesCount, setLinesCount] = useState(0);

  const fetchLinesData = useCallback(() => {
    axios
      .get(`${baseUrl}/api/admin/getLines`, {
        headers: authHeader(),
      })
      .then((res) => {
        setLinesData(res.data.lines);
        setLinesCount(res.data.lines.length);
      })
      .catch((err) => {
        console.log(err);
        setLinesData([]);
      });
  }, []);

  const [devicesData, setDevicesData] = useState([]);
  const [devicesCount, setDevicesCount] = useState(0);

  const fetchDevicesData = useCallback(() => {
    axios
      .get(`${baseUrl}/api/admin/getDevices`, {
        headers: authHeader(),
      })
      .then((res) => {
        setDevicesData(res.data.devices);
        setDevicesCount(res.data.devices.length);
      })
      .catch((err) => {
        console.log(err);
        setDevicesData([]);
      });
  }, []);

  if (user.role === "user") {
    history.push("/admin/index");
  }

  if (user.role === "engineer") {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className="px-5"
      >
        <Grid item className="my-2" xs={12} lg={12}>
          <DevicesTable
            tableData={devicesData}
            setTableData={setDevicesData}
            count={devicesCount}
            fetchData={fetchDevicesData}
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className="px-5"
      >
        {user.role === "admin" && (
          <>
            <Grid item className="my-2" xs={12} lg={6}>
              <DistributorsTable />
            </Grid>
            <Grid item className="my-2" xs={12} lg={6}>
              <LinesTable
                tableData={linesData}
                setTableData={setLinesData}
                count={linesCount}
                fetchData={fetchLinesData}
              />
            </Grid>
            <Grid item className="my-2" xs={12}>
              <ClientsTable
                tableData={clientsData}
                setTableData={setClientsData}
                count={clientsCount}
                fetchData={fetchClientsData}
                fetchUsersData={fetchUsersData}
                fetchMachinesData={fetchMachinesData}
                fetchLinesData={fetchLinesData}
              />
            </Grid>
            <Grid item className="my-2" xs={12} lg={6}>
              <MachinesTable
                tableData={machinesData}
                setTableData={setMachinesData}
                count={machinesCount}
                fetchData={fetchMachinesData}
              />
            </Grid>
            <Grid item className="my-2" xs={12} lg={6}>
              <MachineTypesTable />
            </Grid>
            <Grid item className="my-2" xs={12} lg={6}>
              <VersionTable />
            </Grid>
            <Grid item className="my-2" xs={12} lg={6}>
              <DevicesTable
                tableData={devicesData}
                setTableData={setDevicesData}
                count={devicesCount}
                fetchData={fetchDevicesData}
              />
            </Grid>
          </>
        )}

        <Grid item className="my-2" xs={12}>
          <UsersTable
            tableData={usersData}
            setTableData={setUsersData}
            count={usersCount}
            fetchData={fetchUsersData}
          />
        </Grid>
      </Grid>
    );
  }
}

export default Index;
