import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "constants/url";
import deepEqual from "utils/deepequal";
// import authHeader from "services/auth-header";
import { Card, CardBody, Col, Spinner } from "reactstrap";
import PacksKpiGraph from "./PacksKpiGraph";
import PacksHeatMapComponent from "./PacksHeatmap";
import BarGraph from "./BarGraph";

const ProductBrandBarGraphContainer = ({ filtersData, clientId }) => {
  const [productsData, setProductsData] = useState(null);
  const [brandsData, setBrandsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    start_date: filtersData.start_date,
    end_date: filtersData.end_date,
  });

  useEffect(() => {
    if (
      deepEqual(
        { start_date: filtersData.start_date, end_date: filtersData.end_date },
        filter
      )
    )
      return;
    setFilter({
      start_date: filtersData.start_date,
      end_date: filtersData.end_date,
    });
  }, [filtersData, clientId]);

  useEffect(() => {
    setIsLoading(true);
    setProductsData([]);
    setBrandsData([]);
    axios
      .get(`${baseUrl}/api/mining/products-brands-cost-graph-2`, {
        params: { ...filter, clientId },
      })
      .then((res) => {
        console.log("res", res);
        setProductsData(res.data.costPerProduct);
        setBrandsData(res.data.costPerBrand);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setProductsData([]);
        setBrandsData([]);
      });
  }, [filter, clientId]);

  return isLoading ? (
    <Col xs="12" className="text-center my-2">
      <Spinner />
    </Col>
  ) : (
    <>
      <Col xs="12" md="6" className="mb-4">
        <Card className="card-stats bg-transparent">
          <CardBody className="p-0">
            <BarGraph
              graphData={productsData}
              isLoading={isLoading}
              title="Product Level Cost/Pack"
              color="rgba(55,128,191,0.6)"
              yaxisTitle="Cost/Pack"
            />
          </CardBody>
        </Card>
      </Col>
      <Col xs="12" md="6" className="mb-4">
        <Card className="card-stats bg-transparent">
          <CardBody className="p-0">
            <BarGraph
              graphData={brandsData}
              isLoading={isLoading}
              title="Brand Level Cost/Pack"
              color="#a7ce39"
              yaxisTitle="Cost/Pack"
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ProductBrandBarGraphContainer;
