function sortArray(arr) {
  return arr.sort((a, b) => {
    try {
      if (a.toLowerCase().split('g ')[1] < b.toLowerCase().split('g ')[1]) { return -1; }
      if (a.toLowerCase().split('g ')[1] > b.toLowerCase().split('g ')[1]) { return 1; }
    }
    catch (err) {
      console.log(err);
      if (a > b) return 1;
      if (a < b) return -1;
    }
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });
}

export default sortArray;