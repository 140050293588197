import React from "react";
import { MarkerType } from "react-flow-renderer";

const markerEndConfig = { type: MarkerType.ArrowClosed, width: 30, height: 30 };
const edgeType = "smoothstep";

export const nodes = [
  {
    id: "1",
    type: "input",
    data: {
      label: (
        <>
          <strong>Production Line</strong>
        </>
      )
    },
    position: { x: 250, y: 0 }
  },
  {
    id: "line3",
    data: {
      label: <>Line 3</>
    },
    position: { x: 100, y: 100 },
    style: {
      background: "#D6D5E6",
      color: "#333",
      border: "1px solid #222138",
      width: 180
    }
  },
  {
    id: "line1",
    data: {
      label: <>Line 1</>
    },
    position: { x: 370, y: 100 },
    style: {
      background: "#D6D5E6",
      color: "#333",
      border: "1px solid #222138",
      width: 180
    }
  },
  {
    id: "recipe3",
    position: { x: 420, y: 200 },
    data: {
      label: "M & S Raspberry"
    },
    style: {
      background: "#e30b5d",
      color: "white",
      border: "1px solid #222138",
      width: 180
    }
  },
  // {
  //   id: 'recipe4', E30B5C
  //   position: { x: 320, y: 200 },
  //   data: {
  //     label: 'Strawberry', #fc5a8d
  //   },
  // },
  {
    id: "recipe1",
    position: { x: 1, y: 200 },
    data: {
      label: "M & S Raspberry"
    },
    style: {
      background: "#e30b5d",
      color: "white",
      border: "1px solid #222138",
      width: 180
    }
  },
  {
    id: "recipe2",
    position: { x: 200, y: 200 },
    data: {
      label: "Strawberry"
    },
    style: {
      background: "#549474",
      color: "white",
      border: "1px solid #222138",
      width: 180
    }
  },
  {
    id: "time1",
    data: {
      label: "Morning"
    },
    style: { background: "#f6ab6c" },
    position: { x: 40, y: 300 }
  },
  {
    id: "time2",
    data: {
      label: "Evening"
    },
    position: { x: 210, y: 300 }
  },
  {
    id: "time3",
    data: {
      label: "Morning"
    },
    style: { background: "#f6ab6c" },
    position: { x: 370, y: 300 }
  },
  {
    id: "time4",
    data: {
      label: "Evening"
    },
    position: { x: 530, y: 300 }
  },
  {
    id: "operator1",
    data: {
      label: "Scale SC 1"
    },
    position: { x: 10, y: 400 }
  },
  {
    id: "operator2",
    data: {
      label: "Scale SC2"
    },
    position: { x: 190, y: 400 }
  },
  {
    id: "operator3",
    data: {
      label: "Scale SC1"
    },
    position: { x: 390, y: 400 }
  },
  {
    id: "kpi1",
    data: {
      label: "High KPI"
    },
    type: "output",
    position: { x: 1, y: 500 },
    style: {
      background: "#549474",
      color: "white",
      border: "1px solid #222138"
      //width: 180
    }
  },
  {
    id: "kpi2",
    data: {
      label: "Low KPI"
    },
    type: "output",
    position: { x: 180, y: 500 },
    style: {
      background: "#8f0311",
      color: "white",
      border: "1px solid #222138"
      //width: 180
    }
  },
  {
    id: "kpi3",
    data: {
      label: "Medium KPI"
    },
    type: "output",
    position: { x: 340, y: 500 },
    style: {
      background: "#de8209",
      color: "white",
      border: "1px solid #222138"
      //  width: 180
    }
  },
  {
    id: "kpi4",
    data: {
      label: "High KPI"
    },
    type: "output",
    position: { x: 500, y: 500 },
    style: {
      background: "#549474",
      color: "white",
      border: "1px solid #222138"
      //  width: 180
    }
  }
  // {
  //   id: '6',
  //   type: 'output',
  //   data: {
  //     label: (
  //       <>
  //         An <strong>output node</strong>
  //       </>
  //     ),
  //   },
  //   position: { x: 100, y: 480 },
  // },
  // {
  //   id: '7',
  //   type: 'output',
  //   data: { label: 'Another output node' },
  //   position: { x: 400, y: 450 },
  // },
];

export const edges = [
  {
    id: "e1-2",
    source: "1",
    target: "line1",
    markerEnd: markerEndConfig,
    type: edgeType
  }, // label: 'this is an edge label' },
  {
    id: "e1-3",
    source: "1",
    target: "line3",
    markerEnd: markerEndConfig,
    type: edgeType
  },
  {
    id: "e3-4",
    source: "line3",
    target: "recipe1",
    markerEnd: markerEndConfig,
    type: edgeType
    // animated: true,
    //  label: 'animated edge',
  },
  {
    id: "e4-5",
    source: "line3",
    target: "recipe2",
    markerEnd: markerEndConfig,
    type: edgeType
  },
  {
    id: "e5-6",
    source: "line1",
    target: "recipe3",
    markerEnd: markerEndConfig,
    type: edgeType
  },
  {
    id: "e5-7",
    source: "recipe2",
    target: "time1",
    markerEnd: markerEndConfig,
    type: edgeType
    // style: { stroke: "#f6ab6c" },
    // label: "a step edge",
    //animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  },
  {
    id: "e5-7",
    source: "recipe2",
    target: "time2",
    markerEnd: markerEndConfig,
    type: edgeType
    // label: "a step edge",
    //animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  },
  {
    id: "e5-7",
    source: "recipe3",
    target: "time3",
    markerEnd: markerEndConfig,
    type: edgeType
    // style: { stroke: "#f6ab6c" },
    // label: "a step edge",
    //animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  },
  {
    id: "e5-7",
    source: "recipe3",
    target: "time4",
    markerEnd: markerEndConfig,
    type: edgeType
    //   style: { stroke: "#f6ab6c" },
    // label: "a step edge",
    //animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  },
  {
    id: "e5-7",
    source: "time1",
    target: "operator1",
    markerEnd: markerEndConfig,
    type: edgeType
    // style: { stroke: "#f6ab6c" },
    // label: "a step edge",
    //animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  },
  {
    id: "e5-7",
    source: "time1",
    target: "operator2",
    markerEnd: markerEndConfig,
    type: edgeType

    // label: "a step edge",
    //animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  },
  {
    id: "e5-7",
    source: "time3",
    target: "operator3",
    markerEnd: markerEndConfig,
    type: edgeType
    // style: { stroke: "#f6ab6c" },
    // label: "a step edge",
    //animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  },
  {
    id: "e5-7",
    source: "time3",
    target: "operator4",
    markerEnd: markerEndConfig,
    type: edgeType
    //   style: { stroke: "#f6ab6c" },
    // label: "a step edge",
    //animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  },
  {
    id: "e5-7",
    source: "operator1",
    target: "kpi1",
    markerEnd: markerEndConfig,
    type: edgeType
    // style: { stroke: "#f6ab6c" },
    // label: "a step edge",
    //animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  },
  {
    id: "e5-7",
    source: "operator2",
    target: "kpi2",
    markerEnd: markerEndConfig,
    type: edgeType

    // label: "a step edge",
    //animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  },
  {
    id: "e5-7",
    source: "operator3",
    target: "kpi3",
    markerEnd: markerEndConfig,
    type: edgeType
    // style: { stroke: "#f6ab6c" },
    // label: "a step edge",
    //animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  },
  {
    id: "e5-7",
    source: "time4",
    target: "kpi4",
    markerEnd: markerEndConfig,
    type: edgeType
    //   style: { stroke: "#f6ab6c" },
    // label: "a step edge",
    //animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  }
];
