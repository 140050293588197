import React, { useState } from 'react';
import axios from 'axios';
import { baseUrl } from 'constants/url';
import { generateReport } from '../../utils/buildReport';
import authHeader from 'services/auth-header';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
// import report from '../../assets/report.xlsm';
import report from '../../assets/smurftec.xlsx';
// import report from '../../assets/report1.xlsx';
import * as XLSX from 'xlsx';

function Report({ id, clientId, toggle, recipe }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const handleGenerateExcelReport = async () => {
    setIsLoadingExcel(true);
    try {
      const res = await axios.get(
        `${baseUrl}/api/modal/generate-report-excel/${clientId}/${id}`,
        {
          headers: authHeader()
        }
      );

      console.log('Report Response => ', res.data);

      window.open(res.data.url, '_blank');
      // setTimeout(() => {
      //   window.open(res.data.url2, '_blank');
      // }, 2000); // Increase the delay to 2000 milliseconds (2 seconds) or more.
    } catch (err) {
      console.log('ERROR IN SHEET', err);
    } finally {
      setIsLoadingExcel(false);
    }
  };
  const handleGenerateReport = () => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/api/modal/generate-report/${clientId}/${id}`, {
        headers: authHeader()
      })
      .then(res => {
        console.log('Report Response => ', res.data);
        // const file = new Blob([res.data.doc], { type: "application/pdf" });
        // const fileURL = URL.createObjectURL(file);
        // const pdfWindow = window.open();
        //  pdfWindow.location.href = fileURL;
        let doc;
        if (res.data.checkWeigherData) {
          doc = generateReport(res.data.data, res.data.checkWeigherData.data);
        } else {
          doc = generateReport(res.data.data, {});
        }
        setIsLoading(false);
        doc.save(`Report-${recipe}.pdf`);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <>
        <button
          className='btn'
          onClick={handleGenerateReport}
          //   href={ReportPdf}
          //   target="_blank"
          //   rel="noreferrer"
          style={{
            backgroundColor: '#a7ce39',
            border: 'none',
            color: 'black',
            minWidth: '100'
          }}
        >
          {isLoading ? <CircularProgress size={20} /> : 'Generate PDF Report'}
        </button>
        <button
          className='btn'
          onClick={handleGenerateExcelReport}
          //   href={ReportPdf}
          //   target="_blank"
          //   rel="noreferrer"
          style={{
            backgroundColor: '#a7ce39',
            border: 'none',
            color: 'black',
            minWidth: '100'
          }}
        >
          {isLoadingExcel ? (
            <CircularProgress size={20} />
          ) : (
            'Generate Excel Report'
          )}
        </button>
      </>
      <button className='close' onClick={toggle}>
        <div className='text-white rounded-circle'>
          <i className='fas fa-times' />
        </div>
      </button>
    </div>
  );
}

export default Report;
