import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import { Chart } from "react-chartjs-2";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import Plot from "react-plotly.js";

function KpiMin({ title, batch_id, clientId, runTime }) {
  const [chartLabels, setChartLabels] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const options = {
  //   plugins: {
  //     legend: {
  //       position: "bottom",
  //       labels: {
  //         color: "#89aa9f",
  //       },
  //     },
  //   },
  //   stacked: false,
  //   scales: {
  //     x: {
  //       offset: true,
  //       ticks: {
  //         source: "data",
  //         color: "#89aa9f",
  //       },
  //       type: "timeseries",
  //       time: {
  //         displayFormats: {
  //           millisecond: "DD-MM-YY, HH:mm:ss.SSS",
  //           second: "DD-MM-YY, HH:mm:ss",
  //           minute: "DD-MM-YY, HH:mm",
  //           hour: "DD-MM-YY, HH",
  //           day: "DD-MM-YY",
  //           week: "DD-MM-YY",
  //           month: "MMM, YY",
  //           quarter: "MMM, YY",
  //           year: "YYYY",
  //         },
  //       },
  //     },
  //     y: {
  //       type: "linear",
  //       display: true,
  //       position: "left",
  //       ticks: {
  //         color: "#89aa9f",
  //       },
  //       title: {
  //         display: true,
  //         text: "KPI (%)",
  //       },
  //     },
  //     y1: {
  //       type: "linear",
  //       display: true,
  //       position: "right",
  //       ticks: {
  //         color: "#89aa9f",
  //       },
  //       grid: {
  //         drawOnChartArea: false,
  //       },
  //       title: {
  //         display: true,
  //         text: "Give Away (g)",
  //       },
  //     },
  //   },
  //   responsive: true,
  //   interaction: {
  //     mode: "index",
  //     intersect: false,
  //   },
  // };

  useEffect(() => {
    setIsLoading(true);
    setChartData([]);
    setChartLabels([]);
    axios
      .get(
        `${baseUrl}/api/modal/modal-chart-sc-ag-data/ga/${clientId}/${batch_id}`
      )
      .then((res) => {
        let labels = [];
        let data = [];
        const dataArray = res.data.dataData.ga;
        for (let i = 0; i < dataArray.length; i++) {
          labels.push(dataArray[i].x);
          if (i === 0) {
            data.push(dataArray[i].y);
          } else {
            data.push(dataArray[i].y - dataArray[i - 1].y);
          }
        }

        // res.data.dataData.ga.forEach((item, index) => {
        //   labels.push(item.x);
        //   if (index === 0) {
        //     data.push(item.y);
        //   } else {

        //   }

        // });
        setChartLabels(labels);
        setChartData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setChartData([]);
        setChartLabels([]);
      });
  }, [batch_id, clientId, runTime]);

  // const data = {
  //   labels: chartLabels && chartLabels,
  //   datasets: [
  //     {
  //       type: "line",
  //       label: "KPI (%)",
  //       borderColor: "#5661c8",
  //       backgroundColor: "#5661c8",
  //       borderWidth: 2,
  //       data: chartData && chartData.dataKpi,
  //       yAxisID: "y",
  //     },
  //     {
  //       type: "line",
  //       label: "GA (g)",
  //       borderColor: "#000000",
  //       backgroundColor: "#000000",
  //       borderWidth: 2,
  //       data: chartData && chartData.dataGa,
  //       yAxisID: "y1",
  //     },
  //   ],
  // };

  const data = {
    x: chartLabels,
    y: chartData && chartData,
    type: "line",
    mode: "lines",
    name: "GA (g)",
    marker: { color: "#5661c8" },
  };

  const formattedChartData = [data];

  const options2 = {
    //title: 'KPI',
    autosize: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: { title: "Give Away (g)" },
    xaxis: {
      tickformat: "%H:%M \n %d %B",
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ? (
    <div className="m-5">Loading...</div>
  ) : (
    <Card className="shadow-sm">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">{title}</h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart" style={{ height: "auto" }}>
          <div class="chart-container-bar">
            <Plot
              data={formattedChartData}
              className="w-100 h-100"
              useResizeHandler
              layout={options2}
              config={config}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default KpiMin;
