import React, { createContext, useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import routes from "routes.js";
// import Particles from "react-tsparticles";
// import { particleOptions } from "constants/particleOptions";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";
import { useContext } from "react";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import sortArray from "utils/sort";
import { useAuth } from "contexts/AuthContext";

export const DataContext = createContext();

const Admin = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { user, authenticating } = useAuth()
  // const userCodeVerified = AuthService.getUserCodeVerified()
  const [clientName, setClientName] = useState(user?.clientName ? user.clientName : '')
  const [clientId, setClientId] = useState('')
  const [clientSubscription, setClientSubscription] = useState(user?.subscriptionLabel ? user.subscriptionLabel : '')
  const [lineData, setLineData] = useState("Loading...");

  React.useEffect(() => {
    if (location.pathname.includes('/admin/index')) {
      setClientName(user?.clientName ? user.clientName : '')
      setClientId(user?.role === 'admin' ? "" : user?.clientId)
    }
  }, [location.pathname])
  React.useEffect(() => {
    if (location.pathname.includes('/admin/view_data')) {
      setClientName(user?.clientName ? user.clientName : '')
      setClientId(user?.role === 'admin' ? "" : user?.clientId)
    }
  }, [location.pathname])

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            // component={prop.component}
            render={(props) => <prop.component {...props} setClientName={setClientName} clientId={clientId} setClientId={setClientId} clientSubscription={clientSubscription} setClientSubscription={setClientSubscription} />}
            // component={() => <prop.component setClientName={setClientName} clientId={clientId} setClientId={setClientId} clientSubscription={clientSubscription} setClientSubscription={setClientSubscription} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (!clientId) return;
    axios
      .get(`${baseUrl}/api/get_lookup_data/line/${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        // const option = res.data.data.map((item) => {
        //   return {
        //     label: item,
        //     value: item,
        //   };
        // });
        // option.unshift({ label: 'All', value: 'All' })
        // setLineData(option);
        let sortedData = sortArray(res.data.data);
        setLineData(sortedData)
      })
      .catch((err) => {
        console.log(err);
        setLineData("No Data Found")
      });
  }, [clientId]);

  return (
    <DataContext.Provider value={{ lineData }}>
      <>
        {/* <Particles id="tsparticles" options={particleOptions} /> */}
        {user && (
          <div className="main-content min-vh-100 pb-7" style={{ backgroundColor: '#19312e' }}>
            <AdminNavbar {...props} clientName={clientName} setClientName={setClientName} setClientId={setClientId} />
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/admin/index" />
            </Switch>
            <Container fluid className="position-absolute bottom-0">
              <AdminFooter />
            </Container>
          </div>
        )}
      </>
    </DataContext.Provider>
  );
};

export default Admin;
