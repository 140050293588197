import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  Spinner,
  Label,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import { calculateRecipeValues } from "utils/calculateRecipeValues";
import authHeader from "../../services/auth-header";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";
import Table from "./Table";
import { TablePagination } from "@material-ui/core";
import ConfirmationModal from "./ConfirmationModal";

// import CustomSelectComponent from "components/CustomSelect/CustomSelect";

function AddNewPremiumItemsModal({
  isOpen,
  toggle,
  modalTitle,
  modalData,
  modalButtonText,
  clientId,
  modalKey,
  premiumData: tableData,
  setTableData: setPremiumData,
  premiumCount: count,
  setTableCount: setCount,
  fetchPremiumData,
  user
}) {
  const [tableColumns, setTableColumns] = useState([
    {
      id: modalKey,
      title: modalKey.slice(0, 1).toUpperCase() + modalKey.slice(1),
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "action",
      title: "ACTION",
      className: "sort table-data-font",
      onClick: null,
      render: (record) => renderHtml("action", record, modalKey),
    },
  ]);
  const [itemData, setItemData] = useState(
    modalData
      ? { ...modalData }
      : {
          name: "",
        }
  );
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationModalData, setConfirmationModalData] = useState({
    title: "",
    body: "",
    confirmText: "",
  });
  const [isAdding, setIsAdding] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [editItem, setEditItem] = useState();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setItemData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setGeneralError(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const renderHtml = (key, record, modalKey) => {
    switch (key) {
      case "action":
        return (
          <>
            <i
              className="text-danger btn fa fa-trash px-1 py-0 m-0"
              onClick={() => {
                setConfirmationModalData({
                  title: `Delete this ${modalKey}`,
                  body: "Are you sure you want to delete this item?",
                  confirmText: "Confirm",
                });
                setDeleteUserData(record);
                setOpenConfirmationModal(true);
              }}
            />
            <i
              className="text-info btn fa fa-edit px-1 py-0 m-0"
              onClick={() => {
                setItemData({
                  [modalKey]: record[modalKey],
                });
                setIsEditOpen(true);
                setEditItem(record);
                // setOpenModal(true);
                // setInfoModalData({
                //   key: "recipe",
                //   title: "Update Recipe",
                //   modalData: record,
                //   modalButtonText: "Update",
                // });
              }}
            />
          </>
        );

      default:
        break;
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeModal = () => {
    toggle();
  };

  const handleDelete = () => {
    setIsDeleting(true);
    let url = `${baseUrl}/api/admin/delete${modalKey}/${deleteUserData.id}`;
    if(user.role === 'admin') {
      url += `?clientId=${clientId}`;
    }
    axios
      .delete(url, {
        headers: authHeader(),
      })
      .then((res) => {
        fetchPremiumData(
          `${baseUrl}/api/admin/get${modalKey}s`,
          `${modalKey}s`
        );
        setIsDeleting(false);
        setOpenConfirmationModal(false);
        toast.success(
          `${modalKey.slice(0, 1).toUpperCase()}${modalKey.slice(
            1
          )} deleted successfully`
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message || err.message || 'Something went wrong');
        setIsDeleting(false);
      });
  };

  const addNewItem = (e) => {
    e.preventDefault();
    console.log("Add new: ");
    console.log(itemData);
    if (
      modalKey === "weight" &&
      itemData.weight &&
      (itemData.weight < 11 || itemData.weight > 3999)
    ) {
      toast.error("Weight should be greater than 10 and less than 4000");
      return;
    }
    setIsAdding(true);
    axios
      .post(
        `${baseUrl}/api/admin/add${modalKey}`,
        {
          [modalKey]: itemData[modalKey],
          clientId,
        },
        
      )
      .then((res) => {
        fetchPremiumData(
          `${baseUrl}/api/admin/get${modalKey}s`,
          `${modalKey}s`
        );
        setItemData({
          [modalKey]: "",
        });
        setIsAdding(false);
        toast.success(
          `${modalKey.slice(0, 1).toUpperCase()}${modalKey.slice(
            1
          )} added successfully`
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message || err.message || 'Something went wrong');
        setIsAdding(false);
      });
  };

  const updateItem = (e) => {
    e.preventDefault();
    console.log("Update new: ");
    console.log(itemData);
    if (
      modalKey === "weight" &&
      itemData.weight &&
      (itemData.weight < 11 || itemData.weight > 3999)
    ) {
      toast.error("Weight should be greater than 10 and less than 4000");
      return;
    }
    axios
      .put(`${baseUrl}/api/admin/${modalKey}/${editItem.id}`, itemData, {
        headers: authHeader(),
      })
      .then((res) => {
        setIsAdding(false);
        setIsEditOpen(false);
        setItemData({
          [modalKey]: "",
        });
        fetchPremiumData(
          `${baseUrl}/api/admin/get${modalKey}s`,
          `${modalKey}s`
        );
        setEditItem(null);
        toast.success(
          `${modalKey.slice(0, 1).toUpperCase()}${modalKey.slice(
            1
          )} updated successfully`
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message || err.message || 'Something went wrong');
        setIsAdding(false);
      });
  };

  return (
    <Modal
      centered
      scrollable
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-bg"
      backdropClassName="modal-backdrop-opacity"
    >
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <div className="text-white rounded-circle">
              <i className="fas fa-times" />
            </div>
          </button>
        }
      >
        <h2 style={{ color: "#a7ce39" }}>{modalTitle}</h2>
      </ModalHeader>
      <ModalBody>
        <Form id="add_form" onSubmit={isEditOpen ? updateItem : addNewItem}>
          <div className="pl-lg-4">
            <Row className="justify-content-center align-item-center mx-auto mb-3 text-danger font-weight-bolder">
              {generalError
                ? "Decimal values not allowed for this field. Please provide values in integer."
                : ""}
            </Row>
            <Row className="align-item-center">
              <Col
                xs={12}
                lg={3}
                style={{
                  flexDirection: "row !important",
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  minWidth: "fit-content",
                }}
              >
                <FormGroup className="mb-3 w-75">
                  <Tooltip
                    title="What type of product you are packing i.e. strawberries, blueberries"
                    arrow
                  >
                    <label
                      className="form-control-label textWhite d-block"
                      style={{ marginBottom: 0 }}
                    >
                      {modalKey.slice(0, 1).toUpperCase() + modalKey.slice(1)}
                    </label>
                  </Tooltip>
                  <Input
                    form="add_form"
                    placeholder={`${modalKey
                      .slice(0, 1)
                      .toUpperCase()}${modalKey.slice(1)}`}
                    name={modalKey}
                    type="text"
                    className="textBg"
                    //size="sm"
                    value={itemData[modalKey]}
                    onChange={onChange}
                    required
                    style={{ marginTop: 10 }}
                  />
                </FormGroup>
                {isEditOpen ? (
                  <>
                    <Button
                      style={{
                        backgroundColor: "#a7ce39",
                        border: "none",
                        marginTop: 10,
                        minWidth: 100,
                      }}
                      type="submit"
                      form="add_form"
                    >
                      {isAdding ? (
                        <>
                          <Spinner className="ml-2" size="sm" />
                        </>
                      ) : (
                        "Update"
                      )}
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#a7ce39",
                        border: "none",
                        marginTop: 10,
                        minWidth: 100,
                      }}
                      onClick={() => {
                        setIsEditOpen(false);
                        setItemData({
                          [modalKey]: "",
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    style={{
                      backgroundColor: "#a7ce39",
                      border: "none",
                      marginTop: 10,
                      minWidth: 100,
                    }}
                    type="submit"
                    form="add_form"
                  >
                    {isAdding ? (
                      <>
                        <Spinner className="ml-2" size="sm" />
                      </>
                    ) : (
                      modalButtonText
                    )}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Form>
        {tableData === "Loading..." ? (
          <div className="row justify-content-center my-5">
            <Spinner>Loading...</Spinner>
          </div>
        ) : (
          <>
            <Table
              values={tableData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              columns={tableColumns}
              tableClasses={{
                table:
                  "table align-items-center table-flush table-sm border-bottom text-white table-custom",
                thead: "",
                tbody: "list table-td-color",
              }}
            />
            <TablePagination
              component="div"
              className="text-muted"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal}>Cancel</Button>
      </ModalFooter>
      <ConfirmationModal
        isOpen={openConfirmationModal}
        toggle={() => setOpenConfirmationModal((prevState) => !prevState)}
        title={confirmationModalData.title}
        body={confirmationModalData.body}
        confirmText={confirmationModalData.confirmText}
        modalData={deleteUserData}
        handleDelete={handleDelete}
        isDeleting={isDeleting}
      />
    </Modal>
  );
}

export default AddNewPremiumItemsModal;
