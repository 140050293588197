import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Nav,
  NavItem,
  Input,
  Tooltip,
} from "reactstrap";
import Plot from "react-plotly.js";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import moment from "moment";
import deepEqual from "utils/deepequal";
import { FaInfoCircle } from "react-icons/fa";

function MinutesConsumed({ title, filtersData, setFiltersData, clientId }) {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({ ...filtersData });
  const [duration, setDuration] = useState("");
  const [labelFormat, setFormat] = useState("");
  const [tooltip, setTooltip] = useState(false);

  const onChange = (event) => {
    const value = event.target.value;
    setDuration(value);
    if (value === "") {
      setFilter({
        ...filtersData,
      });
    } else if (value === "Last Week") {
      const start = moment().subtract(1, "weeks").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      setFilter((prevState) => {
        return {
          ...prevState,
          start_date: start,
          end_date: end,
        };
      });
    } else if (value === "Last Month") {
      const start = moment().subtract(1, "months").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      setFilter((prevState) => {
        return {
          ...prevState,
          start_date: start,
          end_date: end,
        };
      });
    } else if (value === "Last Year") {
      const start = moment().subtract(1, "years").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      setFilter((prevState) => {
        return {
          ...prevState,
          start_date: start,
          end_date: end,
        };
      });
    }
  };

  const options = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: "#89aa9f",
        },
      },
    },
    scales: {
      x: {
        offset: true,
        stacked: true,
        ticks: {
          source: "data",
          color: "#89aa9f",
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: "#89aa9f",
        },
      },
    },
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
  };

  useEffect(() => {
    setIsLoading(true);
    setChartLabels([]);
    setChartData([]);
    axios
      .get(`${baseUrl}/api/dashboard/charts/packs`, {
        params: { ...filter, clientId, chart_key: 'packs' },
        headers: authHeader(),
      })
      .then((res) => {
        const start_date = moment(filter.start_date);
        const end_date = moment(filter.end_date);

        const days = end_date.diff(start_date, "days");
        console.log('days', days)
        let labels = [];
        let data = [];
        res.data.data.packs.forEach((item) => {
          labels.push(days > 7 ?
            // only month and year
            moment(item.x).format("MMMM YYYY")
            : item.x);
          data.push(item.y);
        });
        setChartLabels(labels);
        setChartData(data);
        setFormat(res.data.days > 7 ? "%d %B \n %Y" : " %h:%M \n %d %B (%a)"); // setting the format for labels
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setChartData([]);
        setChartLabels([]);
      });
  }, [filter, clientId]);

  useEffect(() => {
    setDuration("");
    if (deepEqual(filtersData, filter)) return;
    setFilter({ ...filtersData });
  }, [filtersData, clientId]);

  // const data = {
  //   labels: chartLabels && chartLabels,
  //   datasets: [
  //     {
  //       type: "bar",
  //       label: "No of Packs",
  //       backgroundColor: "#EC1C24",
  //       data: chartData && chartData,
  //       barThickness: 25,
  //     },
  //   ],
  // };

  const packsData = {
    x: chartLabels,
    y: chartData && chartData,
    type: "line",
    yaxis: "y2",
    // mode: "lines",
    name: "Total Packs Produced",
    marker: { color: "#e7f551" },
    width: chartLabels && chartLabels.length > 1 ? "" : 0.1,
  };
  // print data and actual data

  const formattedChartData = [packsData];

  const options2 = {
    showlegend: true,
    autosize: true,
    //title: 'KPI',
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: { title: "KPI" },
    xaxis: {
      // tickformat: labelFormat,
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ? (
    <div className="m-5">Loading...</div>
  ) : (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              {title}
              <FaInfoCircle
                id="packs-chart-tooltip"
                fontSize={"18px"}
                className="ml-2"
              />
              <Tooltip
                placement={"top"}
                isOpen={tooltip}
                target={`packs-chart-tooltip`}
                toggle={() => {
                  setTooltip((st) => !st);
                }}
              >
                Packs Chart Data
              </Tooltip>
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <Input
                  placeholder="Duration"
                  name="country_id"
                  type="select"
                  value={duration}
                  className="textBg"
                  bsSize="sm"
                  style={{ color: "#000000" }}
                  onChange={onChange}
                >
                  {["", "Last Week", "Last Month", "Last Year"].map(
                    (option, idx) => {
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    }
                  )}
                </Input>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="p-0">
        {chartLabels.length > 0 ? (
          <div className="chart" style={{ height: "auto" }}>
            <div class="chart-container-bar">
              <Plot
                data={formattedChartData}
                className="w-100 h-100"
                useResizeHandler
                layout={options2}
                config={config}
                onRelayout={(e) => {
                  if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
                    setFiltersData({
                      ...filtersData,
                      start_date: new Date(e["xaxis.range[0]"]).toISOString(),
                      end_date: new Date(e["xaxis.range[1]"]).toISOString(),
                    });
                  }
                }}
              />
              {/* <Chart options={options} type="bar" data={data} /> */}
            </div>
          </div>
        ) : (
          <div className="m-5">No Data Found</div>
        )}
      </CardBody>
    </Card>
  );
}

export default MinutesConsumed;
