import React, { useState, useEffect } from "react";
import Filters from "views/shared/Filters";
// import DataTable from "views/TableContainer";
// import InProgressTable from "views/TableContainer/InProgressTable";
import Charts from "../charts/index";
import { CircularProgress, Grid } from "@material-ui/core";
import { Card, CardBody, CardHeader, FormGroup } from "reactstrap";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import RecipesTable from "views/managementPanel/recipesTable";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import AnalyticsBoard from "views/AnalyticsBoard/AnalyticsBoard";
import InProgressData from "views/InProgressData/InProgressData";
import OperatorPerformance from "views/OperatorPerformance/index";
import MiningPage from "views/Mining/index";
import { useAuth } from "contexts/AuthContext";
import OperatorsData from "views/OperatorsData";

const customStyles = {
  input: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base, state) => ({
    ...base,
    // background: "#023950",
    // background: "#737977",
    background: "#19312e",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    // borderColor: state.isFocused ? "yellow" : "green",
    borderColor: state.isFocused ? "#a7ce39" : "#8898aa",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#a7ce39" : "#a7ce39",
    },
  }),
  menu: (base) => ({
    ...base,
    // background: "#737977",
    background: "#19312e",
    color: "white",
    // override border radius to match the box
    border: "1px solid #8898aa",
    borderRadius: 5,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    "::-webkit-scrollbar": {
      display: "none",
    },
    // overflow: 'hidden'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      // backgroundColor: isFocused ? "#2462ad" : null,
      backgroundColor: isFocused ? "#a7ce39" : null,
      color: isFocused ? "black" : "white",
      // color: "#333333",
    };
  },
};

function AdminClientSelection({
  setClientName,
  clientId,
  setClientId,
  setClientSubscription,
  clientSubscription,
}) {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 7);

  const { user } = useAuth();
  const location = useLocation();
  // const [clientId, setClientId] = useState(user.elasticIndex);
  const [clientsList, setClientsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filtersData, setFiltersData] = useState({
    // start_date: "2020-01-01T00:00:00Z",
    start_date: yesterday.toISOString(),
    // end_date: "2020-12-29T23:59:59Z",
    end_date: today.toISOString(),
    recipe: "All",
    line: "All",
    kpiMin: 0,
    kpiMax: 200,
    searchByRecipe: "",
    // gaMin: -9999,
    // gaMax: 9999
  });
  const [timeRange, setTimeRange] = useState({
    start_time: "",
    end_time: "",
  });

  useEffect(() => {
    if (user.role === "admin") {
      // setIsLoading(true);
      axios
        .get(`${baseUrl}/api/admin/client-names`)
        .then((res) => {
          const option = res.data.clients.map((item) => {
            return {
              label: item.name,
              value: item.id,
              subscription: item.subscriptionLabel,
            };
          });
          // setClientsList(res.data.clients);
          setClientsList(option);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user.role]);

  const handleApplyFilters = (filterData) => {
    if (filterData.searchByRecipe) {
      setFiltersData({ ...filterData, recipe: "All" });
    } else {
      setFiltersData({ ...filterData });
    }
  };

  return clientId && location.pathname.includes("view_data") ? (
    <>
      <Filters
        filtersData={filtersData}
        handleApplyFilters={handleApplyFilters}
        clientId={clientId}
        clientSubscription={clientSubscription}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />
      <Charts
        filtersData={filtersData}
        setFiltersData={setFiltersData}
        clientId={clientId}
      />
      {/* <InProgressTable filtersData={filtersData} setFiltersData={setFiltersData} clientId={clientId} /> */}
      {/* <DataTable
        filtersData={filtersData}
        setFiltersData={setFiltersData}
        clientId={clientId}
      /> */}
      {clientSubscription === "premium" && (
        <OperatorsData
          filtersData={filtersData}
          setFiltersData={setFiltersData}
          clientId={clientId}
          timeRange={timeRange}
        />
      )}
      <RecipesTable
        filtersData={filtersData}
        clientId={clientId}
        clientSubscription={clientSubscription}
      />
    </>
  ) : clientId && location.pathname.includes("analytics") ? (
    <>
      <AnalyticsBoard clientId={clientId} />
    </>
  ) : clientId && location.pathname.includes("inProgress") ? (
    <>
      <InProgressData clientId={clientId} />
    </>
  ) : clientId && location.pathname.includes("operatorPerformance") ? (
    <>
      <OperatorPerformance clientId={clientId} />
    </>
  ) : clientId && location.pathname.includes("mining") ? (
    <>
      <MiningPage
        clientId={clientId}
        clientSubscription={clientSubscription}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />
    </>
  ) : user.role === "admin" ? (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "50vh" }}
      >
        <Grid item xs={10} lg={4}>
          {isLoading ? (
            <div className="text-center">
              <CircularProgress size={60} />
            </div>
          ) : (
            <Card className="card-stats">
              <CardHeader className="text-light h3">
                Please select Client for which you want to view data:
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <label className="form-control-label textWhite d-block">
                    Select Client:
                  </label>
                  {/* <Input
                    placeholder="Line"
                    name="line"
                    type="select"
                    className="textBg"
                    value={""}
                    onChange={(e) => setClientId(e.target.value)}
                  >
                    <option />
                    {clientsList &&
                      clientsList.map((option, idx) => {
                        return (
                          <option key={idx} value={option.indexName}>
                            {option.name}
                          </option>
                        );
                      })}
                  </Input> */}
                  <Select
                    placeholder="Select Client..."
                    name="clientId"
                    value={{ label: clientId }}
                    onChange={(e) => {
                      setClientId(e.value);
                      setClientName(e.label);
                      setClientSubscription(e.subscription);
                    }}
                    styles={customStyles}
                    options={
                      location.pathname.includes("mining")
                        ? clientsList.filter(
                            (el) => el.subscription === "premium"
                          )
                        : clientsList
                    }
                  />
                </FormGroup>
              </CardBody>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  ) : (
    <>Not Allowed</>
  );
}

export default AdminClientSelection;
