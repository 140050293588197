function deepEqual(object1, object2) {
  // First, check if they're of the same type and cover simple equality
  if (object1 === object2) {
    return true;
  }

  // If they're not both objects, they're not equal
  if (!(object1 instanceof Object && object2 instanceof Object)) {
    return false;
  }

  // Compare if they have the same number of keys
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Recursively check each property
  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }

    // If values of the same property are not equal, recursively call deepEqual
    if (!deepEqual(object1[key], object2[key])) {
      return false;
    }
  }

  // If everything matched, objects are deep equal
  return true;
}

export default deepEqual;