import React from "react";
import Table from "views/shared/Table";

function ModaloperatorTable({ tableData }) {
  const tableColumns = [
    {
      id: "operator",
      title: "Name",
      className: "sort",
      onClick: null,
      render: null,
    },
    {
      id: "ppm",
      title: "Packs",
      className: "sort",
      onClick: null,
      render: null,
    },
    {
      id: "kpi",
      title: "KPI %",
      className: "sort",
      onClick: null,
      render: null,
    },
    {
      id: "ga",
      title: "GA (g)",
      className: "sort",
      onClick: null,
      render: null,
    },
    {
      id: "t1_perc",
      title: "-T1 %",
      className: "sort",
      onClick: null,
      render: null,
    },
  ];
  return (
    <div>
      <Table
        values={tableData}
        columns={tableColumns}
        tableClasses={{
          table:
            "table align-items-center table-flush border-bottom text-white table-custom",
          thead: "",
          tbody: "list text-muted",
        }}
      />
    </div>
  );
}

export default ModaloperatorTable;
