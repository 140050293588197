import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import axios from "axios";
import Plot from "react-plotly.js";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";

function OpPerformance({ title, batch_id, clientId, runTime }) {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [labelFormat, setFormat] = useState("%H:%M \n %d %B");

  useEffect(() => {
    setIsLoading(false);
    setChartData([]);
    const backgroundColor = [
      "#3B2244",
      "#CD966B",
      "#004369",
      "#DB1F48",
      "#130170",
      "#01949A",
    ];
    axios
      .get(
        `${baseUrl}/api/modal/modal-chart-data/opPerformance/${clientId}/${batch_id}`
      )
      .then((res) => {
        let resChartData = [];

        Object.keys(res.data.data).forEach((item, idx) => {
          let operatorData = { operator: "", times: [], packs: [] };
          operatorData.operator = item;
          operatorData.times = res.data.data[item]["op_times"];
          operatorData.packs = res.data.data[item]["op_packs"];

          resChartData.push(operatorData);
        });

        setChartData(resChartData);
        setFormat("%H:%M \n %d %B"); // setting the format for labels
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setChartData([]);
      });
  }, [batch_id, clientId, runTime]);

  const data = {
    datasets: chartData,
  };

  const formattedChartData = [];

  chartData &&
    chartData.forEach((operator_data) => {
      const operator_temp = {
        x: operator_data.times,
        y: operator_data && operator_data.packs,
        type: "line",
        hovertemplate:
          "<i>Packs Produced</i>: %{y}" + "<br><b>Time</b>: %{x}<br>",
        //yaxis: "y2",
        mode: "lines",
        name: operator_data.operator,
        // marker: { color: "#5661c8" },
      };
      formattedChartData.push(operator_temp);
    });

  console.log("Formatted Chart Data ", formattedChartData);
  // console.log();

  const options2 = {
    //title: 'KPI',
    autosize: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: { title: "Packs Produced" },

    xaxis: {
      // tickformat: labelFormat,
      tickformat: "%H:%M \n %d %B",
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ? (
    <div className="m-5">Loading...</div>
  ) : (
    <Card className="shadow-sm">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">{title}</h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {isLoading ? (
          "Loading..."
        ) : (
          <div className="chart" style={{ height: "auto" }}>
            <div class="chart-container-bar">
              {console.log("Op Perform")}
              {console.log(formattedChartData)}
              {/* {chartData && <Chart options={options} type="line" data={data} />} */}
              <Plot
                data={formattedChartData}
                className="w-100 h-100"
                useResizeHandler
                layout={options2}
                config={config}
              />
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default OpPerformance;
