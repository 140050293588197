import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import { Chart } from "react-chartjs-2";
import Plot from "react-plotly.js";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";

function AvgSpeedMin({ title, clientId, batch_id, runTime }) {
  const [chartLabels, setChartLabels] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const options = {
  //   plugins: {
  //     legend: {
  //       position: "bottom",
  //       labels: {
  //         color: "#89aa9f",
  //       },
  //     },
  //   },
  //   scales: {
  //     x: {
  //       offset: true,
  //       ticks: {
  //         source: "data",
  //         color: "#89aa9f",
  //       },
  //       type: "timeseries",
  //       time: {
  //         displayFormats: {
  //           millisecond: "DD-MM-YY, HH:mm:ss.SSS",
  //           second: "DD-MM-YY, HH:mm:ss",
  //           minute: "DD-MM-YY, HH:mm",
  //           hour: "DD-MM-YY, HH",
  //           day: "DD-MM-YY",
  //           week: "DD-MM-YY",
  //           month: "MMM, YY",
  //           quarter: "MMM, YY",
  //           year: "YYYY",
  //         },
  //       },
  //     },
  //     y: {
  //       ticks: {
  //         color: "#89aa9f",
  //       },
  //     },
  //   },
  //   responsive: true,
  //   interaction: {
  //     mode: "index",
  //     intersect: false,
  //   },
  // };

  useEffect(() => {
    setIsLoading(true);
    setChartData([]);
    setChartLabels([]);
    axios
      .get(
        `${baseUrl}/api/modal/modal-chart-sc-ag-data/avgSpeedKpi/${clientId}/${batch_id}`
      )
      .then((res) => {
        console.log("RES:", res);
        let labels = [];
        let dataAvgSpeed = [];
        let dataKpi = [];
        res.data.dataData.avgSpeed.forEach((item) => {
          labels.push(item.x);
          dataAvgSpeed.push(item.y);
        });
        res.data.dataData.kpi.forEach((item) => {
          dataKpi.push(item.y);
        });
        setChartLabels(labels);
        setChartData({ dataAvgSpeed, dataKpi });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setChartData([]);
        setChartLabels([]);
      });
  }, [batch_id, clientId, runTime]);

  // const data = {
  //   labels: chartLabels && chartLabels,
  //   datasets: [
  //     {
  //       type: "line",
  //       label: "Avg Speed/Min",
  //       borderColor: "#FAB72E",
  //       backgroundColor: "#FAB72E",
  //       borderWidth: 2,
  //       data: chartData && chartData,
  //     },
  //   ],
  // };

  const dataAvgSpeed = {
    x: chartLabels,
    y: chartData && chartData.dataAvgSpeed,
    type: "line",
    mode: "lines",
    name: "PPM",
    marker: { color: "#5661c8" },
  };

  const dataKpi = {
    x: chartLabels,
    y: chartData && chartData.dataKpi,
    type: "line",
    yaxis: "y2",
    mode: "lines",
    name: "KPI (%)",
    marker: { color: "#89aa9f" },
  };

  const formattedChartData = [dataAvgSpeed, dataKpi];

  const options2 = {
    //title: 'KPI',
    showlegend: true,
    autosize: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: {
      // title: "Units",
      title: "PPM",
      // type: 'log',
      // autorange: true
    },
    yaxis2: {
      title: "KPI (%)",
      titlefont: { color: "rgb(167, 206, 57)" },
      tickfont: { color: "rgb(167, 206, 57)" },
      overlaying: "y",
      side: "right",
    },
    xaxis: {
      tickformat: "%H:%M \n %d %B",
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ? (
    <div className="m-5">Loading...</div>
  ) : (
    <Card className="shadow-sm">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">{title}</h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart" style={{ height: "auto" }}>
          <div class="chart-container-bar">
            <Plot
              data={formattedChartData}
              className="w-100 h-100"
              useResizeHandler
              layout={options2}
              config={config}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default AvgSpeedMin;
