import React, { useEffect } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import logo from "../assets/img/brand/MiWeigh.png";
import routes from "routes.js";
import Particles from "react-tsparticles";
import { particleOptions } from "constants/particleOptions";
import { useAuth } from "contexts/AuthContext";

const Auth = () => {
  const { user } = useAuth()
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Particles id="tsparticles" options={particleOptions} />
      {/* <div className="main-content" ref={mainContent} style={{backgroundColor: '#172d2b', minHeight: '100vh'}}> */}
      <div className="main-content" ref={mainContent}>
        <div className="header py-4 py-lg-5 mb-7">
          <Container className="mt-5">
            <div className="header-body text-center mb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <img alt="mi-weigh" className="img-fluid" width="350" src={logo} />
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Auth;