import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 230
    width: '100%',
    paddingRight: '30px',
    paddingLeft: '30px'
  },
  margin: {
    height: theme.spacing(3)
  }
}));


const RangeCustomSlider = withStyles({
  root: {
    color: "#a7ce39",
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    top: 30,
    '& *': {
      background: 'transparent',
      color: '#fff',
    },
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 0.2
  }
})(Slider);



export default function RangeSlider({value, min, max, onChange, name}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <RangeCustomSlider
        valueLabelDisplay="on"
        aria-label="pretto slider"
        // defaultValue={[20, 40]}
        min={min}
        max={max}
        value={value}
        onChange={(e, newValue) => onChange(e, newValue, name)}
      />
    </div>
  );
}
