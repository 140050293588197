import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import { Chart } from "react-chartjs-2";
import Plot from "react-plotly.js";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";

function AvgWeight({ title, batch_id, clientId, runTime }) {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [labelFormat, setFormat] = useState("%H:%M \n %d %B");

  // const options = {
  //   plugins: {
  //     legend: {
  //       position: "bottom",
  //       labels: {
  //         color: "#89aa9f",
  //       },
  //     },
  //   },
  //   scales: {
  //     x: {
  //       offset: true,
  //       ticks: {
  //         source: "data",
  //         color: "#89aa9f",
  //       },
  //       type: "timeseries",
  //       time: {
  //         displayFormats: {
  //           millisecond: "DD-MM-YY, HH:mm:ss.SSS",
  //           second: "DD-MM-YY, HH:mm:ss",
  //           minute: "DD-MM-YY, HH:mm",
  //           hour: "DD-MM-YY, HH",
  //           day: "DD-MM-YY",
  //           week: "DD-MM-YY",
  //           month: "MMM, YY",
  //           quarter: "MMM, YY",
  //           year: "YYYY",
  //         },
  //       },
  //     },
  //     y: {
  //       ticks: {
  //         color: "#89aa9f",
  //       },
  //     },
  //   },
  //   responsive: true,
  //   interaction: {
  //     mode: "index",
  //     intersect: false,
  //   },
  // };

  useEffect(() => {
    setIsLoading(false);
    setChartData([]);
    const backgroundColor = [
      "#3B2244",
      "#CD966B",
      "#004369",
      "#DB1F48",
      "#130170",
      "#01949A",
    ];
    axios
      .get(
        `${baseUrl}/api/modal/modal-chart-data/avgWeight/${clientId}/${batch_id}`
      )
      .then((res) => {
        let resChartData = [];
        Object.keys(res.data.data).forEach((item, idx) => {
          let operatorData = { operator: "", times: [], packs: [] };
          operatorData.operator = item;
          operatorData.times = res.data.data[item]["op_times"];
          operatorData.avgWeight = res.data.data[item]["op_avg_weight"];

          resChartData.push(operatorData);
          // resChartData.push({
          //   label: item,
          //   type: "line",
          //   borderColor: backgroundColor[idx],
          //   backgroundColor: backgroundColor[idx],
          //   borderWidth: 2,
          //   data: res.data.data[item],
          // });
        });

        //  Find item of resChartData with max length of avgWeight
        const maxAvgWeightItem = resChartData[0];
        resChartData.forEach((item) => {
          if (item.avgWeight.length > maxAvgWeightItem.avgWeight.length) {
            maxAvgWeightItem.avgWeight = item.avgWeight;
          }
        });

        const maxAvgTime = resChartData[0];
        resChartData.forEach((item) => {
          if (item.times.length > maxAvgTime.times.length) {
            maxAvgTime.times = item.times;
          }
        });

        let packsLength = maxAvgWeightItem.avgWeight.length;
        let times = maxAvgTime.times;
        console.log("res.data.Set_Point", res.data.Set_Point);
        resChartData.push({
          operator: "Set Point",
          times,
          avgWeight: Array(packsLength).fill(res.data.Set_Point),
        });

        setChartData(resChartData);
        setFormat("%H:%M \n %d %B");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setChartData([]);
      });
  }, [batch_id, clientId, runTime]);

  const formattedChartData = [];

  chartData &&
    chartData.forEach((operator_data) => {
      const operator_temp = {
        x: operator_data.times,
        y: operator_data && operator_data.avgWeight,
        type: "line",
        hovertemplate:
          "<i>Average Weight</i>: %{y}" + "<br><b>Time</b>: %{x}<br>",
        //yaxis: "y2",
        mode: "lines",
        name: operator_data.operator,
        // marker: { color: "#5661c8" },
      };
      formattedChartData.push(operator_temp);
    });

  // const data = {
  //   datasets: chartData,
  // };

  const options2 = {
    //title: 'KPI',
    autosize: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: { title: "Average Weight  (g)" },

    xaxis: {
      // tickformat: labelFormat,
      tickformat: "%H:%M \n %d %B",
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ? (
    <div className="m-5">Loading...</div>
  ) : (
    <Card className="shadow-sm">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">{title}</h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {isLoading ? (
          "Loading..."
        ) : (
          <div className="chart" style={{ height: "auto" }}>
            <div class="chart-container-bar">
              {console.log("Op Perform")}
              {console.log(formattedChartData)}
              {/* {chartData && <Chart options={options} type="line" data={data} />} */}
              <Plot
                data={formattedChartData}
                className="w-100 h-100"
                useResizeHandler
                layout={options2}
                config={config}
              />
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default AvgWeight;
