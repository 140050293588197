import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
//   Row,
//   Col,
//   Card,
//   CardBody,
//   CardTitle,
} from "reactstrap";

function VideoPlayerModal({ isOpen, toggle, modalData }) {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdropClassName="modal-backdrop-opacity"
      contentClassName="modal-bg"
      className="modal-width"
    >
      <ModalHeader
        toggle={toggle}
        className="py-3 px-5"
        close={
          <button className="close" onClick={toggle}>
            <div className="text-white rounded-circle">
              <i className="fas fa-times" />
            </div>
          </button>
        }
      >
        <h2 className="" style={{ color: "#a7ce39" }}>
          {modalData.title}
        </h2>
      </ModalHeader>
      <ModalBody>
        <div className="row justify-content-center">
            <video
              controls
              width="1200"
              onPlay={togglePlay}
              onPause={togglePlay}
              autoPlay
            >
              <source src={modalData.video_url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default VideoPlayerModal;
