import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Card,
  Spinner,
  CardBody,
  Input,
  CardHeader,
  Row,
  Nav,
  NavItem,
} from "reactstrap";
import Table from "views/shared/Table";
import { Grid, TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import authHeader from "../../services/auth-header";
import deepEqual from "utils/deepequal";
import { useCallback } from "react";
import { debounce } from "lodash";

const customStyles = {
  input: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base, state) => ({
    ...base,
    // background: "#023950",
    // background: "#737977",
    background: "#19312e",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    // borderColor: state.isFocused ? "yellow" : "green",
    borderColor: state.isFocused ? "#a7ce39" : "#8898aa",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#a7ce39" : "#a7ce39",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 2000,
    // background: "#737977",
    background: "#19312e",
    color: "white",
    // override border radius to match the box
    border: "1px solid #8898aa",
    // border: '1px solid #a7ce39',
    borderRadius: 5,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    // "::-webkit-scrollbar": {
    //   display: "none",
    // },
    // overflow: 'hidden'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      // backgroundColor: isFocused ? "#2462ad" : null,
      backgroundColor: isFocused ? "#a7ce39" : null,
      color: isFocused ? "black" : "white",
      // fontWeight: 'bold'
      // color: "#333333",
    };
  },
};

function OperatorsCostTable({ filtersData, clientId }) {
  console.log("filtersData", filtersData);
  const [filters, setFilters] = useState({
    startDate: filtersData.start_date,
    endDate: filtersData.end_date,
    page: 0,
    rowsPerPage: 10,
    Op_Name: "",
  });
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  const source = useRef(axios.CancelToken.source());

  const fetchData = useRef(
    debounce(async (searchFilters, filters) => {
      try {
        setTableData("Loading...");
        source.current.cancel("Operation canceled due to new request.");
        source.current = axios.CancelToken.source();
        const params = {
          start_date: filters.startDate,
          end_date: filters.endDate,
          clientId,
        };
        if (searchFilters !== "") params.Op_Name = searchFilters;

        const res = await axios.get(
          `${baseUrl}/api/mining/operators-cost/${filters.page}/${filters.rowsPerPage}`,
          {
            headers: authHeader(),
            params,
          }
        );
        setTableData(res.data.operatorData);
        setCount(res.data.totalCount);
      } catch (error) {
        console.log(error);
        setTableData([]);
        if (axios.isCancel(error)) {
          console.log("Previous request canceled", error.message);
        } else {
          console.error("Error fetching listings", error);
        }
      } finally {
        // setTableData([]);
      }
    }, 700)
  ).current;

  const tableColumns = [
    {
      id: "operator",
      title: "Name",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "weightPerMinute",
      title: "Weight Per Minute",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "costPerPack",
      title: "cost Per Pack £",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "averagePPM",
      title: "average PPM",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "totalMinutes",
      title: "total Mins",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "totalPacks",
      title: "total Packs",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "totalCost",
      title: "total Cost £",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "hourlyRate",
      title: "Hourly Rate £/hr",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setFilters((st) => ({
      ...st,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage();
    // setPage(0);
    setFilters((st) => ({
      ...st,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (
      deepEqual(filters, {
        startDate: filtersData.start_date,
        endDate: filtersData.end_date,
      })
    )
      return;
    setFilters({
      startDate: filtersData.start_date,
      endDate: filtersData.end_date,
      page: 0,
      rowsPerPage: 10,
      Op_Name: "",
    });
  }, [filtersData]);

  useEffect(() => {
    setTableData("Loading...");
    fetchData(searchTerm, filters);
  }, [filters, fetchData]);
  useEffect(() => {
    setTableData("Loading...");
    setFilters((st) => ({
      ...st,
      page: 0,
    }));
    fetchData(searchTerm, filters);
  }, [searchTerm]);

  return (
    <>
      <Card className="shadow h-100">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h2 className="mb-0 text-color-mw">Operators Overview</h2>
            </div>
            <div className="col">
              <Nav className="justify-content-end" pills>
                <NavItem>
                  <Input
                    type="text"
                    placeholder="Search Operator"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{
                      width: "200px",
                    }}
                    size={"sm"}
                    styles={customStyles}
                    className="textBg"
                  />
                </NavItem>
              </Nav>
            </div>
          </Row>
        </CardHeader>
        {/* <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h3 className="px-4 pt-4" style={{ color: "#a7ce39" }}>
              Operators Overview
            </h3>
          </Grid>
          <CardBody
            style={{
              paddingBlock: "0",
            }}
          >
            <Input
              type="text"
              placeholder="Search Operator"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                width: "200px",
              }}
              size={"sm"}
            />
          </CardBody> */}
        <CardBody className="text-muted">
          {tableData === "Loading..." ? (
            <div className="row justify-content-center my-5">
              <Spinner>Loading...</Spinner>
            </div>
          ) : tableData ? (
            <>
              <Table
                values={tableData}
                columns={tableColumns}
                tableClasses={{
                  table:
                    "table align-items-center table-flush table-sm border-bottom text-white table-custom",
                  thead: "text-muted",
                  tbody: "list",
                }}
              />
              <TablePagination
                component="div"
                className="text-muted"
                count={count}
                page={filters.page}
                onPageChange={handleChangePage}
                rowsPerPage={filters.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />{" "}
            </>
          ) : (
            <span className="m-4">No Data Found</span>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default OperatorsCostTable;
