import React, { useState, useEffect } from 'react';
import HeatMap from 'react-heatmap-grid';
import axios from 'axios';
import { baseUrl } from 'constants/url';
import authHeader from 'services/auth-header';
import { Col, Spinner } from 'reactstrap';

const xLabels = new Array(6).fill(0).map((_, i) => `${i}`);
// console.log(xLabels);
// Display only even labels
const xLabelsVisibility = new Array(6)
  .fill(0)
  .map((_, i) => (i % 2 === 0 ? false : false));
// console.log(xLabelsVisibility);

// const yLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const yLabels = ['', '', '', '', ''];
const data = new Array(yLabels.length).fill(0).map(
  () => new Array(xLabels.length).fill(0)
  // .map(() => Math.floor(Math.random() * 100))
);

export default function HeatMapComponent({
  clientId,
  timePeriod,
  setFiltersData
}) {
  const [heatMapData, setHeatMapData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  // console.log('-----------------> ', heatMapData);

  const handleClick = (x, y) => {
    const numberOfCellsPerRow = 6;
    console.log('timePeriod', timePeriod);
    // Assuming timePeriod.start_date and timePeriod.end_date are strings representing dates
    let endDate = new Date(timePeriod.end_date);
    let startDate = new Date(endDate.getTime() - 29 * 24 * 60 * 60 * 1000);

    // console.log('startDate', startDate);
    // console.log('endDate', endDate);

    // Calculate the number of days per cell in the heatmap
    const daysPerCell = 1; // Adjust this based on your heatmap layout

    // Calculate the date of the clicked cell
    // console.log('x', x);
    // console.log('y', y);
    const clickedDate = new Date(
      startDate.getTime() +
        daysPerCell * x * 24 * 60 * 60 * 1000 +
        y * numberOfCellsPerRow * daysPerCell * 24 * 60 * 60 * 1000
    );

    console.log('Clicked Date:', clickedDate);

    // * Start date is clicked date 00:00:00 and endData is clicked date 23:59:59

    startDate = new Date(clickedDate);
    startDate.setHours(0, 0, 0, 0);

    endDate = new Date(clickedDate);
    endDate.setHours(23, 59, 59, 999);

    console.log('startDate', startDate);
    console.log('endDate', endDate);

    setFiltersData({
      end_date: new Date(endDate),
      start_date: new Date(startDate)
    });

    // console.log('startDate', startDate);
    // console.log('endDate', endDate);

    // axios
    //   .get(`${baseUrl}/api/operatorPerformance/monthlyHeatMap/${clientId}`, {
    //     params: { start_date: startDate, end_date: endDate },
    //     headers: authHeader()
    //   })
    //   .then(res => {
    //     // console.log('HEAT MAP DATA: ', res);
    //     setHeatMapData(res.data.heatMapData);
    //     setIsLoading(false);
    //   })
    //   .catch(err => {
    //     // console.log(err);
    //     setIsLoading(false);
    //   });
  };

  useEffect(() => {
    setIsLoading(true);
    const end_date = new Date(timePeriod.end_date);
    const start_date = new Date(end_date);
    start_date.setDate(start_date.getDate() - 30);
    axios
      .get(`${baseUrl}/api/operatorPerformance/monthlyHeatMap/${clientId}`, {
        params: { start_date, end_date },
        headers: authHeader()
      })
      .then(res => {
        // console.log('HEAT MAP DATA: ', res);
        setHeatMapData(res.data.heatMapData);
        setIsLoading(false);
      })
      .catch(err => {
        // console.log(err);
        setIsLoading(false);
      });
    return () => {
      // console.log('Log');
    };
  }, [clientId, timePeriod]);

  return isLoading ? (
    <Col xs='12' className='text-center my-5'>
      <Spinner />
    </Col>
  ) : (
    <div>
      <HeatMap
        xLabels={xLabels}
        yLabels={yLabels}
        // xLabelsLocation={"bottom"}
        xLabelsVisibility={xLabelsVisibility}
        xLabelWidth={5}
        yLabelWidth={5}
        data={heatMapData}
        squares
        height={50}
        onClick={(x, y) => {
          handleClick(x, y);
        }}
        cellStyle={(background, value, min, max, data, x, y) => {
          const val = value ? (value !== 0 ? value.split('@')[0] : 0) : 0;
          let arr1D_data = data.flat();
          arr1D_data = arr1D_data.map(item =>
            item && item !== 0 ? parseFloat(item.split('@')[0]) : 0
          );
          let max1 = Math.max(...arr1D_data);
          let min1 = Math.min(...arr1D_data);
          return {
            background: `rgb(167, 205, 57, ${
              1 - (max1 - val) / (max1 - min1)
            })`,
            fontSize: '14px',
            color: '#fff',
            border: '1px solid grey'
          };
        }}
        cellRender={value =>
          value && value !== 0 && <div>{value.split('@')[0]}</div>
        }
      />
    </div>
  );
}
