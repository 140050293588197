import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";

function LineChartComponent({ lineChartData, title }) {
  console.log('Line ChartData: ', lineChartData);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (lineChartData.current && lineChartData.previous) {
      let cx = []
      let cy = []
      let ctext = []
      lineChartData.current.forEach(item => {
        cx.push(item.x)
        cy.push(item.y)
        ctext.push(item.d)
      });
      let currentBarValue = {
        x: cx,
        y: cy,
        name: "Current",
        type: "line",
        mode: "lines",
        text: ctext,
        hovertemplate: '(%{text}, %{y})',
        marker: {
          // color: "rgba(55,128,191,0.6)",
          color: "#a7ce39",
        },
      };

      let px = []
      let py = []
      let ptext = []
      lineChartData.previous.forEach(item => {
        px.push(item.x)
        py.push(item.y)
        ptext.push(item.d)
      });
      let previousBarValue = {
        x: px,
        y: py,
        name: "Previous",
        type: "line",
        mode: "lines",
        text: ptext,
        hovertemplate: '(%{text}, %{y})',
        marker: {
          // color: "rgba(255,153,51,0.6)",
          color: "#0c8ea5",
        },
      };

      setData([currentBarValue, previousBarValue]);
    }
  }, [lineChartData]);

  const layout = {
    showlegend: true,
    // responsive: true,
    autosize: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: { title },
    xaxis: {
      // tickformat: "%d %B \n %Y",
      title: 'Days'
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return (
    <Plot
      data={data}
      className="w-100 h-100"
      useResizeHandler
      layout={layout}
      config={config}
      // onRelayout={(e) => {
      //   if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
      //     setFiltersData({
      //       ...filtersData,
      //       start_date: new Date(e["xaxis.range[0]"]).toISOString(),
      //       end_date: new Date(e["xaxis.range[1]"]).toISOString(),
      //     });
      //   }
      // }}
    />
  );
}

export default LineChartComponent;
